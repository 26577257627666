@import "mixins";
@import "variables";

.container {
    display: flex;
    flex-direction: row;
    align-items: center;

    flex-wrap: wrap;

    width: 100%;
    padding-top: 40px;
    padding-bottom: 20px;

    .docs_button {
        margin-left: 12px;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;

        text-decoration: none;
        transition-duration: $anim-duration;

        .icon {
            width: 13px;
            height: 13px;
            transition-duration: $anim-duration;
            background-size: cover;
            background-image: url("../../../resources/ico-exportlink2.svg");
        }

        .title {
            @include font(14px, rgba(white, 0.3));
            transition-duration: $anim-duration;
            text-decoration: underline;
            margin-left: 4px;
        }

        &:hover {
            .icon {
                background-image: url("../../../resources/ico-exportlink.svg");
            }

            .title {
                color: #0661b9;
            }
        }
    }
}

@import "mixins";
@import "variables";

.header_container {
    display: flex;
    flex-direction: row;

    @media (max-width: $mobile-size) {
        flex-direction: column;
    }

    .item_container {
        display: flex;
        flex-direction: column;
        flex: 1;

        .row {
            display: flex;
            flex-direction: column;
            height: 100%;
            padding: 20px 30px;

            border-radius: 2px;
            border: solid 1px rgba(255, 255, 255, 0.2);
            background-color: rgba(255, 255, 255, 0.05);

            .item {
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }

            .number_item {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .stakable_container {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-end;
                }
            }

            .dummy {
                height: 30px;
            }
        }
    }
    .spacer {
        width: 30px;
        height: 30px;
    }
}

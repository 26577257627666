@import "mixins";
@import "variables";

.container {
    position: relative;
    cursor: pointer;

    z-index: 10000;

    height: 30px;
    width: 138px;

    padding-left: 16px;
    padding-right: 16px;
    border-radius: 15px;
    border: solid 1px rgba(255, 255, 255, 0.3);

    display: flex;
    flex-direction: row;
    align-items: center;

    @media (max-width: $mobile-size) {
    }

    .text {
        @include font(12px, white);
        flex: 1;
    }

    .icon {
        width: 8px;
        height: 6px;
        background-size: cover;
        margin-left: 12px;

        background-image: url("../../../resources/ico-gover-tr-2.svg");
    }
}

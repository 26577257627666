@import "mixins";
@import "variables";

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 0 30px 0;

    .title {
        margin: 0 0 20px 0;
    }

    .contents {
        .home {
            width: 32px;
            height: 32px;
            background-size: cover;
            background-image: url("../../../../../resources/ico-home-ipc.svg");
            margin: 0 30px 0 0;
            float: left;
        }
        .twitter {
            width: 32px;
            height: 32px;
            background-size: cover;
            background-image: url("../../../../../resources/logo-twitter-about.svg");
            margin: 0 30px 0 0;
            float: left;
        }
        .discord {
            width: 32px;
            height: 32px;
            background-size: cover;
            background-image: url("../../../../../resources/logo-discord-about.svg");
            margin: 0 30px 0 0;
            float: left;
        }
        .telegram {
            width: 32px;
            height: 32px;
            background-size: cover;
            background-image: url("../../../../../resources/logo-telegram-about.svg");
            margin: 0 30px 0 0;
            float: left;
        }
        .mail {
            width: 32px;
            height: 32px;
            background-size: cover;
            background-image: url("../../../../../resources/logo-medium-about.svg");
            float: left;
        }
    }
}

@import "mixins";
@import "variables";

.container {
    @include container1();

    .title {
        margin-top: 40px;
        margin-bottom: 20px;
        align-self: flex-start;
    }
}
.create_button {
    margin-top: -52px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    width: 100%;

    @media (max-width: $mobile-size) {
        align-items: flex-end;
    }

    .button {
        width: 200px;
        height: 32px;

        @media (max-width: $mobile-size) {
            margin-top: 50px;
            width: 100%;
        }
    }
}

.list_container {
    position: relative;
    width: 100%;

    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
}

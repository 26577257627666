@import "mixins";
@import "variables";

.container {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(black, 0.3);

    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 10000;

    .contents {
        width: 280px;
        height: 400px;

        border-radius: 5px;
        box-shadow: 0 20px 15px 0 rgba(0, 0, 0, 0.4);
        border: solid 1px rgba(255, 255, 255, 0.3);
        background-color: #181b23;
        overflow: hidden;

        flex-direction: column;
        display: flex;

        .contents_title {
            margin-top: 30px;
            margin-left: 20px;
            margin-right: 20px;
            width: calc(100% + 60px);
            display: flex;
            flex-direction: row;
        }

        .popdummy {
            margin-top: 10px;
            margin-left: 20px;
            margin-right: 20px;

            height: 1px;
            background: rgba(#fff, 0.5);
        }

        .list::-webkit-scrollbar {
            display: none;
        }

        .list {
            position: relative;
            flex: 1;
            margin-left: 20px;
            margin-right: 20px;

            overflow-y: auto;

            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */

            .card {
                cursor: pointer;
                height: 40px;
                display: flex;
                flex-direction: row;
                align-items: center;
                border-bottom: 1px solid rgba(white, 0.2);

                &:hover {
                    border: 1px solid #ffe646;
                }

                .referral_icon {
                    margin-left: 10px;
                    margin-right: 6px;
                    width: 20px;
                    height: 20px;
                    background-size: cover;
                    background-image: url("../../../../../resources/ico-referrallink-x.svg");

                    &.exist {
                        background-image: url("../../../../../resources/ico-referrallink-o.svg");
                    }
                }

                .check_icon {
                    width: 20px;
                    height: 20px;
                    margin-right: 10px;
                    background-size: cover;
                    background-image: url("../../../../../resources/vp-ico-no.svg");

                    &.selected {
                        background-image: url("../../../../../resources/vp-ico-ok3.svg");
                    }
                }
            }
        }

        .morebutton {
            margin-top: 10px;
            margin-bottom: 20px;

            width: 160px;
            height: 30px;

            margin-left: calc(50% - 80px);
        }

        .bar {
            margin-left: 20px;
            margin-right: 20px;
            height: 1px;
            background-color: rgba(white, 0.5);
            margin-bottom: 30px;
        }

        .buttons {
            display: flex;
            flex-direction: row;
            width: 100%;
            align-self: center;

            .button1 {
                flex: 1;
                height: 52px;
                background-color: #fff;
                display: flex;
                flex-direction: row;
                justify-content: center;

                &:hover {
                    cursor: pointer;
                    opacity: 0.9;
                }
            }

            .button2 {
                flex: 1;
                height: 52px;
                background-color: #ffe646;
                display: flex;
                flex-direction: row;
                justify-content: center;
                cursor: pointer;

                &.disabled {
                    background-color: rgba(#ffe646, 0.1);

                    &:hover {
                        background-color: rgba(#ffe646, 0.1);
                    }
                }

                &:hover {
                    opacity: 0.9;
                }
            }
        }
    }
}

@font-face {
    font-family: "bold";
    src: url(./resources/Gotham-Bold.ttf);
}

@font-face {
    font-family: "medium";
    src: url(./resources/Gotham-Medium.ttf);
}

@font-face {
    font-family: "book";
    src: url(./resources/Gotham-Book.ttf);
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@import "mixins";
@import "variables";

.container {
    display: none;
    @media (max-width: $width-pc1) {
        position: fixed;
        left: 0px;
        top: 0px;
        width: 100%;
        height: $header-mobile;
        z-index: 100010;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        background-color: #212531;
    }

    .inner {
        height: $header-mobile;
        width: 100%;
        height: 70px;
        display: flex;
        flex-direction: row;
        align-items: center;
        .logo {
            width: 42px;
            height: 42px;
            cursor: pointer;

            margin-left: 20px;
            background-size: cover;
            background-image: url("../../resources/V2/m-logo-vrk.svg");
        }

        .spacer {
            flex: 1;
        }

        .buttons {
            width: 42px;
            height: 42px;
            cursor: pointer;

            &.airdrop {
                margin-right: 20px;
                background-image: url("../../resources/ico-airdrop.svg");
            }

            &.wallet {
                margin-right: 20px;
                background-size: cover;
                background-image: url("../../resources/ico-nwallet.svg");

                &.connected {
                    background-image: url("../../resources/ico-nwallet-on.svg");
                }
            }

            &.menu {
                margin-right: 12px;
                background-size: cover;
                background-image: url("../../resources/ico-menu.svg");

                &.show {
                    background-image: url("../../resources/ico-menu-cross.svg");
                }
            }
        }
    }

    .sidebar {
        width: calc(100% - 40px);
        height: 30px;
        padding-left: 20px;
        padding-right: 20px;
        background-color: #141418;

        border-bottom: solid 0.5px #3b3531;

        display: flex;
        flex-direction: row;
        align-items: center;

        .text1 {
            @include font(12px, white);
        }

        .icon {
            margin-left: 4px;
            margin-right: 4px;
            width: 14px;
            height: 9px;
        }

        .refresh_container {
            width: 30px;
            height: 100%;
            margin-right: -8px;

            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            .refresh {
                width: 14px;
                height: 14px;
                background-image: url("../../resources/ico-refresh.svg");
            }
        }
    }
}

.inner_container {
    display: none;

    @media (max-width: $width-pc1) {
        display: flex;
        flex-direction: column;

        position: fixed;
        left: 0px;
        top: $header-mobile - 30px;
        width: 100%;
        overflow-y: hidden;
        height: calc(100% - 70px);

        z-index: 100030;

        transition-duration: $anim-duration;

        background-color: transparent;

        &.showmenu {
            background-color: rgba(black, 0.3);
            .menu_container {
                top: 0px;
            }
        }

        .menu_container {
            position: absolute;
            left: 0px;
            top: -100vh;
            width: 100%;

            z-index: 100002;

            transition-duration: $anim-duration;

            overflow-y: scroll;

            max-height: 100%;
            padding-bottom: 30px;
            background-color: #212531;

            .menu {
                cursor: pointer;
                height: 81px;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding-left: 30px;
                padding-right: 30px;

                .title {
                    @include font(24px, white);
                }

                .docs {
                    flex: 1;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-end;

                    @include font(16px, rgba(white, 0.3));
                    text-decoration: underline;

                    .icon {
                        width: 13px;
                        height: 13px;
                        background-size: cover;
                        background-image: url("../../resources/ico-exportlink2.svg");
                    }
                }
            }
        }

        &.showwallet {
            background-color: rgba(black, 0.3);
            .wallet_container {
                bottom: 0px;
            }
        }

        .wallet_container {
            position: absolute;
            left: 0px;
            bottom: -100%;
            width: calc(100% - 62px);
            padding: 30px;

            z-index: 100002;
            transition-duration: $anim-duration;

            box-shadow: 0 -5px 5px 0 rgba(0, 0, 0, 0.3);
            border: solid 1px rgba(255, 255, 255, 0.2);
            background-image: linear-gradient(314deg, #23293b, #39425d);

            display: flex;
            flex-direction: column;
            align-items: flex-start;

            border-top-left-radius: 20px;
            border-top-right-radius: 20px;

            .close_icon {
                cursor: pointer;
                align-self: flex-end;
                width: 42px;
                height: 42px;
                background-size: cover;
                background-image: url("../../resources/ico-menu-cross.svg");
                margin-top: -10px;
                margin-right: -10px;
            }

            .row0 {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-left: -10px;
                margin-top: 20px;
                height: 34px;

                .icon {
                    width: 34px;
                    height: 34px;
                    background-size: cover;
                    background-image: url("../../resources/ico-walletcoin.svg");
                }

                .text {
                    margin-left: 10px;
                    @include font(24px, #ffe646);
                }
            }

            .row0_2 {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-left: -10px;
                margin-top: 14px;
            }

            .row1 {
                display: flex;
                flex-direction: row;

                margin-left: -10px;
                margin-right: -10px;
                margin-top: 6px;

                word-break: break-all;

                @include font(13px, #c8c8c8);
                line-height: 1.54;
            }

            .row1_2 {
                display: flex;
                flex-direction: row;
                align-items: center;

                margin-left: -10px;
                margin-top: 6px;

                .copy_box {
                    cursor: pointer;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    height: 28px;
                    padding-left: 10px;
                    padding-right: 10px;
                    border-radius: 15px;
                    border: solid 1px rgba(white, 0.3);

                    transition: opacity $anim-duration;

                    &:hover {
                        opacity: 0.6;
                    }

                    .copy_box_icon {
                        width: 20px;
                        height: 20px;
                        background-size: cover;
                        background-image: url("../../resources/ico-paste-wallet-copy.svg");
                    }

                    .copy_box_text {
                        @include font(14px, rgba(white, 0.3));
                        margin-left: 4px;
                    }
                }
            }

            .row2 {
                margin-top: 30px;
                margin-left: -30px;
                margin-right: -30px;
                width: calc(100% + 60px);
                display: flex;
                flex-direction: column;

                .dummy1 {
                    height: 1px;
                    background-color: rgba(black, 0.1);
                }
                .dummy2 {
                    height: 1px;
                    background-color: rgba(white, 0.1);
                }
            }

            .row2_1 {
                margin-top: 30px;
                margin-left: -10px;
                margin-right: -10px;
                width: calc(100% + 20px);

                .button {
                    width: 100%;
                    height: 48px;
                }
            }

            .row3 {
                margin-top: 20px;
                margin-left: -30px;
                margin-right: -20px;
                margin-bottom: -30px;
                width: calc(100% + 50px);
                height: 40px;

                background-color: rgba(black, 0.1);

                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;

                .button {
                    cursor: pointer;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    .text {
                        @include font(12px, rgba(white, 0.3));
                        text-decoration: underline;
                    }
                    .icon {
                        width: 20px;
                        height: 20px;
                        background-size: cover;
                        background-image: url("../../resources/ico-disconnect-copy.svg");
                    }
                }
            }
        }
    }
}

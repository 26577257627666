@import "mixins";
@import "variables";
.container {
    display: flex;
    flex-direction: row;
    height: 52px;
    margin-bottom: 44px;

    @media (max-width: $mobile-size) {
        flex-direction: column;
        height: auto;
    }
}

@import "mixins";
@import "variables";

.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;

    .icon {
        width: 13px;
        height: 13px;

        margin-right: 4px;
        border-radius: 4px;
    }

    .dummy {
        flex: 1;
    }
}

@import "mixins";
@import "variables";

.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 20px;
    min-height: 16px;
    height: 20px;
    margin: 0 0 16px 0;

    @media (max-width: $mobile-size) {
        margin: 0 0 17px 0;
        height: fit-content;
    }

    .row {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;

        flex: 1;
    }

    .row2 {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;

        flex: 1;

        @media (max-width: $mobile-size) {
            flex-direction: column;
            align-items: unset;
        }

        .row2_title {
            display: flex;
            flex-direction: row;

            .row2_title_ico {
                width: 20px;
                height: 20px;
                background-size: cover;
                background-image: url("../../../resources/vp-check.svg");
                margin: 0 8px 0 0;
            }
        }

        .row2_content {
            @media (max-width: $mobile-size) {
                position: relative;
                margin: 0 0 15px 0;
            }

            .row2_content_text {
                display: flex;
                flex-direction: row;
                @media (max-width: $mobile-size) {
                    margin: 6px 0 0 28px;
                    // position: absolute;
                    // left: 0;
                }
                @media (max-width: 500px) {
                    flex-direction: column;
                }

                .dummy {
                    width: 8px;
                    @media (max-width: 500px) {
                        height: 6px;
                    }
                }
            }
        }
    }

    .link {
        display: flex;
        cursor: pointer;

        transition-duration: $anim-duration;
        &:hover {
            opacity: 0.6;
        }
    }
}

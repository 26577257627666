@import "mixins";
@import "variables";

.container {
    margin: 0 0 40px 0;
    display: flex;
    flex-direction: column;

    .dummy3 {
        height: 1px;
        opacity: 0.2;
        background-color: #fff;
    }

    .title_container {
        display: flex;
        flex-direction: row;
        margin-top: 40px;
        margin-bottom: 20px;
        .title {
            flex: 1;

            display: flex;
            flex-direction: row;

            @include fontLight(20px, white);
        }

        .change {
            cursor: pointer;
            @include fontLight(16px, rgba(white, 0.3));
            text-decoration: underline;
            &:hover {
                color: #ffe646;
            }
        }
    }

    .box {
        padding-top: 30px;
        padding-right: 30px;
        padding-left: 30px;
        padding-bottom: 0px;

        border-radius: 2px;
        border: solid 1px rgba(255, 255, 255, 0.2);
        background-image: linear-gradient(289deg, #23293b, #39425d);

        display: flex;
        flex-direction: column;

        .gotosite_container {
            display: flex;
            flex-direction: row;
            margin-bottom: 10px;

            .gotosite {
                text-decoration: none;
                height: 30px;
                padding-left: 18px;
                padding-right: 12px;
                border-radius: 15px;
                border: solid 1px #fff;

                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                @include font(14px, white);
                cursor: pointer;

                &:hover {
                    border-color: #ffe646;
                    color: #ffe646;
                    .arrowicon {
                        background-image: url("../../../../../resources/ico-arrow-top-true.svg");
                    }
                }

                .arrowicon {
                    margin-left: 4px;
                    width: 20px;
                    height: 20px;
                    background-image: url("../../../../../resources/ico-arrow-top.svg");
                }
            }
        }

        .signature_box {
            height: 92px;
            margin-top: -20px;
            margin-bottom: 30px;
            border-radius: 2px;
            border: solid 1px rgba(#fff, 0.3);

            display: flex;
            flex-direction: column;
            .box1 {
                padding-left: 10px;
                padding-right: 10px;
                padding-top: 10px;
                padding-bottom: 10px;
                min-height: 20px;
                display: flex;
                flex-direction: row;
                align-items: center;

                .icon {
                    width: 20px;
                    height: 20px;
                    background-size: cover;
                    background-image: url("../../../../../resources/ico-referrallink-x.svg");

                    &.referrer {
                        background-image: url("../../../../../resources/ico-referrallink-o.svg");
                    }
                }

                .text {
                    @include fontLight(12px, white);
                    margin-left: 6px;
                }
            }

            .box2 {
                height: 1px;
                background-color: rgba(white, 0.3);
            }

            .box3 {
                padding-left: 10px;
                padding-right: 10px;
                padding-top: 10px;
                padding-bottom: 10px;
                min-height: 20px;
                display: flex;
                flex-direction: column;

                .text1 {
                    @include fontLight(12px, white);
                }

                .text2 {
                    margin-top: 4px;
                    word-break: break-all;
                    @include fontLight(10px, rgba(white, 0.3));
                }
            }
        }
    }

    .confirmbutton {
        margin: 40px auto 0px auto;
        height: 48px;
        width: 400px;

        @media (max-width: $mobile-size) {
            width: calc(100%);
        }

        .button {
            width: calc(100% - 4px);
            height: calc(100% - 2px);
            border-radius: 2px;
            border: solid 1px rgba(255, 255, 255, 0.3);
            display: flex;
            flex-direction: row;
            justify-content: center;

            &.disabled {
                background-color: rgba(#fff, 0.1);
                cursor: not-allowed;

                .button_text {
                    color: rgba(#fff, 0.3);
                }

                .button_ico {
                    background-image: url("../../../../../resources/ico-arrow-coming-off.svg");
                }

                &:hover {
                    background-color: rgba(#fff, 0.1);
                    cursor: not-allowed;
                    border: solid 1px rgba(255, 255, 255, 0.3);

                    .button_text {
                        color: rgba(#fff, 0.3);
                    }

                    .button_ico {
                        background-image: url("../../../../../resources/ico-arrow-coming-off.svg");
                    }
                }
            }

            &:hover {
                border-radius: 2px;
                box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.5);
                border: solid 1px #ffe646;
                cursor: pointer;

                .button_text {
                    color: #ffe646;
                }

                .button_ico {
                    background-image: url("../../../../../resources/ico-arrow-top-true.svg");
                }
            }

            .button_text {
                margin: 16px 0 0 0;
                @include fontBold(14px, #fff);
            }

            .button_ico {
                width: 20px;
                height: 20px;
                background-size: cover;
                margin: 11px 0 0 4px;
                background-image: url("../../../../../resources/ico-arrow-top.svg");
            }
        }
    }
}

@import "mixins";
@import "variables";

.container {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: row;
    height: 58px;
    width: 100%;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;

    align-items: center;
    justify-content: center;
    background-color: transparent;

    overflow: hidden;

    .box {
        width: 100%;
        display: flex;
        flex-direction: row;
        height: 58px;

        .button {
            position: relative;
            @include fontBold(16px, rgba(white, 0.3));
            cursor: pointer;
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #282a3c;
            text-align: center;

            .selectedline {
                position: absolute;
                width: 100%;
                height: 2px;
                bottom: 0px;
                background-color: #ffe646;
                display: none;
            }

            &:hover {
                background-color: rgba(#fff, 0.1);
            }

            &.left {
                &.selected {
                    background-color: transparent;
                    color: #ffe646;

                    &:hover {
                        background-color: rgba(#fff, 0.1);
                    }

                    .selectedline {
                        display: flex;
                    }
                }
            }

            &.right {
                &.selected {
                    background-color: transparent;
                    color: #ffe646;

                    &:hover {
                        background-color: rgba(#fff, 0.1);
                    }

                    .selectedline {
                        display: flex;
                    }
                }
            }
        }
    }

    .bottomline {
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: 0px;
        background-color: rgba(white, 0.2);
    }
}

@import "mixins";
@import "variables";

.wrap {
    width: 100%;
    position: relative;
    z-index: 0;

    @media (max-width: $mobile-size) {
        height: calc(100%);
    }

    .container {
        @include container2();
        position: relative;
        margin: 0 auto;

        &.hash {
            margin: 40px auto 0 auto;
        }

        .buttons {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            .button {
                margin-left: 12px;
                margin-bottom: 20px;
                align-self: flex-end;
                width: 100px;
                height: 32px;
            }
        }
    }
}

.ptitle_container {
    width: calc(100% - 60px);
    height: 15px;
    margin: 34px auto 0 auto;
    display: flex;
    flex-direction: row;

    .ptitle_info {
        width: 200px;
        line-height: 15px;
    }

    .ptitle_locked {
        width: 130px;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: right;

        .ptitle_locked_title {
            line-height: 15px;
        }

        .ptitle_locked_ico {
            width: 20px;
            height: 20px;
            margin: -4px 0 0 4px;
            background-size: cover;
            background-image: url("../../../../resources/ico-feelock01.svg");
        }
    }

    .ptitle_claimable {
        width: 130px;
        display: flex;
        flex-direction: row;
        justify-content: right;
    }
}

.pdetail_container {
    width: calc(100% - 60px);
    height: 20px;
    margin: 20px auto 0 auto;
    display: flex;
    flex-direction: row;

    .pdetail_info {
        width: 200px;
        height: 100%;
        display: flex;
        flex-direction: row;

        .pdetail_info_ico {
            width: 20px;
            height: 20px;
            background-size: cover;
            background-image: url("../../../../resources/ico-rewards-01.svg");

            &.sharing {
                background-image: url("../../../../resources/ico-rewards-02.svg");
            }

            &.deposit {
                background-image: url("../../../../resources/ico-rewards-03.svg");
            }
        }

        .pdetail_info_title {
            line-height: 23px;
            margin: 0 0 0 6px;
        }
    }

    .pdetail_locked {
        width: 130px;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: right;

        .pdetail_locked_wrap {
            display: flex;
            flex-direction: row;
            justify-content: right;
            .pdetail_locked_amount {
                line-height: 22px;
            }

            .pdetail_locked_symbol {
                margin: 0 0 0 4px;
                line-height: 24px;
            }
        }
    }

    .pdetail_claimable {
        width: 130px;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: right;

        .pdetail_claimable_wrap {
            display: flex;
            flex-direction: row;
            justify-content: right;
            .pdetail_claimable_amount {
                line-height: 20px;
            }

            .pdetail_claimable_symbol {
                margin: 0 0 0 4px;
                line-height: 22px;
            }
        }
    }
}

.mobile_pdetail_container {
    width: 100%;
    display: flex;
    flex-direction: column;

    .mobile_title {
        height: 20px;
        margin: 0 0 10px 0;
        display: flex;
        flex-direction: row;

        .mobile_title_ico {
            width: 20px;
            height: 20px;
            background-size: cover;
            margin: 0 6px 0 0;
            background-image: url("../../../../resources/ico-rewards-01.svg");

            &.sharing {
                background-image: url("../../../../resources/ico-rewards-02.svg");
            }

            &.deposit {
                background-image: url("../../../../resources/ico-rewards-03.svg");
            }
        }

        .mobile_title_text {
            line-height: 22px;
        }
    }

    .mobile_contents {
        height: 38px;
        display: flex;
        flex-direction: row;

        .mobile_contents_locked {
            width: calc(50% - 1px);
            height: 100%;
            display: flex;
            flex-direction: column;

            .mobile_contents_locked_title {
                height: 20px;
                display: flex;
                flex-direction: row;

                .locked_title_text {
                    line-height: 20px;
                }

                .locked_title_ico {
                    width: 20px;
                    height: 20px;
                    margin: -2px 0 0 4px;
                    background-size: cover;
                    background-image: url("../../../../resources/ico-feelock01.svg");
                }
            }

            .mobile_contents_locked_amount {
                height: 19px;
                .pdetail_locked_wrap {
                    display: flex;
                    flex-direction: row;

                    .pdetail_locked_amount {
                        line-height: 20px;
                    }

                    .pdetail_locked_symbol {
                        margin: 0 0 0 4px;
                        line-height: 24px;
                    }
                }
            }
        }

        .mobile_contents_dummy {
            width: 1px;
            height: 20px;
            margin: 11px 0 0 0;
            background: rgba(#fff, 0.2);
        }

        .mobile_contents_claimable {
            width: 50%;
            height: 100%;
            // background: yellow;
            display: flex;
            flex-direction: column;
            position: relative;

            .mobile_contents_claimable_title {
                height: 20px;
                // display: flex;
                // flex-direction: row;
                // justify-content: right;
                position: absolute;
                right: 0;
                top: 0;

                .claimable_title_text {
                    line-height: 20px;
                }
            }

            .mobile_contents_claimable_amount {
                height: 19px;
                .pdetail_claimable_wrap {
                    display: flex;
                    flex-direction: row;
                    // justify-content: right;
                    position: absolute;
                    right: 0;
                    top: 20px;

                    .pdetail_claimable_amount {
                        line-height: 22px;
                    }

                    .pdetail_claimable_symbol {
                        margin: 0 0 0 4px;
                        line-height: 24px;
                    }
                }
            }
        }
    }
}

@import "mixins";
@import "variables";

.container {
    display: flex;
    flex-direction: column;

    .row0 {
        display: flex;
        flex-direction: column;
    }

    .row1 {
        margin-top: 40px;
        display: flex;
        flex-direction: row;

        @media (max-width: $mobile-size) {
            flex-direction: column;
        }

        .dummy {
            width: 60px;
            @media (max-width: $mobile-size) {
                height: 14px;
            }
        }
    }

    .row2 {
        margin-top: 20px;
        display: flex;
        flex-direction: row;

        @media (max-width: $mobile-size) {
            flex-direction: column;
            margin-top: 14px;
        }

        .dummy {
            width: 60px;
            @media (max-width: $mobile-size) {
                height: 14px;
            }
        }
    }
}

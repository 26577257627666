@import "mixins";
@import "variables";

.container {
    width: 310px;
    display: flex;
    flex-direction: column;

    @media (max-width: $mobile-size) {
        width: 100%;
    }

    .mytotal_amount {
        @include font(30px, #fff);
        text-decoration: underline;
        text-decoration-thickness: 2px;
        margin: 6px 0 0 0;
        height: 36px;
        line-height: 36px;
        cursor: pointer;

        span {
            @include font(12px, #fff);
        }
    }

    .mytotal_line {
        width: 100%;
        height: 1px;
        opacity: 0.2;
        background-color: #fff;
        margin: 20px 0 20px 0;
    }

    .mytotal_claimed {
        @include font(16px, rgba(255, 255, 255, 0.3));
        margin: 6px 0 0 0;
        height: 19px;
        line-height: 19px;

        span {
            @include font(12px, rgba(255, 255, 255, 0.3));
        }
    }
}

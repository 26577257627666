@import "mixins";
@import "variables";

.container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .remainingdate {
        height: 19px;
        line-height: 19px;
        @include fontBold(16px, rgba(255, 255, 255, 0.3));
        margin: 0 0 6px 0;
    }

    .remainingdetail {
        width: 400px;
        height: 48px;
        border-radius: 24px;
        background-color: rgba(#fff, 0.1);
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @media (max-width: $mobile-size) {
            width: 100%;
        }

        .remainingdetailtitle {
            @include font(14px, rgba(#fff, 0.3));
            line-height: 48px;
            margin: 0 0 0 20px;
        }

        .remainingdetailamount {
            @include font(16px, #fff);
            line-height: 48px;
            margin: 0 20px 0 0;

            &.under {
                @include font(16px, #ffe646);
            }
        }
    }
}

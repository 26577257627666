@import "mixins";
@import "variables";

.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    width: 100vw;
    height: $footer-pc;
    margin-top: $footer-margin-pc;

    position: relative;
    z-index: 1;

    box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, 0.2);
    background-color: #212531;

    @media (max-width: $mobile_size) {
        height: $footer-mobile;
        margin-top: $footer-margin-mobile;
    }

    .inner_container {
        display: flex;
        flex-direction: row;
        height: 100%;

        width: 100%;
        max-width: $width-pc1;

        @media (max-width: $width-pc1) {
            width: calc(100% - 40px);
        }

        @media (max-width: $mobile_size) {
            flex-direction: column;
        }

        .block {
            display: flex;
            flex-direction: row;
            flex: 1;

            @media (max-width: $mobile_size) {
                flex: none;
                justify-content: center;
            }

            .icon {
                margin-top: 34px;
                width: 24px;
                height: 25px;
                background-size: cover;
                background-image: url("../../resources/icon-footer.svg");
                @media (max-width: $mobile_size) {
                    margin-top: 20px;
                }
            }

            .text {
                margin-top: 38px;
                margin-left: 8px;

                @include font(14px, rgba(white, 0.3));
                @media (max-width: $mobile_size) {
                    margin-top: 25px;
                }
            }
        }

        .wrapper {
            display: flex;
            flex-direction: row;

            @media (max-width: $mobile_size) {
                flex-direction: column-reverse;
            }

            .links {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 230px;
                margin-top: 35px;

                @media (max-width: $mobile_size) {
                    margin-top: 25px;
                    align-self: center;
                    min-width: 230px;
                    max-width: 310px;
                    width: calc(100% - 80px);
                }

                .icon {
                    cursor: pointer;

                    width: 30px;
                    height: 30px;

                    border-radius: 15px;

                    opacity: 0.3;

                    &:hover {
                        opacity: 1;
                    }

                    .icon1 {
                        width: 100%;
                        height: 100%;
                        background-size: cover;
                        background-image: url("../../resources/logo-github.svg");
                    }
                    .icon2 {
                        width: 100%;
                        height: 100%;
                        background-size: cover;
                        background-image: url("../../resources/logo-twitter.svg");
                    }
                    .icon3 {
                        width: 100%;
                        height: 100%;
                        background-size: cover;
                        background-image: url("../../resources/logo-medium.svg");
                    }
                    .icon4 {
                        width: 100%;
                        height: 100%;
                        background-size: cover;
                        background-image: url("../../resources/logo-telegram.svg");
                    }
                    .icon5 {
                        width: 100%;
                        height: 100%;
                        background-size: cover;
                        background-image: url("../../resources/logo-forum.svg");
                    }
                }
            }

            .dummy {
                width: 2px;
                height: 16px;
                margin: 42px 20px 0px 20px;
                opacity: 0.3;
                background-color: #fff;

                @media (max-width: $mobile-size) {
                    display: none;
                }
            }

            .litepaper {
                margin-top: 40px;
                display: flex;
                flex-direction: row;
                align-items: center;
                height: 20px;
                cursor: pointer;
                text-decoration: none;

                @media (max-width: $mobile_size) {
                    margin-top: 30px;
                    align-self: center;
                }

                &:hover {
                    .icon {
                        background-image: url("../../resources/ico-docs-on.svg");
                    }

                    .text {
                        color: #ffe646;
                    }
                }

                .icon {
                    width: 20px;
                    height: 20px;
                    background-image: url("../../resources/ico-docs.svg");
                }

                .text {
                    margin-left: 6px;
                    @include font(16px, rgba(white, 0.3));
                }
            }
        }
    }
}

@import "mixins";
@import "variables";

.container {
    
    width: calc(60% - 20px);
    @media (max-width: $mobile-size) {
        width: 100%;
    }

    .container_box{

        @include box_shadow();
        display: flex;
        flex-direction: column;
        border-radius: 2px;
        box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.5);
        border: solid 1px rgba(255, 255, 255, 0.2);
        background-image: linear-gradient(317deg, #23293b 100%, #39425d 0%);

        padding: 30px;

        @media (max-width: $mobile-size) {
            padding: 20px;
            margin-bottom: 40px;
        }

        .chart {
            display: flex;
            flex-direction: row;

            .header_container {
                width: 70%;
                @media (max-width: $mobile-size) {
                    width: 100%;
                }
            }
            .chart_container {
                margin-left: 0px;
                margin-top: 50px;
                @media (max-width: $mobile-size) {
                    display: none;
                }
            }
        }
        .line_container {
            width: calc(100% + 60px);
            position:relative;
            left:-30px;
    
            @media (max-width: $mobile-size) {
                width: calc(100% + 40px);
                left:-20px;
            }
        }
        .vp_container {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            @media (max-width: $mobile-size) {
                flex-direction: column;
                align-items: flex-start;
            }
            
            .vp{

                .staked_wrap {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-end;
                    margin-top: 9px;
                }
                
            }
            .required_createvp {
                width: 130px;
                height: 30px;
                margin: -2px 0 0 20px;
                @media (max-width: $mobile-size) {
                    margin: 20px 0 0 0;
                    align-self: flex-end;
                }
            }
        }

        

        .total_container {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .value {
                margin-top: 8px;
                max-width: 100%;
                display: flex;
                .total_wrap {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-end;
    
                    .total_num {
                        @include font(30px, #ffffff);
    
                        @media (max-width: $mobile-size) {
                            @include font(20px, #ffffff);
                        }
                    }
                }
            }
        }
    }
}
@import "mixins";
@import "variables";

.container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .row1_1 {
        display: flex;
        flex-direction: row;
        align-items: center;

        width: calc(100% - 40px);
        height: 60px;
        padding-left: 20px;
        padding-right: 20px;
        border-radius: 5px;
        border: solid 1px #fff;
        background-color: rgba(255, 255, 255, 0.1);

        .switch_container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            .bg {
                width: 50px;
                height: 30px;
                border-radius: 15px;

                background-color: #c8c8c8;

                display: flex;
                flex-direction: row;
                align-items: center;

                cursor: pointer;

                &.on {
                    justify-content: flex-end;
                    background-color: #ffe646;
                }

                .button {
                    width: 24px;
                    height: 24px;
                    margin-left: 2px;
                    margin-right: 2px;
                    border-radius: 13px;
                    box-shadow: 0 3px 1px 0 rgba(0, 0, 0, 0.06),
                        0 3px 8px 0 rgba(0, 0, 0, 0.15);
                    border: solid 1px rgba(0, 0, 0, 0.04);
                    background-color: #ffffff;
                }
            }
        }
    }

    .row1_2 {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;

        .dummy {
            width: 30px;
            height: 0px;
        }
    }
}

@import "mixins";
@import "variables";

$combosize: 100px;

.container {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    // min-height: 120px;
    .title_container {
        display: flex;
        flex-direction: row;
        align-items: center;

        .balance_container {
            display: flex;
            flex-direction: row;
            align-items: center;

            .title {
                @media (max-width: $mobile-size) {
                    display: none;
                }
            }

            .value {
                cursor: pointer;

                &.disabled {
                    cursor: auto;
                }
            }
        }
    }

    .body_container {
        border-radius: 10px;
        margin-top: 8px;

        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;

        .coin_icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 20px;
            width: 28px;
            height: 28px;
        }

        .field {
            @include font(18px, white);

            border-radius: 8px;

            padding-top: 0px;
            padding-bottom: 0px;
            padding-left: 57px;
            padding-right: 19px;
            width: 10px;
            flex: 1;
            height: 58px;
            outline: none;
            -webkit-appearance: none;

            &.combo {
                padding-right: 120px;

                @media (max-width: 320px) {
                    padding-right: $combosize * 0.8 + 19;
                }
            }

            background-color: rgba(255, 255, 255, 0.1);
            border: solid 1px rgba(255, 255, 255, 0.1);

            &::placeholder {
                color: rgba(white, 0.3);
            }

            &:focus {
                height: 54px;
                padding-left: 55px;
                background-color: #ffffff;
                border: 3px solid #ffe646;
                color: #2d2d2d;
            }

            &.error {
                height: 54px;
                padding-left: 55px;
                border: 3px solid #a02d46;
                color: #a02d46;
            }

            &.disabled {
                height: 60px;
                padding-left: 58px;
                border: 0px solid #141818;
                box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
                background-color: #141418;
            }
        }

        .combobox {
            cursor: pointer;
            position: absolute;
            z-index: 10000;
            right: 1px;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
            width: $combosize;
            height: 58px;

            display: flex;
            flex-direction: row;
            align-items: center;

            @media (max-width: 320px) {
                width: $combosize * 0.8;
            }

            .dummy {
                width: 1px;
                height: 20px;
                background-color: rgba(white, 0.3);
            }

            .symbol {
                @include font(18px, rgba(white, 0.3));
                background-color: transparent;
                border: 0px;
                outline: none;
                -webkit-appearance: none;
            }

            .icon {
                width: 20px;
                height: 20px;
                margin-left: 12px;
                background-image: url("../../../resources/ico-drop.svg");
            }
        }

        &:focus-within {
            .combobox {
                .dummy {
                    background-color: #2d2d2d;
                }

                .symbol {
                    color: #2d2d2d;
                }

                .icon {
                    background-image: url("../../../resources/ico-drop-on.svg");
                }
            }
        }
    }

    .error_container {
        display: flex;
        flex-direction: row;
        margin-top: 8px;
        height: 24px;

        .error_text {
            @include fontLight(14px, #a02d46);

            display: flex;
            flex-direction: row;
            align-items: center;

            background-color: rgba($color: #a02d46, $alpha: 0.3);
            border-radius: 12px;
            word-break: break-all;

            padding-left: 12px;
            padding-right: 12px;

            letter-spacing: -0.4px;
        }
    }
}

@import "mixins";
@import "variables";

.container {
    flex: 1;
    padding: 30px;

    display: flex;
    flex-direction: column;

    @media (max-width: $mobile-size) {
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
    }

    &.trade {
        padding: 0;
        height: 715px;
    }

    .row0 {
        width: 100%;
        height: 60px;

        margin-bottom: 10px;

        display: flex;
        flex-direction: row;

        &.trade {
            margin: 50px 0 50px 50px;
        }

        @media (max-width: $width-pc1) {
            height: auto;
            align-items: flex-start;
            flex-direction: column;
        }

        .col0 {
            display: flex;
            flex-direction: row;
            align-items: center;

            .logo {
                margin-top: -7px;
                width: 60px;
                height: 60px;
                border-radius: 30px;
                background-color: #141418;

                display: flex;
                justify-content: center;
                align-items: center;

                .icon {
                    width: 44px;
                    height: 44px;
                    background-size: cover;
                    background-image: url("../../../resources/V2/logo-vkr-2.svg");
                }
            }

            .title {
                margin-left: 20px;
                display: flex;
                flex-direction: column;

                .text {
                    margin-top: 8px;
                    display: flex;
                    flex-direction: row;

                    .percent {
                        margin-top: 8px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        .icon {
                            margin-left: 12px;
                            width: 14px;
                            height: 9px;

                            margin-right: 4px;
                        }
                    }
                }
            }
        }

        .col1 {
            flex: 1;

            @media (max-width: $width-pc1) {
                flex: none;
                height: 30px;
            }
        }
    }

    .row1 {
        // margin-right: -30px;

        height: 178px;
        width: calc(100% + 60px);
        margin-left: -30px;
        margin-right: -30px;

        @media (max-width: $mobile-size) {
            margin-left: -20px;
            margin-right: -20px;
            height: 120px;
            position: relative;
            width: calc(100% + 40px);
        }

        &.trade {
            margin: 0px;
            width: 100%;
            height: 378px;
        }

        // margin-bottom: 20px;
    }

    .row2 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 30px;

        @media (max-width: $mobile-size) {
            margin: 30px 30px 0 30px;
        }

        @media (max-width: 375px) {
            margin: 30px 0px 0 0px;
        }

        &.trade {
            margin-top: 85px;
        }

        .dummy {
            width: 10px;
        }

        .button {
            width: 69px;
            height: 32px;

            &.trade {
                width: 80px;
                height: 30px;
            }

            @media (max-width: $width-pc1) {
                flex: 1;
                width: auto;
            }
        }
    }
}

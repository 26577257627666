@import "mixins";
@import "variables";

.container {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 120px;
    padding-bottom: 32px;

    .title_container {
        display: flex;
        flex-direction: row;
        align-items: center;

        .title_container_v2 {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        .length {
            display: flex;
            flex-direction: row;
            align-items: center;

            @include fontLight(14px, white);

            b {
                font-weight: 500;
            }
        }
    }

    &:focus-within {
        .length {
            color: white;

            b {
                color: #ffe646;
                font-weight: 500;
            }
        }
    }

    .body {
        border-radius: 10px;
        margin-top: 8px;

        position: relative;
        display: flex;
        flex-direction: row;

        .field {
            @include font(18px, white);

            border-radius: 2px;

            padding-top: 20px;
            padding-bottom: 20px;
            padding-left: 19px;
            padding-right: 19px;
            width: 10px;
            flex: 1;
            height: 300px;
            outline: none;
            -webkit-appearance: none;

            background-color: #282a3c;
            border: solid 1px rgba(255, 255, 255, 0.3);

            &::placeholder {
                color: rgba(white, 0.3);
            }

            &:focus {
                height: 296px;
                background-color: #ffffff;
                border: 3px solid #ffe646;
                color: #2d2d2d;
            }

            &.disabled {
                height: 302px;
                border: 0px solid #141818;
                box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
                background-color: #141418;
            }
        }
    }
}

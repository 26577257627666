@import "mixins";
@import "variables";

.container {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(black, 0.3);

    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 10000;

    .contents {
        width: calc(800px - 40px);
        height: calc(423px - 30px);
        padding: 20px;

        border-radius: 5px;
        box-shadow: 0 20px 15px 0 rgba(0, 0, 0, 0.4);
        border: solid 1px rgba(255, 255, 255, 0.3);
        background-color: #181b23;

        flex-direction: column;
        display: flex;
        align-items: center;

        @media (max-width: $mobile-size) {
            width: calc(280px - 40px);
        }

        .list {
            position: relative;
            width: 100%;
            height: calc(290px - 20px);
            margin-top: 20px;
            padding: 10px;
            border-radius: 10px;
            background-color: #222226;
            color: white;

            overflow-y: scroll;
            white-space: pre-wrap;
            word-break: break-all;
        }

        .button {
            margin-top: 10px;
            width: 200px;
            height: 40px;
        }
    }
}

@import "mixins";
@import "variables";

.container {
    @include box_shadow();

    width: calc(100% - 30px);
    padding: 20px 0 20px 30px;
    display: flex;
    margin-bottom: 40px;
    flex-direction: row;
    align-items: center;
    border-radius: 2px;
    box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.5);
    border: solid 1px rgba(255, 255, 255, 0.2);
    background-image: linear-gradient(317deg, #23293b 100%, #39425d 0%);


    .icon_container {
        width: 60px;
        height: 60px;
        margin: 0 20px 0 0;
        border-radius: 50px;
        border: solid 1px rgba(255, 255, 255, 0.2);
        background-color: rgba(0, 0, 0, 0.5);
        .icon {
            width: 60px;
            height: 60px;
            background-size: contain;
            background-image: url("../../resources/burn.gif");
        }
    }

    .inner_container {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .value {
            margin-top: 8px;
            max-width: 100%;
            display: flex;
            .burn_wrap {
                display: flex;
                flex-direction: row;
                align-items: flex-end;

                .burn_num {
                    display: flex;
                    @media (max-width: $mobile-size) {
                        display: none;
                    }
                }

                .burn_num_mobile {
                    display: none;
                    @media (max-width: $mobile-size) {
                        display: flex;
                    }
                }
            }
        }
    }
}

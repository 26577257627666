@import "mixins";
@import "variables";

.container {
    @include container1();

    .vkr_info_container {
        @include container1();
        // padding: 0 0px 30px 20px;

        @media (max-width: $mobile-size) {
            padding: 0px;
            width: 100%;
        }

        .governance_header_container {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;

            @media (max-width: $mobile-size) {
                flex-direction: column;
                align-items: flex-start;
            }
            .item {
                padding: 20px 30px;
                width: 50%;
                border-radius: 2px;
                border: solid 1px rgba(255, 255, 255, 0.2);
                background-color: rgba(255, 255, 255, 0.05);

                @media (max-width: $mobile-size) {
                    padding: 20px 30px;
                    width: calc(100% - 60px);
                    height: 45px;
                    flex: none;
                    margin-bottom: 20px;
                }

                .price_container {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .percent {
                        margin-top: 8px;
                        display: flex;
                        flex-direction: row;

                        .icon {
                            margin-left: 12px;
                            width: 14px;
                            height: 9px;

                            margin-right: 4px;
                        }
                    }
                }
            }
        }
    }
    .stake_container {
        @include container1();

        @media (max-width: $mobile-size) {
            width: 100%;
            padding: 0;
            
        }

        .inner_container {
            width: 100%;
            display: flex;
            flex-direction: row;
            position: relative;

            .section {
                display: flex;
                flex-direction: column;
                flex: 1;
            }

            .dummy {
                width: 40px;
            }

            @media (max-width: $mobile-size) {
                flex-direction: column;

                .dummy {
                    width: auto;
                    height: 40px;
                }
            }
        }
    }
}

@import "mixins";
@import "variables";

.campaigns_item {
    @include box_shadow();
    cursor: pointer;

    margin-bottom: 40px;
    text-decoration: none;

    width: calc(50% - 22px);
    float: left;
    background-size: cover;
    border-radius: 2px;
    box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.5);

    display: flex;
    flex-direction: row;
    position: relative;

    background-image: linear-gradient(
        66deg,
        rgba(27, 255, 188, 0.2),
        #1bffbc 100%
    );
    border: solid 1px rgba(255, 255, 255, 0.2);

    @media (max-width: $mobile-size) {
        width: calc(100%);
        flex-direction: column;
    }

    &:hover {
        border: solid 1px #ffe646;
        .contents {
            .detail_button {
                border-color: #ffe646;
                background-color: #ffe646;

                .text {
                    color: #2d2d2d;
                }

                .arrow {
                    background-image: url("../../../resources/ico-arrow-ctrue.svg");
                }
            }
        }
    }

    &.disabled {
        background-image: linear-gradient(
            294deg,
            rgba(#23293b, 0.4),
            rgba(#39425d, 0.4)
        );

        .icon_container {
            .icon {
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;

                .closed {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    width: 80px;
                    height: 80px;
                    border-radius: 50px;
                    border: solid 1px rgba(255, 255, 255, 0.2);
                    background-color: rgba(#000, 0.8);
                    position: absolute;
                    @include fontBold(12px, #fff);

                    @media (max-width: $mobile-size) {
                        width: 60px;
                        height: 60px;
                        left: 0;
                        @include fontBold(10px, #fff);
                    }
                }
            }

            .titles {
                .text1 {
                    color: rgba(white, 0.3);
                }
                .text2 {
                    color: rgba(white, 0.3);
                }
            }
        }

        .contents {
            .campaign_title {
                color: rgba(white, 0.3);
            }

            .box {
                .yet {
                    .opendate {
                        color: rgba(white, 0.3);
                        &:not(.highlight) {
                            color: rgba(white, 0.3);
                        }
                    }

                    .text_remainig {
                        color: rgba(white, 0.3);
                    }

                    .block_left {
                        .text1 {
                            color: rgba(white, 0.3);
                            &.highlight {
                                color: rgba(white, 0.3);
                            }
                        }
                    }
                }

                .open {
                    .total_distribution {
                        .text1 {
                            color: rgba(white, 0.3);
                        }
                        .text2 {
                            color: rgba(white, 0.3);
                        }
                    }

                    .graph {
                        .info {
                            .text1 {
                                color: rgba(white, 0.3);
                            }
                            .text2 {
                                color: rgba(white, 0.3);
                            }
                        }
                    }

                    .block_left {
                        .text1 {
                            color: rgba(white, 0.3);
                        }
                        .text2 {
                            color: rgba(white, 0.3);
                        }
                        .text3 {
                            color: rgba(white, 0.3);
                        }
                    }
                }
            }
        }

        &:hover {
            border: solid 0.5px #ffe646;
            box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.5);
            background-image: linear-gradient(
                315deg,
                rgba(#191b1e, 0.4),
                rgba(#1b1f2c, 0.4)
            );

            .icon_container {
                .titles {
                    .text1 {
                        color: white;
                    }
                    .text2 {
                        color: white;
                    }
                }
            }

            .contents {
                .campaign_title {
                    color: white;
                }

                .box {
                    .yet {
                        .opendate {
                            color: white;
                            &:not(.highlight) {
                                color: white;
                            }
                        }

                        .text_remainig {
                            color: white;
                        }

                        .block_left {
                            .text1 {
                                color: white;
                                &.highlight {
                                    color: white;
                                }
                            }
                        }
                    }

                    .open {
                        .total_distribution {
                            .text1 {
                                color: white;
                            }
                            .text2 {
                                color: white;
                            }
                        }

                        .graph {
                            .info {
                                .text1 {
                                    color: white;
                                }
                                .text2 {
                                    color: white;
                                }
                            }
                        }

                        .block_left {
                            .text1 {
                                color: white;
                            }
                            .text2 {
                                color: white;
                            }
                            .text3 {
                                color: white;
                            }
                        }
                    }
                }
            }
        }
    }

    .icon_container {
        width: 180px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (max-width: $mobile-size) {
            height: 100px;
            flex-direction: row;
        }

        .icon {
            width: 120px;
            height: 127px;
            background-size: contain;

            background-image: url("../../../resources/TPT/logo-tpt.png");
            margin-top: 40px;

            @media (max-width: $mobile-size) {
                width: 57px;
                height: 60px;
                margin-top: 0px;
                margin-left: 20px;
            }

            &.public {
                background-image: url("../../../resources/TPT/logo-tpt-p.svg");
            }

            .closed {
                display: none;
            }
        }

        .titles {
            display: flex;
            flex-direction: column;
            align-items: center;

            @media (max-width: $mobile-size) {
                margin-left: 23px;
                align-items: flex-start;
            }

            .text1 {
                @include fontBold(20px, white);
            }

            .text2 {
                margin-top: 2px;
                @include font(12px, white);

                @media (max-width: $mobile-size) {
                    line-height: 20px;
                }
            }
        }
    }

    .dummy {
        display: flex;
        flex-direction: row;

        @media (max-width: $mobile-size) {
            flex-direction: column;
        }

        .dummy1 {
            width: 1px;
            background-color: rgba(black, 0.1);

            @media (max-width: $mobile-size) {
                width: auto;
                height: 1px;
            }
        }
        .dummy2 {
            width: 1px;
            background-color: rgba(white, 0.1);

            @media (max-width: $mobile-size) {
                width: auto;
                height: 1px;
            }
        }
    }

    .contents {
        flex: 1;

        display: flex;
        flex-direction: column;
        padding: 40px 30px 40px 30px;

        @media (max-width: $mobile-size) {
            padding: 20px 20px 30px 20px;
        }

        .campaign_title {
            @include font(18px, white);
        }

        .box {
            margin-top: 16px;
            height: 82px;

            display: flex;
            flex-direction: column;

            .yet {
                display: flex;
                flex-direction: column;

                .opendate {
                    @include font(18px, #ffe646);

                    &:not(.highlight) {
                        color: white;
                    }
                }

                .text_remainig {
                    margin-top: 9px;
                    @include font(14px, rgba(white, 0.8));
                }

                .block_left {
                    margin-top: 9px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding-left: 10px;
                    padding-right: 10px;
                    height: 30px;

                    border-radius: 4px;
                    background-color: rgba(black, 0.4);

                    .text1 {
                        @include font(16px, white);
                        flex: 1;
                        // text-align: right;

                        &.highlight {
                            color: #ffe646;
                        }
                    }
                }
            }

            .open {
                .onsale {
                    width: 76px;
                    margin-top: -8px;
                    height: 20px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    border-radius: 4px;
                    background-color: #13ea5b;

                    @include fontBold(12px, white);

                    &.disabled {
                        width: auto;
                        height: auto;
                        border-radius: 0;
                        background-color: transparent;
                        justify-content: flex-start;

                        @include fontBold(14px, rgba(white, 0.8));
                    }
                }

                .total_distribution {
                    margin-top: 16px;
                    display: flex;
                    flex-direction: row;

                    .text1 {
                        @include font(14px, rgba(white, 0.8));
                    }

                    .text2 {
                        margin-left: 4px;
                        @include font(14px, rgba(white, 0.7));
                    }
                }

                .graph {
                    margin-top: 8px;
                    display: flex;
                    flex-direction: column;

                    .info {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        .text1 {
                            flex: 1;
                            @include font(14px, #13ea5b);
                        }

                        .text2 {
                            @include font(14px, rgba(white, 0.7));
                        }
                    }

                    .bar_container {
                        margin-top: 6px;
                        height: 6px;
                        border-radius: 3px;
                        background-color: rgba(#fff, 0.5);
                        overflow: hidden;

                        .bar {
                            height: 6px;

                            background-color: #13ea5b;
                        }
                    }
                }

                .block_left {
                    margin-top: 9px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding-left: 10px;
                    padding-right: 10px;
                    height: 30px;

                    border-radius: 4px;
                    background-color: rgba(black, 0.4);

                    .text1 {
                        @include font(16px, rgba(white, 0.8));
                        flex: 1;
                    }
                    .text2 {
                        @include font(14px, white);
                    }
                    .text3 {
                        margin-left: 4px;
                        @include font(14px, rgba(white, 0.3));
                    }
                }
            }
        }

        .detail_button {
            margin-top: 20px;
            height: 36px;

            border-radius: 18px;
            border: solid 1px rgba(#fff, 0.3);

            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .text {
                margin-top: 2px;
                @include font(14px, white);
            }

            .arrow {
                margin-left: 4px;
                width: 20px;
                height: 20px;
                background-size: cover;
                background-image: url("../../../resources/ico-arrow-c.svg");
            }
        }
    }
}

@import "mixins";
@import "variables";

.container {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;

    .inner_container {
        margin-top: 40px;
        width: 800px;
        position: relative;

        display: flex;
        flex-direction: column;

        @media (max-width: $mobile-size) {
            max-width: 350px;
            width: calc(100% - 40px);
        }

        .box {
            width: 100%;
            margin-top: 20px;
            padding-top: 30px;
            padding-bottom: 10px;
            border-radius: 2px;
            border: solid 1px rgba(255, 255, 255, 0.2);
            background-image: linear-gradient(295deg, #23293b, #39425d);

            display: flex;
            flex-direction: column;
            align-items: center;

            @mixin button() {
                cursor: pointer;

                width: 220px;
                height: 48px;
                margin-bottom: 20px;
                opacity: 0.3;
                border-radius: 24px;
                border: solid 1px #fff;

                @include font(14px, #2d2d2d);
                letter-spacing: -0.6px;
                small {
                    font-size: 12px;
                }

                &:hover {
                    opacity: 0.6;
                }
            }

            .button1 {
                @include button();

                .icon {
                    width: 14px;
                    height: 13px;
                    margin-right: 4px;
                    background-size: cover;
                    background-image: url("../../resources/ico-wallet-c-1.svg");
                }
            }

            .button2 {
                @include button();

                margin-top: 20px;

                .icon {
                    width: 14px;
                    height: 13px;
                    margin-right: 4px;
                    background-size: cover;
                    background-image: url("../../resources/ico-wallet-c-2.svg");
                }
            }
        }
    }
}

.connect_button {
    cursor: pointer;

    width: 220px;
    height: 46px;
    margin-bottom: 20px;

    border-radius: 24px;
    border: solid 1px rgba(255, 255, 255, 0.3);

    display: flex;
    flex-direction: row;
    align-items: center;

    &:not(.available) {
        background-color: rgba(255, 255, 255, 0.09);
    }

    &:hover {
        background-color: #ffe646;
        border-color: #ffe646;
        .text {
            color: black;
        }

        .icon {
            &:not(.available) {
                opacity: 1;
            }
        }
    }

    .text {
        @include font(12px, white);
        margin-left: 20px;
        flex: 1;
    }

    .icon {
        width: 36px;
        height: 36px;
        background-color: white;
        border-radius: 18px;
        margin-right: 5px;
        overflow: hidden;

        &:not(.available) {
            opacity: 0.5;
        }
    }
}

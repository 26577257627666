@import "mixins";
@import "variables";

.container {
    margin: 40px 0 0 0;
    width: 100%;

    .inner_container {
        width: calc(100% - 60px);
        border-radius: 2px;
        box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.5);
        border: solid 1px rgba(255, 255, 255, 0.2);
        background-image: linear-gradient(279deg, #23293b, #39425d);
        margin: 20px 0 0 0;
        padding: 30px;
        padding-bottom: 0;
        display: flex;
        flex-direction: row;

        @media (max-width: $mobile-size) {
            padding: 20px;
            width: calc(100% - 40px);
            flex-direction: column;
        }

        .line1 {
            width: 1px;
            height: 200px;
            opacity: 0.1;
            background-color: #000;
            margin: -30px 0 0 30px;

            @media (max-width: $mobile-size) {
                width: calc(100% + 40px);
                height: 1px;
                margin: 20px 0 0 -20px;
            }
        }

        .line2 {
            width: 1px;
            height: 200px;
            opacity: 0.1;
            background-color: #fff;
            margin: -30px 0 0 0;

            @media (max-width: $mobile-size) {
                width: calc(100% + 40px);
                height: 1px;
                margin: 0 0 0 -20px;
            }
        }
    }
}

@import "mixins";
@import "variables";

.container {
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    border: solid 1px #fff;
    background-color: rgba(255, 255, 255, 0.1);

    &.hidden {
        border: 0px;
        background-color: transparent;
    }

    .icon {
        margin-left: 21px;
        margin-top: 21px;
        margin-right: 12px;
        width: 20px;
        height: 20px;

        background-image: url("../../../resources/ico-not2.svg");
    }

    .text {
        @include fontLight(12px, #fd5214);
        flex: 1;
        word-break: break-all;
        line-height: 1.5;
        margin-top: 23px;
        margin-right: 20px;
        margin-bottom: 20px;
    }
}

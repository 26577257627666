@import "mixins";
@import "variables";

.container {
    @include container2();

    .campaign_title {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-bottom: 20px;

        .title1 {
            @include font(14px, #786f64);
        }

        .title2 {
            @include fontLight(14px, #c8c8c8);
        }
    }

    .inner {
        display: flex;
        flex-direction: column;

        border-radius: 2px;
        box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.5);
        border: solid 1px rgba(255, 255, 255, 0.2);
        background-image: linear-gradient(328deg, #23293b, #39425d);

        padding: 30px;
    }
}

@import "mixins";
@import "variables";

.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;

    .title {
        display: flex;
        margin-bottom: 4px;
        .icon {
            width: 13px;
            height: 13px;

            margin-right: 4px;
            border-radius: 4px;
        }
    }

    // .dummy {
    //     flex: 1;
    // }
    .num {
        display: flex;
        flex-direction: column;
        margin-left: 17px;
        margin-top: 6px;
        .dummy {
            height: 6px;
        }

        .axlUSDCamount {
            display: flex;
            flex-direction: row;

            .special {
                color: rgba(255, 255, 255, 0.3);
                size: 8px;
                height: 10px;
                margin: -3px 4px 0 0;
            }
        }
    }
}

@import "mixins";
@import "variables";

.container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .button {
        cursor: pointer;

        width: 400px;
        height: 48px;

        border-radius: 2px;
        border: solid 1px rgba(255, 255, 255, 0.3);
        display: flex;

        @include fontBold(14px, white);

        &:hover {
            border: solid 1px #ffe646;
            cursor: pointer;
            color: #ffe646;

            .buttoncontent {
                .buttonarrow {
                    background-image: url("../../../resources/ico-arrow-m-on.svg");
                }
            }
        }

        @media (max-width: $mobile-size) {
            width: 100%;
        }

        .buttoncontent {
            display: flex;
            flex-direction: row;
            margin: 18px auto 0 auto;
            .buttonmore {
                margin: 0 6px 0 0;
            }

            .buttonarrow {
                width: 20px;
                height: 20px;
                background-size: cover;
                margin: -4px 0 0 0;
                background-image: url("../../../resources/ico-arrow-m.svg");
            }
        }
    }
}

.container2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .button {
        width: 400px;
        height: 36px;
        border-radius: 18px;
        border: solid 1px rgba(255, 255, 255, 0.3);
        display: flex;
        flex-direction: row;
        margin: 30px 0 0 0;
        @include font(14px, #fff);
        justify-content: center;

        &:hover {
            background: #ffe646;
            color: black;
            cursor: pointer;

            .button_ico {
                background-image: url("../../../resources/ico-arrow-m-2.svg");
            }
        }

        .buttontext {
            line-height: 36px;
            // margin: 0 auto;
        }

        .button_ico {
            width: 20px;
            height: 20px;
            background-size: cover;
            margin: 8px 0 0 4px;
            background-image: url("../../../resources/ico-arrow-m.svg");
        }

        @media (max-width: $mobile-size) {
            width: calc(100% - 40px);
        }
    }
}

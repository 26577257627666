@import "mixins";
@import "variables";

.container {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(black, 0.3);

    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 10000;

    .contents {
        width: calc(280px - 40px);
        padding: 20px;

        border-radius: 5px;
        box-shadow: 0 20px 15px 0 rgba(0, 0, 0, 0.4);
        border: solid 1px rgba(255, 255, 255, 0.3);
        background-color: #181b23;
        // border-radius: 20px;
        // box-shadow: 0 20px 15px 0 rgba(0, 0, 0, 0.4);
        // border: solid 1px #3b3531;
        // background-color: #1a1a1e;

        display: flex;
        flex-direction: column;
        // align-items: center;

        .contents_title {
            margin: 10px 0 0 0;
        }

        .contents_dummy {
            width: 100%;
            height: 1px;
            background: rgba(#fff, 0.5);
            margin: 10px 0 0 0;
        }

        .text1 {
            margin-top: 20px;
            @include fontLight(12px, rgba(#fff, 0.5));
            text-align: center;
            line-height: 14px;
        }

        .box {
            margin-top: 20px;
            width: calc(100% - 40px);
            padding: 10px 20px 10px 20px;
            // border-radius: 10px;
            // background-color: #222226;
            border-radius: 2px;
            border: solid 1px rgba(255, 255, 255, 0.2);
            background-color: rgba(255, 255, 255, 0.05);

            display: flex;
            flex-direction: column;

            // align-items: center;

            .text1 {
                margin-top: 0px;
                @include fontLight(14px, #ffe646);
                text-align: right;
                line-height: 20px;
            }

            .text2 {
                margin-top: 10px;
                // @include font(16px, #ffe646);
                // text-align: right;
                display: flex;
                flex-direction: row;
                justify-content: right;

                .text2_amount {
                }

                .text2_symbol {
                    margin: 0 0 0 8px;
                    line-height: 18px;
                }
            }

            .text3 {
                @include fontLight(14px, #a02d46);
                text-align: right;
            }

            .boxdummy {
                width: 100%;
                height: 1px;
                margin: 10px 0 0 0;
                background: rgba(#fff, 0.3);
            }
        }

        .buttons {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 30px;
            height: 46px;
            width: 100%;
            // .dummy {
            //     width: 20px;
            // }

            // .button {
            //     flex: 1;
            //     height: 44px;
            // }

            .buttoncancel {
                width: calc(50% + 20px);
                margin: 0 0 -20px -20px;
                height: calc(100% + 20px);
                border-radius: 0 0 0 4px;
                background-color: #fff;
                display: flex;
                flex-direction: row;
                justify-content: center;

                &:hover {
                    cursor: pointer;
                    opacity: 0.9;
                }
            }
            .buttonstake {
                width: calc(50% + 20px);
                margin: 0 -20px -20px 0;
                height: calc(100% + 20px);
                border-radius: 0 0 4px 0;
                background-color: #ffe646;
                display: flex;
                flex-direction: row;
                justify-content: center;

                &:hover {
                    cursor: pointer;
                    opacity: 0.9;
                }
            }
        }
    }
}

@import "mixins";
@import "variables";

.campaign_cell4 {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1px;

    @media (max-width: $mobile-size) {
        flex-direction: column-reverse;
        margin-left: 16px;
        margin-right: 16px;
        margin-top: 30px;
    }

    .pool_button {
        height: 30px;
        margin-right: 10px;

        @media (max-width: $mobile-size) {
            align-self: flex-end;

            margin-right: -14px;

            margin-top: 10px;
            // margin-bottom: 10px;
        }
    }

    .inner {
        display: flex;
        flex-direction: row;
        align-items: center;

        height: 28px;

        padding-left: 16px;
        padding-right: 16px;

        border-radius: 14px;
        background-color: #141418;

        @media (max-width: $mobile-size) {
            height: auto;
            padding-top: 20px;
            padding-bottom: 20px;
            width: 100%;
        }

        .icon {
            width: 19px;
            height: 19px;

            background-size: contain;
            background-image: url("../../../resources/ico-deposit3.svg");
        }
        .text1 {
            margin-left: 8px;
            @include fontBold(14px, #786f64);
        }

        .sub {
            display: flex;
            flex-direction: row;
            align-items: center;

            flex: 1;
            @media (max-width: $mobile-size) {
                flex-direction: column;
                align-items: flex-end;
                justify-content: flex-end;
            }

            .text2 {
                @include font(16px, white);

                small {
                    font-size: 12px;
                }

                &.isZero {
                    color: #a02d46;
                }
            }

            .dummy {
                @include font(16px, white);

                small {
                    font-size: 12px;
                }

                @media (max-width: $mobile-size) {
                    display: none;
                }
            }

            .text3 {
                @include font(16px, white);

                small {
                    font-size: 12px;
                }

                @media (max-width: $mobile-size) {
                    margin-top: 6px;
                }

                &.isZero {
                    color: #a02d46;
                }
            }
        }
    }

    .dummy2 {
        flex: 1;

        @media (max-width: $mobile-size) {
            flex: none;
            display: none;
        }
    }
}

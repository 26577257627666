@import "mixins";
@import "variables";

.container::-webkit-scrollbar {
    display: none;
}

.container {
    display: flex;
    flex-direction: column;
    border-top: 1px solid rgba(#fff, 0.2);
    margin-top: 20px;

    width: 800px;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    @media (max-width: $mobile-size) {
        width: calc(100vw - 40px);
        overflow: scroll;
    }

    .titleWrapper {
        width: 800px;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;

        border-bottom: 1px solid rgba(#fff, 0.2);
        height: 48px;

        .membership {
            width: 146px;
            height: 100%;
            border-right: 1px solid rgba(#fff, 0.2);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            @include fontLight(12px, rgba(#fff, 0.5));
        }

        .title_box {
            flex: 1;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            border-right: 1px solid rgba(#fff, 0.2);

            .title {
                @include fontLight(12px, rgba(#fff, 0.5));
                text-align: center;
            }

            &:nth-of-type(2) {
                height: calc(100% - 28px);
            }

            &:nth-of-type(4) {
                border-right: 0px;
            }

            .lockperiod {
                display: flex;
                flex-direction: row;
                align-items: center;

                height: 20px;

                .icon {
                    width: 20px;
                    height: 20px;

                    background-size: cover;
                    background-image: url("../../../../../resources/ico-feelock011.svg");
                }

                .text {
                    margin-top: 4px;
                    height: 12px;
                    @include font(12px, #e94f4f);
                }
            }
        }
    }

    .tier {
        display: flex;
        flex-direction: column;
        width: 800px;

        > .tierBox {
            height: 50px;
            display: flex;
            flex-direction: row;
            align-items: center;
            border-bottom: 1px solid rgba(#fff, 0.2);

            &:hover {
                &.highlight {
                    cursor: pointer;
                    box-shadow: none;
                    background-color: rgba(#ffe646, 0.2);
                }
            }

            &.highlight {
                box-shadow: 0 0 6px 0 #ffe646;
                border: solid 1px #ffe646;
                background-color: rgba(white, 0.1);
            }

            > .title {
                display: flex;
                align-items: center;
                width: 146px;
                height: 100%;
                border-right: 1px solid rgba(white, 0.2);

                > .blackImg,
                .goldImg,
                .silverImg,
                .generalImg {
                    width: 40px;
                    height: 40px;
                    background-size: cover;
                    margin-right: 6px;
                    opacity: 0.3;

                    &.highlight {
                        opacity: 1;
                    }
                }

                > .blackImg {
                    background-image: url("../../../../../resources/tier/img-black.svg");
                }

                > .goldImg {
                    background-image: url("../../../../../resources/tier/img-gold.png");
                }

                > .silverImg {
                    background-image: url("../../../../../resources/tier/img-silver.png");
                }

                > .generalImg {
                    background-image: url("../../../../../resources/tier/img-general.svg");
                }
            }

            .detail {
                flex: 1;
                height: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                border-right: 1px solid rgba(#fff, 0.2);

                &:nth-of-type(2) {
                    height: calc(100% - 28px);
                }

                &:nth-of-type(4) {
                    border-right: 0px;
                }
            }
        }
    }
}

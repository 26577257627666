@import "mixins";
@import "variables";

.container {
    @include container1();

    .card_container {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        flex-wrap: wrap;
        justify-content: space-between;

        width: 100%;

        @media (max-width: $mobile-size) {
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: center;
        }

        .card {
            cursor: pointer;
            // @include box_shadow();
            margin-bottom: 40px;

            padding-top: 30px;
            padding-bottom: 30px;
            padding-left: 30px;
            padding-right: 30px;
            width: calc(50% - 60px - 20px);

            display: flex;
            flex-direction: row;
            align-items: center;

            transition-duration: border $anim-duration;

            border-radius: 2px;
            box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.5);
            border: solid 1px rgba(255, 255, 255, 0.2);
            background-image: linear-gradient(282deg, #23293b, #39425d);

            @media (max-width: $mobile-size) {
                width: calc(100% - 60px);
            }

            &:hover {
                border: solid 0.5px #ffe646;

                .col2 {
                    .icon {
                        background-image: url("../../../resources/ico-poll-next-on.svg");
                    }
                }
            }

            .col1 {
                display: flex;
                flex-direction: column;
                flex: 1;

                .text1 {
                    @include fontLight(20px, white);
                }
            }
            .col2 {
                margin-left: 20px;
                width: 30px;

                display: flex;

                align-items: center;
                justify-content: center;

                .icon {
                    transition-duration: $anim-duration;
                    width: 30px;
                    height: 30px;
                    background-size: cover;
                    background-image: url("../../../resources/ico-poll-next.svg");
                }
            }
        }
    }
}

@import "mixins";
@import "variables";

@keyframes rotate {
    0% {
        transform: rotate(-10deg);
    }

    50% {
        transform: rotate(10deg);
    }
    100% {
        transform: rotate(-10deg);
    }
}

.container {
    @include container1();
    align-items: flex-start;

    @media (max-width: $mobile-size) {
        @include container_main();
    }

    .campaignBanner_wrapper {
        width: 100%;
        height: 300px;
        margin: 0 0 40px 0;
        // background: yellow;

        @media (max-width: $mobile-size) {
            position: relative;
            height: 0;
            margin: 0 0 20px 0;
            padding-bottom: 69%;
        }

        .campaignBanner {
            width: 1200px;
            height: 300px;
            margin-top: 40px;
            background-size: cover;
            background-image: url("../../resources/V2/campaignbanner_v2.png");
            box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.5);

            @media (max-width: $mobile-size) {
                width: 100%;
                top: 0;
                left: 0;
                margin-top: 30px;

                background-image: url("../../resources/V2/campaignbanner_m_v2.png");
            }
        }
        .campaignBanner2 {
            width: 1200px;
            height: 300px;
            margin-top: 40px;
            background-size: cover;
            background-image: url("../../resources/sayve1.png");
            box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.5);

            @media (max-width: $mobile-size) {
                width: 100%;
                top: 0;
                left: 0;
                margin-top: 30px;

                background-image: url("../../resources/sayve2.png");
            }
        }
    }

    .section0_container {
        @include box_shadow();

        width: 100%;

        display: flex;
        flex-direction: row;

        @media (max-width: $mobile-size) {
            flex-direction: column;
        }
    }

    .row1_container {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-bottom: 20px;

        .row1 {
            flex: 1;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            @media (max-width: $mobile-size) {
                flex-direction: column;
                flex: none;
                width: 100%;
                justify-content: flex-start;
            }
            .section1_container {
                flex: 1;
                width: 100%;
            }
            .section2_container {
                flex: 1;
                width: 100%;
                display: flex;
                flex-direction: column;
                margin-left: 20px;

                @media (max-width: $mobile-size) {
                    margin-left: 0px;
                }
            }
        }
    }

    .row2_container {
        display: flex;
        flex-direction: row;
        width: 100%;

        @media (max-width: $mobile-size) {
            flex-direction: column;
        }
    }
}

@import "mixins";
@import "variables";

.loading_box {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;

    .icon {
        margin-top: 10px;
        width: 120px;
        height: 120px;
        background-size: cover;
        background-image: url("../../../resources/loading_01.gif");
    }

    .text {
        margin-top: 10px;

        @include fontLight(14px, #ececec);
        text-align: center;
    }

    .info {
        margin-top: 40px;
        width: calc(100% - 30px);
        padding-left: 10px;
        padding-right: 20px;
        padding-top: 20px;
        margin-bottom: 30px;
        border-radius: 5px;
        border: solid 1px #fff;
        background-color: rgba(255, 255, 255, 0.1);

        display: flex;
        flex-direction: column;

        @media (max-width: $mobile-size) {
            padding-bottom: 10px;
        }
    }
}

@import "mixins";
@import "variables";

.container {
    margin: 40px 0 20px 0;
    display: flex;
    flex-direction: column;

    @media (max-width: $mobile-size) {
        margin: 20px 0 20px 0;
    }

    .symbols {
        display: flex;
        flex-direction: row;

        .symbol {
            margin: 0 20px 0 0;
            width: 80px;
            height: 80px;
        }

        .symboltitle {
            display: flex;
            flex-direction: column;

            .symboltitle_symbol {
                margin: 18px 0 0 0;
                height: 20px;
                line-height: 20px;
            }

            .symboltitle_title {
                margin: 4px 0 0 0;
                height: 20px;
                line-height: 20px;
            }
        }
    }

    .titles {
        margin: 20px 0 0 0;
        display: flex;
        flex-direction: column;

        .title {
            // height: 34px;
            line-height: 34px;
            word-break: break-all;
        }

        .referralcheck {
            margin: 8px 0 0 0;
            height: 20px;
            display: flex;
            flex-direction: row;

            .referralcheck_ico {
                width: 20px;
                height: 20px;
                background-size: cover;
                background-image: url("../../../../resources/ico-referrallink-x.svg");
                margin: 0 6px 0 0;

                &.reftrue {
                    background-image: url("../../../../resources/ico-referrallink-o.svg");
                }
            }

            .referralcheck_title {
                height: 15px;
                line-height: 15px;
                margin: 3px 0 0 0;
            }
        }
    }
}

@import "mixins";
@import "variables";

.wrap {
    width: 100%;
    position: relative;
    z-index: 0;

    &.hash {
        background: transparent;
    }

    @media (max-width: $width-pc1) {
        height: calc(100%);
    }

    .container {
        // @include container1();
        width: 1200px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;

        @media (max-width: $width-pc1) {
            width: calc(100% - 60px);
        }
    }

    .trade_container {
        width: 100%;
        // height: 715px;
        display: flex;
        flex-direction: column;

        .labels {
            display: flex;
            flex-direction: row;
            margin-bottom: 4px;
            align-items: center;

            .button {
                margin-left: 10px;
                width: 100px;
                height: 50px;
            }

            .pollcard {
                display: flex;
                flex-direction: column;
                margin-bottom: 10px;
                padding: 20px;
                background-color: rgba(white, 0.1);

                .buttons {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .buttons2 {
                        display: flex;
                        flex-direction: row;
                        height: 50px;
                        width: 300px;
                    }
                }
            }
        }
    }
}

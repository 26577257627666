@import "mixins";
@import "variables";
.container {
    flex: 1;

    display: flex;
    flex-direction: row;
    align-items: center;
    .icon {
        width: 32px;
        height: 32px;
        background-size: cover;
        background-image: url("../../../../../resources/ipc-no01.svg");

        &.passed {
            background-image: url("../../../../../resources/ipc-success01.svg");
        }
    }

    .text {
        margin-left: 10px;
        @include font(16px, #e94f4f);
        &.passed {
            color: #55f559;
        }
    }

    @media (max-width: $mobile-size) {
        .icon {
            width: 26px;
            height: 26px;
        }

        .text {
            font-size: 14px;
        }
    }
}

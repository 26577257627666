@import "mixins";
@import "variables";

.container {
    @include container1();

    @media (max-width: $mobile-size) {
        width: 100%;
        padding: 0;
    }

    .title_container {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-end;

        .title {
            flex: 1;
        }

        @media (max-width: $mobile-size) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    .line_container {
        width: 100%;
    }

    .governance_polls_title_container {
        margin-top: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        position: relative;

        @media (max-width: $mobile-size) {
            flex-direction: column;
            align-items: flex-start;
        }

        .top_container {
            display: flex;
            @media (max-width: $mobile-size) {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-content: center;
            }
            .title {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-right: 40px;
                .text {
                    margin-left: 4px;
                    @include fontLight(20px, #5a5a5c);
                }
            }
        }

        .dummy {
            flex: 1;

            @media (max-width: $mobile-size) {
                flex: none;
                height: 20px;
            }
        }

        .bottom_container {
            @media (max-width: $mobile-size) {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-content: center;
                margin-bottom: 20px;
            }
            .buttons {
                display: flex;
                flex-direction: row;
                margin-right: 15px;

                @media (max-width: $mobile-size) {
                    width: 100%;
                    margin-right: 0px;
                }

                .dummy2 {
                    width: 20px;
                }

                .button_container {
                    height: 32px;
                    @media (max-width: $mobile-size) {
                        flex: 1;
                    }
                    .createbutton {
                        height: 32px;
                        margin: 0 10px;

                        @media (max-width: $mobile-size) {
                            margin: 0;
                        }

                        .button {
                            width: 100%;
                            @include font(12px, #fff);
                            border-radius: 2px;
                            border: solid 1px rgba(255, 255, 255, 0.3);

                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            line-height: 30px;
                            align-items: center;

                            padding: 0 10px;
                            cursor: pointer;

                            @media (max-width: $mobile-size) {
                                padding: 0px;
                            }

                            &:hover {
                                border: solid 1px rgba(#fff, 0.1);
                                background-color: rgba(#000, 0.1);
                                // width: calc(100% - 2px);
                                // height: 46px;
                                // line-height: 46px;
                            }
                        }
                        .poll_button {
                            width: 100%;
                            @include font(12px, #ffe646);
                            border-radius: 2px;
                            border: solid 1px rgba(255, 255, 255, 0.3);

                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            line-height: 30px;
                            align-items: center;

                            padding: 0 10px;
                            cursor: pointer;

                            @media (max-width: $mobile-size) {
                                padding: 0px;
                            }

                            &:hover {
                                border: solid 1px rgba(#fff, 0.1);
                                background-color: rgba(#000, 0.1);
                            }
                        }
                    }
                }
            }
        }
    }

    .governance_polls_cards {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        width: 100%;

        justify-content: space-between;

        @media (max-width: $mobile-size) {
            flex-direction: column;
            justify-content: center;
        }
    }
}

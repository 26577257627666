@import "mixins";
@import "variables";

.wrap {
    width: 100%;
    position: relative;
    z-index: 0;

    @media (max-width: $mobile-size) {
        height: calc(100%);
    }

    .bg_footer {
        // top: $header-pc;
        top: 0;
        left: 0px;
        width: 100vw;
        height: 100vh;
        position: fixed;

        z-index: -1;

        background-image: radial-gradient(
            circle at 0 100%,
            rgba(#4aa454, 0.7),
            rgba(#000, 0.7)
        );
    }

    .container {
        @include container2();
        position: relative;
        margin: 0 auto;

        @media (max-width: $mobile-size) {
            padding: 0;
        }

        &.hash {
            margin: 40px auto 0 auto;
        }

        .ipctopbanner {
            width: calc(100% + 2000px);
            height: 40px;
            margin: 0 0 0 -1000px;
        }

        .ipcbeforeopen {
            width: 100%;
            height: 70px;
            margin: 30px 0 -20px 0;
        }

        .header_container {
            width: 100%;
            display: flex;
            flex-direction: row;

            margin-top: 40px;
            margin-bottom: -20px;

            @media (max-width: $mobile-size) {
                margin-top: 0px;
                flex-direction: column;
            }
        }
    }
}

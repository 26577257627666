@import "mixins";
@import "variables";

.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .mobile_status {
        width: calc(100% - 420px);
        height: 32px;
        margin: 10px 0 0 0;
        display: none;

        @media (max-width: $mobile-size) {
            display: flex;
            flex-direction: row;
            width: 100%;
        }

        .mobile_statusfield {
            display: flex;
            flex-direction: column;
            margin: 0 0 0 10px;
            justify-content: center;

            .mobile_statustitle2 {
                @include font(14px, #55f559);

                &.fail {
                    @include font(14px, #fd5214);
                }

                &.ing {
                    @include font(14px, rgba(255, 255, 255, 0.4));
                }
            }

            .mobile_statussub {
                margin: 4px 0 0 0;
                @include fontLight(12px, #55f559);

                &.fail {
                    @include fontLight(12px, #fd5214);
                }

                &.ing {
                    @include fontLight(12px, rgba(255, 255, 255, 0.4));
                }
            }
        }

        .mobile_statustitle {
            line-height: 32px;
            margin: 0 0 0 10px;
            @include font(14px, #55f559);

            &.fail {
                @include font(14px, #fd5214);
            }

            &.ing {
                @include font(14px, rgba(255, 255, 255, 0.4));
            }
        }
    }

    .balancefield {
        height: 16px;
        text-align: right;
        position: relative;

        .balance {
            display: flex;
            flex-direction: row;
            position: absolute;
            right: 0;
            top: 1px;

            .balance_main {
                @include fontLight(14px, rgba(255, 255, 255, 0.3));

                &.fail {
                    @include fontLight(14px, #fd5214);
                }
            }

            .balance_symbol {
                margin: 1px 0 0 4px;
                @include fontLight(12px, rgba(255, 255, 255, 0.3));

                &.fail {
                    @include fontLight(12px, #fd5214);
                }
            }
        }
    }

    .contentbox {
        display: flex;
        flex-direction: row;
        margin: 6px 0 0 0;
        height: 52px;

        .status {
            width: calc(100% - 420px);
            height: 32px;
            margin: 10px 0 0 0;
            display: flex;
            flex-direction: row;

            @media (max-width: $mobile-size) {
                display: none;
            }

            .statusfield {
                display: flex;
                flex-direction: column;
                margin: 0 0 0 10px;
                justify-content: center;

                .statustitle2 {
                    @include font(14px, #55f559);

                    &.fail {
                        @include font(14px, #fd5214);
                    }

                    &.ing {
                        @include font(14px, rgba(255, 255, 255, 0.4));
                    }
                }

                .statussub {
                    margin: 4px 0 0 0;
                    @include fontLight(12px, #55f559);

                    &.fail {
                        @include fontLight(12px, #fd5214);
                    }

                    &.ing {
                        @include fontLight(12px, rgba(255, 255, 255, 0.4));
                    }
                }
            }

            .statustitle {
                line-height: 32px;
                margin: 0 0 0 10px;
                @include font(14px, #55f559);

                &.fail {
                    @include font(14px, #fd5214);
                }

                &.ing {
                    @include font(14px, rgba(255, 255, 255, 0.4));
                }
            }
        }

        .symbolfield {
            width: 420px;
            border-radius: 2px;
            box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
            display: flex;
            flex-direction: row;
            background-color: rgba(20, 20, 23, 0.6);

            @media (max-width: $mobile-size) {
                width: 100%;
            }

            .amount {
                width: calc(100% - 165px);

                .amount_text {
                    @include font(18px, #000);
                    line-height: 52px;
                    margin: 0 0 0 20px;
                }
            }

            .symbol {
                width: 165px;

                .deposit {
                    display: flex;
                    flex-direction: column;

                    .deposit_symbol {
                        height: 16px;
                        line-height: 16px;
                        text-align: right;
                        margin: 8px 20px 0 0;
                        @include font(14px, #000);
                    }

                    .deposit_day {
                        height: 20px;
                        display: flex;
                        flex-direction: row;
                        margin: 0 20px 0 0;

                        position: relative;

                        .deposit_day_text {
                            @include font(12px, #e94f4f);
                            position: absolute;
                            top: 4px;
                            right: 24px;
                        }

                        .deposit_day_ico {
                            width: 20px;
                            height: 20px;
                            margin: -2px 0 0 4px;
                            background-size: cover;
                            background-image: url("../../../../../resources/ico-feelock011.svg");
                            position: absolute;
                            top: 0;
                            right: 0;
                        }
                    }
                }

                .vp {
                    height: 30px;
                    margin: 11px 15px 0 0;
                    display: flex;
                    flex-direction: row;

                    .vp_symbol {
                        width: 20px;
                        @include font(14px, #fff);
                        line-height: 30px;
                    }

                    .vp_dummy {
                        width: 1px;
                        height: 20px;
                        margin: 5px 10px 0 10px;
                        background: rgba(#fff, 0.3);
                    }

                    .vp_button {
                        width: 109px;
                        border-radius: 15px;
                        border: solid 1px rgba(#fff, 0.3);
                        display: flex;
                        flex-direction: row;
                        justify-content: center;

                        &:hover {
                            border-radius: 15px;
                            border: 0;
                            background-color: #ffe646;
                            cursor: pointer;
                            .vp_ico {
                                background-image: url("../../../../../resources/ico-vp-t.svg");
                            }

                            .vp_text {
                                color: black;
                            }
                        }

                        .vp_ico {
                            width: 20px;
                            height: 20px;
                            margin: 4px 4px 0 0;
                            background-size: cover;
                            background-image: url("../../../../../resources/ico-vp.svg");
                        }

                        .vp_text {
                            line-height: 30px;
                            @include font(12px, #fff);
                        }
                    }
                }

                .participants {
                    height: 16px;
                    text-align: right;
                    @include font(14px, #fff);
                    line-height: 16px;
                    margin: 18px 20px 0 0;
                }
            }
        }
    }
}

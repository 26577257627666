@import "mixins";
@import "variables";

.testnet_badge {
    margin-left: 12px;
    width: 60px;
    height: 20px;

    @include fontBold(10px, white);

    border-radius: 10px;
    background-color: #fd5214;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

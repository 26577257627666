@import "mixins";
@import "variables";

.stake_info_container {
    display: flex;
    flex-direction: column;
    position: relative;

    .inner {
        @include box_shadow();

        display: flex;
        flex-direction: column;
        position: relative;
        padding: 30px;
        border-radius: 2px;
        border: solid 1px rgba(255, 255, 255, 0.2);
        background-image: linear-gradient(306deg, #23293b, #39425d);

        @media (max-width: $mobile-size) {
            padding: 20px;
        }

        .stake_container {
            display: flex;
            flex-direction: column;
            position: relative;

            .title {
                @include fontBold(20px, #ffffff);
                margin-bottom: 6px;
            }

            .apr_container {
                display: flex;
                flex-direction: row;
                align-items: flex-end;

                .apr {
                    display: flex;
                    align-items: center;
                    height: 20px;
                    padding: 0px 10px;
                    margin-right: 10px;
                    border-radius: 4px;
                    background-color: #ffe646;
                    justify-content: center;
                    @include fontBold(12px, #2d2d2d);
                }
            }

            .icon {
                margin-top: 48px;
                width: 120px;
                height: 120px;
                border-radius: 60px;
                background-color: #141418;
                border: solid 1px rgba(255, 255, 255, 0.2);

                align-self: center;
                @media (max-width: $mobile-size) {
                    width: 80px;
                    height: 80px;
                }

                .icon1 {
                    width: 120px;
                    height: 120px;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-image: url("../../resources/V2/logo-vkrusdc.svg");

                    @media (max-width: $mobile-size) {
                        width: 80px;
                        height: 80px;
                    }
                }
            }
        }

        .staked_container {
            display: flex;
            flex-direction: row;
            align-items: center;
            position: relative;

            .title_container {
                .title {
                    @include fontBold(20px, #ffffff);
                    margin-bottom: 6px;
                }

                .apr_container {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-end;

                    .apr {
                        display: flex;
                        align-items: center;
                        height: 20px;
                        padding: 0px 10px;
                        margin-right: 10px;
                        border-radius: 4px;
                        background-color: #ffe646;
                        @include fontBold(12px, #2d2d2d);

                        @media (max-width: 320px) {
                            height: 16px;
                            padding: 0px 7px;
                            margin-right: 7px;
                        }
                    }
                }
            }

            .icon {
                margin-right: 20px;
                width: 80px;
                height: 80px;
                border-radius: 60px;
                background-color: #141418;
                border: solid 1px rgba(255, 255, 255, 0.2);

                align-self: center;

                .icon1 {
                    width: 80px;
                    height: 80px;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-image: url("../../resources/V2/logo-vkrusdc.svg");
                    @media (max-width: 320px) {
                        width: 64px;
                        height: 64px;
                    }
                }

                @media (max-width: $mobile-size) {
                    margin-top: 0px;
                }
                @media (max-width: 320px) {
                    margin-right: 16px;
                    width: 64px;
                    height: 64px;
                }
            }
        }

        .not_info {
            display: flex;
            flex-direction: column;
            position: relative;

            .title {
                display: none;
            }

            .info_wrapper {
                display: flex;
                margin-top: 20px;
                .info {
                    display: flex;
                }
            }
            .icon {
                margin-top: 20px;
                width: 120px;
                height: 120px;
                border-radius: 60px;
                background-color: #141418;
                border: solid 1px rgba(255, 255, 255, 0.2);

                align-self: center;
                @media (max-width: $mobile-size) {
                    width: 80px;
                    height: 80px;
                }

                .icon1 {
                    width: 120px;
                    height: 120px;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-image: url("../../resources/V2/logo-vkrusdc.svg");

                    @media (max-width: $mobile-size) {
                        width: 80px;
                        height: 80px;
                    }
                }
            }
            .info_line_container {
                width: 100%;
                position: relative;
                left: 0px;
                margin-top: 70px;
                @media (max-width: $mobile-size) {
                    margin-top: 30px;
                }
            }
            .stake_button {
                display: flex;
                justify-content: center;
                align-self: center;
                margin-top: 40px;
                margin-bottom: 10px;
                width: 250px;
                height: 48px;

                @media (max-width: $mobile-size) {
                    margin-top: 20px;
                    width: 100%;
                }
            }
        }

        .info_wrapper {
            display: flex;
            // flex-direction: row;
            flex-direction: column;
            margin-top: 52px;

            @media (max-width: $mobile-size) {
                flex-direction: column;
            }

            .info {
                display: flex;
                flex-direction: column;

                flex: 1;

                @media (max-width: $mobile-size) {
                    width: 100%;
                }

                &.highlighted {
                    margin-top: 23px;
                    background-color: #222226;
                    height: 100px;
                    border-radius: 10px;
                }

                .title {
                    @include font(14px, #ffffff);
                    margin-bottom: 6px;
                }
                .amount_container {
                    display: flex;
                    flex-direction: row;
                    .icon {
                        width: 20px;
                        height: 20px;
                        margin-right: 6px;
                        background-image: url("../../resources/ico-deposit011.svg");
                    }
                }
            }

            .stakableamount {
                height: 15px;
                width: 100%;
                margin: 6px 0 0 0;

                .title {
                    @include font(12px, #fff);
                    line-height: 15px;
                }
            }

            .stakedummy {
                width: 100%;
                height: 1px;
                background-color: #fff;
                opacity: 0.1;
                margin: 6px 0 0 0;
            }

            .dummy {
                width: 20px;
                height: 20px;
            }
        }

        .reward_box {
            margin-top: 20px;
            min-height: calc(67px - 24px);
            border-radius: 4px;
            padding: 12px 20px;
            background-color: #202536;

            display: flex;
            flex-direction: row;
            align-items: center;

            @media (max-width: $mobile-size) {
                padding: 10px;
            }

            .reward {
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .reward_content {
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: row;
                    word-break: break-all;
                }
            }

            .button {
                width: 64px;
                height: 32px;
            }
        }

        .line_container {
            width: 100%;
            position: absolute;
            left: 0px;
            top: 220px;

            @media (max-width: $mobile-size) {
                top: 120px;
            }
            &.staked {
                top: 65px;
                @media (max-width: $mobile-size) {
                    top: 55px;
                }
            }
        }

        .stake_buttons {
            margin-top: 20px;
            margin-bottom: 10px;
            display: flex;
            flex-direction: row;
            height: 48px;

            .dummy {
                width: 10%;
            }
        }

        .stake_button {
            align-self: center;
            margin-top: 110px;
            margin-bottom: 10px;
            width: 250px;
            height: 48px;

            @media (max-width: $mobile-size) {
                margin-top: 52px;
                width: 100%;
            }
        }
    }
}

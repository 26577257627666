@import "mixins";
@import "variables";

.container {
    display: flex;
    flex-direction: column;
    cursor: pointer;

    &:hover {
        // opacity: 0.6;

        .row {
            .detailcontents {
                opacity: 0.6;
            }
            .detailbutton {
                .detailbutton_field {
                    .detailbutton_field_ico {
                        background-image: url("../../../../resources/ico-cexportlink02.svg");
                    }
                }
            }
        }
    }

    .row {
        // height: 123px;
        position: relative;
        display: flex;
        flex-direction: row;
        // align-items: center;
        margin: 20px 0 20px 20px;
        // background: green;

        @media (max-width: $mobile-size) {
            // flex-direction: column;
            flex-direction: row;
            align-items: flex-start;
            height: auto;
            margin: 20px 20px 0 20px;
        }

        .detailcontents {
            width: 730px;

            @media (max-width: $mobile-size) {
                width: calc(100% - 40px);
            }

            .content {
                display: flex;
                flex-direction: row;
                flex: 1;

                .icon {
                    width: 13px;
                    height: 13px;
                    margin-right: 8px;
                    background-image: url("../../../../resources/ico-exportlink3.svg");
                }
                .icon2 {
                    width: 13px;
                    height: 13px;
                    margin-right: 8px;
                    background-image: url("../../../../resources/ico-exportlink4.svg");
                }

                .msg {
                    display: flex;
                    flex-direction: row;
                    flex: 1;
                    line-height: 1.2;
                    margin-top: 5px;
                    flex-wrap: wrap;
                    @include fontLight(16px, #c8c8c8);

                    @media (max-width: $mobile-size) {
                        margin: -20px 0 0 0;
                    }

                    .success {
                        @include font(16px, white);
                    }
                    .fail {
                        @include font(16px, #a02d46);
                    }
                }

                @media (max-width: $mobile-size) {
                    margin-top: 30px;
                    align-items: flex-start;
                    margin-bottom: 20px;
                }
            }

            .date {
                display: flex;
                flex-direction: row;
                align-items: center;

                @media (max-width: $mobile-size) {
                    margin-bottom: 30px;
                }

                .date_text {
                    margin: 2px 0 0 0;
                }

                .date_icon {
                    width: 20px;
                    height: 20px;
                    margin-right: 4px;
                    background-image: url("../../../../resources/ico-day-w01.svg");
                }
            }
        }

        .detailbutton {
            width: 50px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;

            @media (max-width: $mobile-size) {
                right: -20px;
            }

            .detailbutton_field {
                width: 30px;
                height: 30px;
                border-radius: 15px;
                border: solid 1px rgba(255, 255, 255, 0.2);
                background-color: rgba(#000, 0.3);

                .detailbutton_field_ico {
                    width: 13px;
                    height: 13px;
                    margin: 8px auto 0 auto;
                    background-image: url("../../../../resources/ico-cexportlink01.svg");
                }
            }
        }
    }

    .historydummy {
        width: 100%;
        height: 1px;
        opacity: 0.2;
        margin: 0 auto;
        background-color: #fff;

        @media (max-width: $mobile-size) {
            width: 100%;
        }
    }
}

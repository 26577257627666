@import "mixins";
@import "variables";

.container {
    display: flex;
    flex-direction: row;
    align-items: center;

    .label {
        @include font(14px, #786f64);

        &.campaignfalse {
            color: rgba(#fff, 0.3);
        }

        &.hovercheck {
            color: rgba(#fff, 1);
        }

        small {
            font-size: 12px;
            @media (max-width: 320px) {
                font-size: 9.6px;
            }
        }
    }
}

$anim-duration: 0.2s;
$mobile-size2: 1250px;
$mobile-size: 1000px;
$mobile-min-size: 420px;

$width-pc1: 1200px;
$width-pc2: 800px;
$width-mobile: 100%;

$header-pc: 70px;
$header-mobile: 100px;

$footer-pc: 100px;
$footer-margin-pc: 100px;
$footer-mobile: 182px;
$footer-margin-mobile: 100px;

$TPT_default_color: #1bffbc;
$sayve-default-color: #4aa454;

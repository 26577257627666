@import "mixins";
@import "variables";

.container {
    margin: 0 0 40px 0;
    display: flex;
    flex-direction: column;

    .description {
        margin: 20px 0 0 0;
        @include fontLight(14px, #fff);
        line-height: 22px;
        white-space: pre-wrap;
        word-break: keep-all;
        text-align: justify;
    }
}

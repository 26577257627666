@import "mixins";
@import "variables";

.done_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: -31px;
    margin-right: -31px;
    margin-top: -31px;

    border-radius: 2px;
    box-shadow: 0 20px 15px 0 rgba(0, 0, 0, 0.3);
    border: solid 1px rgba(255, 255, 255, 0.2);
    background-image: linear-gradient(301deg, #23293b, #39425d);

    .icon {
        margin-top: 50px;
        width: 80px;
        height: 80px;
        background-size: cover;
        background-image: url("../../../resources/img-complete.svg");
    }

    .text1 {
        margin-top: 10px;
        @include font(14px, #55f559);
    }

    .box {
        width: calc(100% - 60px);
        margin-left: 30px;
        margin-right: 30px;
        margin-top: 40px;
        border-radius: 5px;
        border: solid 1px #fff;
        background-color: rgba(255, 255, 255, 0.1);
        padding-left: 10px;
        padding-right: 20px;
        padding-top: 20px;
    }

    .dummy {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        width: calc(100% - 30px);
        margin-left: 30px;
        margin-right: 30px;
        .dummy1 {
            height: 1px;
            background-color: #181b23;
        }
        .dummy2 {
            height: 1px;
            background-color: rgba(white, 0.2);
        }
    }

    .button {
        margin-top: 30px;
        margin-bottom: 40px;

        width: 400px;
        height: 48px;
        align-self: center;

        @media (max-width: $mobile-size) {
            width: calc(100% - 60px);
        }
    }
}

@import "mixins";
@import "variables";

.governance_create_form_container {
    @include container2();

    border-radius: 2px;

    .parse_container {
        padding: 30px 30px 0px 30px;
        border-radius: 2px;
        box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.5);
        border: solid 1px rgba(255, 255, 255, 0.2);
        background-image: linear-gradient(318deg, #23293b 100%, #39425d 0%);
    }
}

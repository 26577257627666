@import "mixins";
@import "variables";

.container {
    position: relative;
}

.inner_container {
    display: flex;
    flex-direction: column;

    .exchangeicon {
        width: 20px;
        height: 20px;
        align-self: center;
        margin-top: 20px;

        background-size: cover;
        background-repeat: no-repeat;
        background-image: url("../../../../resources/ipc-next2.svg");
        @media (max-width: $mobile-size) {
            margin-top: 20px;
        }
    }

    .line_container {
        width: calc(100% + 40px);
        position:relative;
        left:-20px;

        @media (max-width: $mobile-size) {
            left:-20px;
        }
    }

    .row {
        display: flex;
        flex-direction: row;
        align-items: center;

        @media (max-width: $mobile-size) {
            flex-direction: column;
        }

        .icon {
            width: 20px;
            height: 20px;

            margin-left: 11px;
            margin-right: 11px;

            background-size: cover;
            background-repeat: no-repeat;
            background-image: url("../../../../resources/icon-sum2.svg");
        }
    }
    .spreaddummy {
        width: 100%;
        height: 1px;
        background: rgba(#fff, 0.3);
    }
    .createbutton {
        width: 50%;
        height: 48px;
        margin: 30px auto 33px auto;

        .button {
            border-radius: 24px;
            width: 100%;
            background-color: rgba(#fff, 0.1);
            @include fontBold(16px, rgba(255, 255, 255, 0.3));

            display: flex;
            flex-direction: row;
            justify-content: center;
            line-height: 48px;
            cursor: pointer;

            &:hover {
                border: solid 1px rgba(#fff, 0.1);
                background-color: rgba(#000, 0.1);
                width: calc(100% - 2px);
                height: 46px;
                line-height: 46px;
            }

            &.enable {
                border-radius: 24px;
                background-color: #425ad0;
                @include fontBold(16px, white);
                cursor: pointer;

                &:hover {
                    background-color: rgba(#425ad0, 0.6);
                    border: solid 1px rgba(#425ad0, 0.6);
                    color: white;
                    width: calc(100% - 2px);
                    height: 46px;
                    line-height: 46px;
                }
            }

            &.enable2 {
                border-radius: 24px;
                background-color: #bd3552;
                @include fontBold(16px, white);
                cursor: pointer;

                &:hover {
                    background-color: rgba(#bd3552, 0.6);
                    border: solid 1px rgba(#bd3552, 0.6);
                    color: white;
                    width: calc(100% - 2px);
                    height: 46px;
                    line-height: 46px;
                }
            }
        }
    }
}

.header_container {
    display: flex;
    flex-direction: row;

    @media (max-width: $mobile-size) {
        flex-direction: column;
    }

    .item_container {
        display: flex;
        flex-direction: column;
        flex: 1;

        .row {
            margin-top: 20px;
            display: flex;
            flex-direction: column;

            padding: 30px;

            border-radius: 10px;
            background-color: #222226;

            .item {
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }

            .dummy {
                height: 30px;
            }
        }
    }
    .spacer {
        width: 30px;
        height: 30px;
    }
}

.tabbox {
    display: flex;
    flex-direction: row;
}

@import "mixins";
@import "variables";
.wrapper {
    display: flex;
    align-self: center;
    justify-content: center;
    width: 100%;

    .container {
        display: flex;

        width: 60%;
        height: 48px;

        @media (max-width: $mobile-size) {
            width: 100%;
        }
    }
}

@import "mixins";
@import "variables";

.container {
    display: flex;
    flex-direction: column;
    border-top: 1px solid rgba(#fff, 0.2);
    margin-top: 20px;
}

.titleWrapper {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    @include fontLight(12px, rgba(#fff, 0.5));
    border-bottom: 1px solid rgba(#fff, 0.2);

    > .dummy {
        flex: 1;
    }

    > .max {
        width: 170px;
        text-align: center;

        @media (max-width: $mobile-size) {
            width: 95px;
            text-align: right;
            padding-right: 20px;
        }
    }

    > .min {
        width: 240px;
        text-align: center;

        @media (max-width: $mobile-size) {
            width: 90px;
            text-align: right;
            padding-right: 10px;
        }
    }
}

.contentWrapper {
    display: flex;

    > .tier {
        width: calc(100% - 240px);
        border-right: 1px solid rgba(#fff, 0.2);
        display: flex;
        flex-direction: column;

        @media (max-width: $mobile-size) {
            width: calc(100% - 100px);
        }

        > .tierBox {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid rgba(#fff, 0.2);

            &:hover {
                &.highlight {
                    cursor: pointer;
                    box-shadow: none;
                    background-color: #1bffbc;

                    .title {
                        .webTitle {
                            color: black;
                        }
                    }

                    .detail {
                        .luna {
                            color: black;
                        }

                        .tptWrapper {
                            .special {
                                color: rgba(black, 0.3);
                            }
                            .tpt {
                                color: rgba(black, 0.3);
                            }
                        }
                    }
                }
            }

            &.highlight {
                box-shadow: 0 0 6px 0 #1bffbc;
                border: solid 1px #1bffbc;
                background-color: rgba(white, 0.1);
            }

            > .title {
                display: flex;
                align-items: center;

                > .webTitle {
                    @include fontLight(16px, white);
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    @media (max-width: $mobile-size) {
                        @include fontLight(12px, white);
                    }
                }

                > .blackImg,
                .goldImg,
                .silverImg,
                .generalImg {
                    width: 60px;
                    height: 60px;
                    background-size: cover;
                    margin-right: 20px;
                    opacity: 0.3;

                    &.highlight {
                        opacity: 1;
                    }

                    @media (max-width: $mobile-size) {
                        width: 40px;
                        height: 40px;
                        margin-right: 0;
                    }
                }

                > .blackImg {
                    background-image: url("../../../../../resources/tier/img-black.svg");
                }

                > .goldImg {
                    background-image: url("../../../../../resources/tier/img-gold.png");
                }

                > .silverImg {
                    background-image: url("../../../../../resources/tier/img-silver.png");
                }

                > .generalImg {
                    background-image: url("../../../../../resources/tier/img-general.svg");
                }
            }

            > .detail {
                width: 170px;

                @media (max-width: $mobile-size) {
                    width: 115px;
                }

                > .luna {
                    @include fontLight(16px, #fff);
                    margin-bottom: 2px;

                    @media (max-width: $mobile-size) {
                        @include fontLight(12px, #fff);
                    }

                    span {
                        font-size: 12px;
                    }
                }

                > .tptWrapper {
                    display: flex;
                    height: 12px;

                    > .special {
                        color: rgba(255, 255, 255, 0.3);
                        size: 12px;
                        margin: -3px 4px 0 0;
                    }

                    > .tpt {
                        @include fontLight(12px, rgba(#fff, 0.3));
                    }
                }
            }
        }
    }

    > .min {
        width: 240px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid rgba(#fff, 0.2);

        @media (max-width: $mobile-size) {
            width: 100px;
        }

        > .minWrapper {
            display: flex;
            flex-direction: column;

            > .luna {
                @include fontLight(16px, #fff);
                margin-bottom: 2px;
                text-align: center;

                @media (max-width: $mobile-size) {
                    @include fontLight(12px, #fff);
                }

                span {
                    font-size: 12px;
                }
            }

            > .tptWrapper {
                display: flex;

                > .special {
                    color: rgba(255, 255, 255, 0.3);
                    size: 12px;
                    margin: -3px 4px 0 0;
                }

                > .tpt {
                    @include fontLight(12px, rgba(#fff, 0.3));
                }
            }
        }
    }
}

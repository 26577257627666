@import "mixins";
@import "variables";

.container {
    display: flex;
    flex-direction: column;

    .voted_bar {
        height: 8px;
        background-color: rgba(255, 255, 255, 0.1);
        display: flex;
        flex-direction: row;
        border-radius: 4;
        overflow: hidden;
        border-radius: 4px;

        .agree {
            background-color: #4da4ff;
        }

        .disagree {
            background-color: #e14141;
        }

        .abstain {
            background-color: white;
        }
    }

    .quorum_line {
        height: 8px;
        display: flex;
        flex-direction: row;

        .line {
            width: 1px;
            background-color: #786f64;
        }
    }
    .quorum_bar {
        display: flex;
        flex-direction: row;
        height: 15px;
        position: relative;

        .text {
            position: absolute;
            top: 0px;
            transform: translateX(-50%);
            // left: 50%;
            @include fontLight(12px, #787878);
        }
    }
}

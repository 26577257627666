@import "mixins";
@import "variables";

.campaign_detail_pool_history_container {
    position: relative;
    // margin-top: 80px;
    width: 100%;
    margin: 0 0 0 0;

    .title_container {
        display: flex;
        flex-direction: row;
        margin: 0 0 20px 0;

        .dummy {
            flex: 1;
        }
    }

    .box {
        border-radius: 2px;
        border: solid 1px rgba(255, 255, 255, 0.2);
        background-color: rgba(255, 255, 255, 0.05);
        margin: 30px 0 0 0;
    }
}

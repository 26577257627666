@import "mixins";
@import "variables";

.governance_detail_container {
    @include container2();
    align-items: flex-start;

    .inner_container {
        display: flex;
        width: 100%;
        border-radius: 2px;
        box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.5);
        border: solid 1px rgba(255, 255, 255, 0.2);
        background-image: linear-gradient(306deg, #23293b 100%, #39425d);
        padding: 30px 30px 0px;

        @media (max-width: $mobile-size) {
            width: calc(100% - 60px);
        }

        &.hash {
            box-shadow: none;
            border: none;
            background-image: none;
        }

        .buttons {
            margin-top: 20px;
            margin-bottom: 40px;
            display: flex;
            flex-direction: row;
            align-items: center;

            .dummy {
                width: 20px;
                @media (max-width: $mobile-size) {
                    width: 10px;
                }
            }

            .button {
                cursor: pointer;
                flex: 1;
                height: 72px;

                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;

                border-radius: 5px;
                border: solid 1px rgba(255, 255, 255, 0.3);

                &.yes {
                    height: 70px;

                    border: solid 1px rgba(255, 255, 255, 0.3);
                    background-color: transparent;

                    @include fontLight(16px, #4da4ff);

                    &:hover {
                        border: solid 1px #4da4ff;
                        background-color: transparent;

                        @include fontLight(16px, #4da4ff);
                    }
                    &.selected {
                        border: solid 1px #4da4ff;
                        background-color: #4da4ff;

                        @include fontLight(16px, white);
                    }
                }

                &.no {
                    height: 70px;

                    border: solid 1px rgba(255, 255, 255, 0.3);
                    background-color: transparent;

                    @include fontLight(16px, #e14141);

                    &:hover {
                        border: solid 1px #e14141;
                        background-color: transparent;

                        @include fontLight(16px, a02d46);
                    }
                    &.selected {
                        border: solid 1px #e14141;
                        background-color: #e14141;

                        @include fontLight(16px, white);
                    }
                }

                &.abstain {
                    height: 70px;

                    border: solid 1px rgba(255, 255, 255, 0.3);
                    background-color: transparent;

                    @include fontLight(16px, rgba(255, 255, 255, 0.3));

                    &:hover {
                        border: solid 1px white;
                        background-color: transparent;

                        @include fontLight(16px, white);
                    }
                    &.selected {
                        border: solid 1px white;
                        background-color: white;

                        @include fontLight(16px, #2d2d2d);
                    }
                }
            }
        }
        .warning_container {
            border-radius: 5px;
            border: solid 1px rgba(255, 255, 255, 0.3);
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            margin-top: 10px;
        }
    }
}

@import "mixins";
@import "variables";

.container {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(black, 0.3);

    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 10000;

    .contents {
        width: calc(280px - 40px);
        height: calc(423px - 33px);
        padding: 30px 30px 0 30px;

        border-radius: 5px;
        box-shadow: 0 20px 15px 0 rgba(0, 0, 0, 0.4);
        border: solid 1px rgba(255, 255, 255, 0.3);
        background-color: #181b23;

        flex-direction: column;
        display: flex;
        // align-items: center;

        .contents_title {
            width: calc(100% + 60px);
            display: flex;
            flex-direction: row;
        }

        .popdummy {
            width: 100%;
            height: 1px;
            background: rgba(#fff, 0.5);
            margin: 10px 0 20px 0;
        }

        .popupcontents {
            height: 290px;
            display: flex;
            flex-direction: column;

            .popup1 {
                @include font(14px, #fff);
                word-break: break-all;
                line-height: 16px;
                white-space: pre-wrap;
            }
            .popup2 {
                @include fontLight(12px, #fff);
                word-break: break-all;
                line-height: 16px;
                margin: 20px 0 0 0;

                .popup2_sub {
                    color: #ffe646;
                }
            }
            .popup3 {
                @include fontLight(12px, #fff);
                word-break: break-all;
                line-height: 16px;
                margin: 20px 0 0 0;

                .popup3_sub {
                    color: #ffe646;
                }
            }
            .popup4 {
                @include fontLight(12px, #fff);
                word-break: break-all;
                line-height: 16px;
                margin: 20px 0 0 0;
            }
        }

        .claimable {
            text-align: left;
            @include font(14px, #fff);
        }

        .list {
            position: relative;
            width: 100%;
            height: calc(290px - 20px);
            // margin-top: 20px;
            padding-top: 10px;
            padding-bottom: 10px;
            border-radius: 10px;
            // background-color: #222226;

            overflow-y: auto;

            .empty {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translateX(-50%) translateY(-50%);
                width: 100%;
                text-align: center;
                @include fontLight(14px, #fff);
            }

            .card {
                // width: 100%;
                // height: 92px;
                display: flex;
                flex-direction: column;
                // padding-left: 20px;
                // padding-right: 20px;
                .item {
                    flex: 1;

                    .row1 {
                        // margin-top: 24px;
                        display: flex;
                        flex-direction: row;
                    }

                    .row2 {
                        margin-top: 6px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: flex-end;

                        .icon {
                            width: 20px;
                            height: 20px;
                            margin: -4px 0 0 4px;
                            background-size: contain;
                            // background-image: url("../../../../resources/ico-feelock0111.svg");
                        }
                    }
                }

                .row2dummy {
                    width: 100%;
                    height: 1px;
                    margin: 13px 0 13px 0;
                    background: rgba(#fff, 0.2);
                }
            }
        }

        .checkbutton {
            width: calc(100% + 60px);
            margin: 0 0 -30px -30px;
            height: 52px;
            border-radius: 0 0 4px 4px;
            background-color: #fff;
            display: flex;
            flex-direction: row;
            justify-content: center;

            &:hover {
                cursor: pointer;
                background: #ffe646;
            }
        }
    }
}

@import "mixins";
@import "variables";

.container {
    width: 100%;
    margin: 40px 0 20px 0;

    .containerwrap {
        display: flex;
        flex-direction: row;

        .logofield {
            width: 95px;
            margin: 0 14px 0 0;
            position: relative;

            @media (max-width: $mobile-size) {
                width: 90px;
                height: 112px;
                margin: 0 0 0 0;
            }

            .logofield_ico {
                width: 95px;
                height: 100px;
                background-size: cover;
                background-image: url("../../../../../../resources/TPT/logo-tpt-p.png");

                &.earlybird {
                    background-image: url("../../../../../../resources/TPT/logo-tpt.png");
                }
            }

            .logoclosed {
                position: absolute;
                top: 40px;
                left: 13px;
                width: 70px;
                height: 18px;
                background-color: rgba(#000, 0.8);
                display: flex;
                flex-direction: row;
                justify-content: center;
                @include font(12px, rgba(255, 255, 255, 0.64));
                line-height: 18px;
            }
        }

        .contentsfield {
            width: 590px;
            display: flex;
            flex-direction: column;

            @media (max-width: $mobile-size) {
                width: 100%;
                margin: 0 0 0 20px;
            }

            .titles {
                margin: 10px 0 0 0;
                display: flex;
                flex-direction: column;

                @media (max-width: $mobile-size) {
                    margin: 12px 0 0 0;
                }

                .title {
                    line-height: 29px;
                    display: flex;
                    flex-direction: row;
                    @include font(24px, #fff);

                    &.earlybird {
                        @include font(24px, $TPT-default-color);
                    }

                    @media (max-width: $mobile-size) {
                        line-height: normal;
                        @include font(18px, #fff);
                    }
                }

                .symbol {
                    height: 20px;
                    margin: 10px 0 0 0;
                    line-height: 20px;
                }

                .name {
                    height: 20px;
                    margin: 4px 0 0 0;
                    line-height: 20px;
                }
            }
        }
    }
}

@import "mixins";
@import "variables";

.container {
    display: flex;
    flex-direction: column;

    .dummy {
        margin-left: -30px;
        width: calc(100% + 60px);
        margin-bottom: 30px;
    }

    .dummy2 {
    }

    .estimate_box {
        position: relative;
        margin-top: 30px;
        margin-bottom: -10px;
        height: 52px;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        border-radius: 2px;
        border: solid 1px rgba(255, 255, 255, 0.2);
        background-color: rgba(255, 255, 255, 0.1);

        .value {
            @include fontBold(20px, #ffe646);
            margin-top: 5px;
        }
    }
}

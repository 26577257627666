@import "mixins";
@import "variables";

.container {
    width: 520px;
    height: 400px;
    margin: 0 30px 0 0;
    display: flex;
    flex-direction: column;

    @media (max-width: $mobile-size) {
        width: calc(100%);
        margin-top: 40px;
        height: 380px;
    }

    .header_myrewards_title {
        width: 100%;
        height: 24px;
        line-height: 24px;
        margin: 0 0 20px 0;
    }

    .header_myrewards_wrap {
        width: 100%;
        // height: calc(100% + 300px);

        background-color: rgba(0, 0, 0, 0.2);
        position: relative;
        z-index: 2;

        .header_myrewards_bg_footer {
            top: 0;
            left: 0px;
            width: calc(100% + 2px);
            border-radius: 2px;
            height: calc(100%);
            position: absolute;
            background-image: linear-gradient(289deg, #23293b, #39425d);
            z-index: -1;

            &.isIpc {
                background-image: linear-gradient(
                    240deg,
                    rgba($TPT-default-color, 0.5),
                    rgba(242, 144, 119, 0)
                );
            }

            @media (max-width: $mobile-size) {
                &.isIpc {
                    height: 298px;
                }
            }
        }

        .header_myrewards_contents {
            width: 100%;
            height: 356px;
            border-radius: 2px;
            border: solid 1px rgba(255, 255, 255, 0.3);
            box-shadow: 0 20px 15px 0 rgba(0, 0, 0, 0.3);
            display: flex;
            flex-direction: column;

            &.isIpc {
                height: 296px;
            }

            @media (max-width: $mobile-size) {
                display: none;
            }

            .myrewards_contents_titles {
                width: 100%;
                height: 59px;
            }

            .myrewards_contents_dummy {
                width: 520px;
                height: 1px;
                opacity: 0.2;
                background-color: #fff;
                margin: 0 auto;

                @media (max-width: $mobile-size) {
                    width: calc(100%);
                }
            }

            .myrewards_claim {
                height: 116px;
                width: 100%;

                .myrewards_claimbutton {
                    width: calc(100% - 60px);
                    height: 36px;
                    margin: 40px auto 0 auto;
                }
            }
        }

        .mobile_header_myrewards_contents {
            width: 100%;
            height: 387px;
            // opacity: 0.7;
            // transform: rotate(-180deg);
            border-radius: 2px;
            border: solid 1px rgba(255, 255, 255, 0.3);
            box-shadow: 0 20px 15px 0 rgba(0, 0, 0, 0.3);
            // background-image: linear-gradient(37deg, #f035f0, #000);
            display: none;

            @media (max-width: $mobile-size) {
                display: flex;
                flex-direction: column;
                &.isIpc {
                    height: 296px;
                }
            }

            .mobile_myrewards_contents_titles {
                height: 69px;
                width: calc(100% - 38px);
                margin: 19px auto 0 auto;
            }

            .mobile_myrewards_contents_dummy {
                width: 100%;
                height: 1px;
                background: rgba(255, 255, 255, 0.3);
                margin: 13px 0 0 0;
            }

            .mobile_myrewards_claim {
                height: 116px;
                width: 100%;

                .mobile_myrewards_claimbutton {
                    width: calc(100% - 40px);
                    height: 36px;
                    margin: 25px auto 0 auto;
                }
            }
        }
    }
}

.ptitle_container {
    width: calc(100% - 60px);
    height: 15px;
    margin: 34px auto 0 auto;
    display: flex;
    flex-direction: row;

    .ptitle_info {
        width: 200px;
        line-height: 15px;
    }

    .ptitle_locked {
        width: 130px;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: right;

        .ptitle_locked_title {
            line-height: 15px;
        }

        .ptitle_locked_ico {
            width: 20px;
            height: 20px;
            margin: -4px 0 0 4px;
            background-size: cover;
            background-image: url("../../../../resources/ico-feelock01.svg");
        }
    }

    .ptitle_claimable {
        width: 130px;
        display: flex;
        flex-direction: row;
        justify-content: right;
    }
}

.pdetail_container {
    width: calc(100% - 60px);
    height: 20px;
    margin: 20px auto 0 auto;
    display: flex;
    flex-direction: row;

    .pdetail_info {
        width: 200px;
        height: 100%;
        display: flex;
        flex-direction: row;

        .pdetail_info_ico {
            width: 20px;
            height: 20px;
            background-size: cover;
            background-image: url("../../../../resources/ico-rewards-01.svg");

            &.sharing {
                background-image: url("../../../../resources/ico-rewards-02.svg");
            }

            &.deposit {
                background-image: url("../../../../resources/ico-rewards-03.svg");
            }
        }

        .pdetail_info_title {
            line-height: 23px;
            margin: 0 0 0 6px;
        }
    }

    .pdetail_locked {
        width: 130px;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: right;

        .pdetail_locked_wrap {
            display: flex;
            flex-direction: row;
            justify-content: right;
            .pdetail_locked_amount {
                line-height: 22px;
            }

            .pdetail_locked_symbol {
                margin: 0 0 0 4px;
                line-height: 24px;
            }
        }
    }

    .pdetail_claimable {
        width: 130px;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: right;

        .pdetail_claimable_wrap {
            display: flex;
            flex-direction: row;
            justify-content: right;
            .pdetail_claimable_amount {
                line-height: 20px;
            }

            .pdetail_claimable_symbol {
                margin: 0 0 0 4px;
                line-height: 22px;
            }
        }
    }
}

.mobile_pdetail_container {
    width: 100%;
    display: flex;
    flex-direction: column;

    .mobile_title {
        height: 20px;
        margin: 0 0 10px 0;
        display: flex;
        flex-direction: row;

        .mobile_title_ico {
            width: 20px;
            height: 20px;
            background-size: cover;
            margin: 0 6px 0 0;
            background-image: url("../../../../resources/ico-rewards-01.svg");

            &.sharing {
                background-image: url("../../../../resources/ico-rewards-02.svg");
            }

            &.deposit {
                background-image: url("../../../../resources/ico-rewards-03.svg");
            }
        }

        .mobile_title_text {
            line-height: 22px;
        }
    }

    .mobile_contents {
        height: 38px;
        display: flex;
        flex-direction: row;

        .mobile_contents_locked {
            width: calc(50% - 1px);
            height: 100%;
            display: flex;
            flex-direction: column;

            .mobile_contents_locked_title {
                height: 20px;
                display: flex;
                flex-direction: row;

                .locked_title_text {
                    line-height: 20px;
                }

                .locked_title_ico {
                    width: 20px;
                    height: 20px;
                    margin: -2px 0 0 4px;
                    background-size: cover;
                    background-image: url("../../../../resources/ico-feelock01.svg");
                }
            }

            .mobile_contents_locked_amount {
                height: 19px;
                .pdetail_locked_wrap {
                    display: flex;
                    flex-direction: row;

                    .pdetail_locked_amount {
                        line-height: 20px;
                    }

                    .pdetail_locked_symbol {
                        margin: 0 0 0 4px;
                        line-height: 24px;
                    }
                }
            }
        }

        .mobile_contents_dummy {
            width: 1px;
            height: 20px;
            margin: 11px 0 0 0;
            background: rgba(#fff, 0.2);
        }

        .mobile_contents_claimable {
            width: 50%;
            height: 100%;
            // background: yellow;
            display: flex;
            flex-direction: column;
            position: relative;

            .mobile_contents_claimable_title {
                height: 20px;
                // display: flex;
                // flex-direction: row;
                // justify-content: right;
                position: absolute;
                right: 0;
                top: 0;

                .claimable_title_text {
                    line-height: 20px;
                }
            }

            .mobile_contents_claimable_amount {
                height: 19px;
                .pdetail_claimable_wrap {
                    display: flex;
                    flex-direction: row;
                    // justify-content: right;
                    position: absolute;
                    right: 0;
                    top: 20px;

                    .pdetail_claimable_amount {
                        line-height: 22px;
                    }

                    .pdetail_claimable_symbol {
                        margin: 0 0 0 4px;
                        line-height: 24px;
                    }
                }
            }
        }
    }
}

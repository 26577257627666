@import "mixins";
@import "variables";

.container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 20px;
    align-items: center;
    width: 100%;
    height: 40px;

    position: relative;

    border-radius: 20px;
    background-color: #282a3c;

    @media (max-width: $mobile-size) {
        // height: auto;
        // min-height: 40px;
        // padding-top: 10px;
        // padding-bottom: 10px;
    }

    .button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        height: 100%;
        flex: 1;
        background-color: #222226;

        .text {
            @include font(12px, rgba(white, 0.2));
        }

        &.left {
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
            background-color: #282a3c;
            &.selected {
                border-radius: 20px;
                border: solid 1px #ffe646;
                background-color: rgba(255, 230, 70, 0.3);
                .text{
                    @include font(12px, #ffe646);
                }
            }
        }

        &.right {
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
            background-color: #282a3c;
            &.selected {
                border-radius: 20px;
                border: solid 1px #ffe646;
                background-color: rgba(255, 230, 70, 0.3);
                .text{
                    @include font(12px, #ffe646);
                }
            }
        }
    }

    .icon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);

        width: 20px;
        height: 20px;
        background-image: url("../../../../resources/img-next.svg");
    }
}

@import "mixins";
@import "variables";
.container {
    width: calc(420px - 40px);
    padding-left: 20px;
    padding-right: 20px;

    border-radius: 2px;
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
    background-color: rgba(#141418, 0.6);

    display: flex;
    flex-direction: row;
    align-items: center;

    @media (max-width: $mobile-size) {
        width: calc(100% - 40px);
        margin-top: 10px;
        height: 52px;

        .text {
            font-size: 14px;
        }
    }

    .text {
        flex: 1;
        @include font(18px, #e94f4f);
        &.passed {
            color: white;
        }
    }

    .suffix_deposit {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .row1 {
            @include font(14px, white);
        }

        .row2 {
            display: flex;
            flex-direction: row;
            align-items: center;

            .text {
                @include font(12px, #e94f4f);
                margin-top: 3px;
            }

            .icon {
                margin-left: 6px;

                width: 20px;
                height: 20px;

                background-size: cover;
                background-image: url("../../../../../resources/ico-feelock011.svg");
            }
        }
    }

    .suffix_participation {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        @include font(14px, white);
    }

    .suffix_vp {
        display: flex;
        flex-direction: row;
        align-items: center;

        .col1 {
            @include font(14px, white);
        }
        .col2 {
            margin-left: 10px;
            width: 1px;
            height: 20px;
            opacity: 0.3;
            background-color: #fff;
        }

        .col3 {
            margin-left: 10px;
            display: flex;
            flex-direction: row;
            align-items: center;

            height: 30px;
            padding-left: 12px;
            padding-right: 12px;

            border-radius: 15px;
            border: solid 1px rgba(#fff, 0.3);

            cursor: pointer;

            &:hover {
                background-color: #ffe646;

                .text {
                    color: #2d2d2d;
                }

                .icon {
                    background-image: url("../../../../../resources/ico-vp-t.svg");
                }
            }

            .text {
                margin-left: 4px;
                @include font(12px, white);
                margin-top: 2px;
            }

            .icon {
                width: 20px;
                height: 20px;

                background-size: cover;
                background-image: url("../../../../../resources/ico-vp.svg");
            }
        }
    }
}

@import "mixins";
@import "variables";

.container {
    width: 100%;
    height: 100%;
    border-radius: 2px;
    border: solid 1px rgba(#fff, 0.3);
    display: flex;
    flex-direction: row;
    justify-content: center;
    @include font(12px, #fff);

    &.rounded {
        border-radius: 15px;
    }

    &:hover {
        background: #ffe646;
        cursor: pointer;
        color: #000;

        .buttonico_vp {
            background-image: url("../../../resources/ico-arrow-ctrue.svg");
        }
    }

    .title {
        margin: 0 0 0 6px;
        height: 100%;
        line-height: 32px;
    }

    .buttonico_vp {
        width: 20px;
        height: 20px;
        background-size: cover;
        margin: 4px 0 0 6px;
        background-image: url("../../../resources/ico-arrow-c.svg");

        @media (max-width: $mobile-size) {
            margin: 4px 0 0 0;
        }
    }
}

@import "mixins";
@import "variables";

.container {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;

    .slip {
        margin: 24px 0 0 0;
    }

    .spread {
        margin-top: 8px;
        display: flex;
        flex-direction: row;
        align-items: center;

        @media (max-width: $mobile-size) {
            flex-direction: column;
            margin-top: 12px;
        }

        .group1 {
            flex: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 10px;

            @media (max-width: $mobile-size) {
                width: 100%;
            }
        }

        .button {
            flex: 1;
            height: 32px;
        }

        .dummy {
            width: 10px;
        }

        .dummy2 {
            width: 10px;
            @media (max-width: $mobile-size) {
                display: none;
            }
        }
    }
}

@import "mixins";
@import "variables";

.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;

    width: 100%;
    max-width: $width-pc1;
    z-index: 1000000;

    // @media (max-width: $width-pc1) {
    //     width: calc(100% - 40px);
    // }

    @media (max-width: $width-pc1) {
        display: none;
    }

    .logo {
        cursor: pointer;
        width: 113px;
        height: 38px;
        background-size: cover;
        background-image: url("../../resources/V2/logo-valkyrie_v2.svg");
    }

    .spacer {
        flex: 1;
    }

    .spacer2 {
        margin-left: -15px;
        margin-right: 30px;

        width: 2px;
        height: 16px;
        opacity: 0.3;
        background-color: #fff;
    }

    .docsbutton {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 30px;

        &:hover {
            .docsicon {
                background-image: url("../../resources/ico-docs-on.svg");
            }

            .docstitle {
                color: #ffe646;
            }
        }

        .docsicon {
            width: 20px;
            height: 20px;
            background-image: url("../../resources/ico-docs.svg");
        }

        .docstitle {
            margin-left: 6px;
            @include font(16px, rgba(white, 0.3));
            letter-spacing: -0.46px;
        }
    }

    .airdropbutton {
        width: 42px;
        height: 42px;
        cursor: pointer;

        margin-right: -5px;
        background-image: url("../../resources/ico-airdrop.svg");
    }

    .menu {
        @include font(16px, rgba(white, 0.3));

        display: flex;
        align-items: center;
        height: 100%;

        cursor: pointer;
        margin-right: 45px;
        text-decoration: none;

        &.selected {
            color: #ffe646;
        }

        &:hover {
            color: #ffe646;
        }
    }
}

.connect_container {
    @include font(16px, white);

    margin-left: 15px;

    .connected {
        cursor: pointer;

        display: flex;
        flex-direction: row;
        align-items: center;

        border: solid 1px #ffe646;
        padding-left: 12px;
        padding-right: 12px;

        height: 30px;
        border-radius: 15px;

        transition: opacity $anim-duration;

        position: relative;

        .button {
            display: flex;
            flex-direction: row;
            align-items: center;
            .icon1 {
                width: 14px;
                height: 12px;
                background-image: url("../../resources/ico-wallet-on.svg");
                background-size: cover;
            }

            .text1 {
                @include font(12px, white);
                margin-left: 4px;
            }

            .icon2 {
                margin-left: 8px;
                width: 1px;
                height: 14px;
                background-color: #ffe646;
            }

            .text2 {
                @include font(12px, #ffe646);
                margin-left: 8px;
            }

            &:hover {
                opacity: 0.6;
            }
        }

        .popup {
            cursor: default;
            position: absolute;
            z-index: 100000;
            right: 0px;
            top: 40px;
            width: 260px;

            border-radius: 2px;
            box-shadow: 0 20px 15px 0 rgba(0, 0, 0, 0.3);
            border: solid 1px rgba(255, 255, 255, 0.2);
            background-image: linear-gradient(319deg, #23293b, #39425d);
            // height: 297px;

            display: flex;
            flex-direction: column;
            .row0 {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-left: 20px;
                margin-top: 20px;

                .icon {
                    width: 34px;
                    height: 34px;
                    background-size: cover;
                    background-image: url("../../resources/ico-walletcoin.svg");
                }

                .text {
                    margin-left: 10px;
                    display: flex;
                    position: relative;
                    @include font(24px, #ffe646);
                }
            }

            .row1 {
                margin-left: 20px;
                margin-top: 10px;
                margin-right: 20px;
                word-break: break-all;

                @include font(13px, #c8c8c8);
                line-height: 1.33;
            }

            .row2 {
                display: flex;
                flex-direction: row;
                margin-top: 6px;
                margin-left: 20px;

                .copy_box {
                    cursor: pointer;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    height: 28px;
                    padding-right: 10px;
                    border-radius: 16px;
                    border: solid 1px rgba(white, 0.3);

                    transition: opacity $anim-duration;

                    &:hover {
                        opacity: 0.6;
                    }

                    .copy_box_icon {
                        margin-left: 10px;
                        width: 20px;
                        height: 20px;
                        background-size: cover;
                        background-image: url("../../resources/ico-paste-wallet-copy.svg");
                    }

                    .copy_box_text {
                        @include font(14px, rgba(white, 0.3));
                        margin-left: 4px;
                    }
                }
            }

            .row3 {
                margin-top: 30px;
                display: flex;
                flex-direction: column;

                .dummy1 {
                    height: 1px;
                    background-color: rgba(black, 0.1);
                }
                .dummy2 {
                    height: 1px;
                    background-color: rgba(white, 0.1);
                }
            }

            .row4 {
                margin-top: 30px;
                display: flex;
                flex-direction: column;

                .button {
                    margin-left: 30px;
                    margin-right: 30px;
                    height: 48px;
                }
            }

            .row5 {
                margin-top: 20px;
                height: 40px;
                padding-right: 20px;
                background-color: rgba(black, 0.1);

                display: flex;
                flex-direction: row;
                align-items: center;

                .button {
                    cursor: pointer;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    .text {
                        @include fontLight(12px, rgba(white, 0.3));
                        text-decoration: underline;
                    }
                    .icon {
                        width: 20px;
                        height: 20px;
                        background-size: cover;
                        background-image: url("../../resources/ico-disconnect-copy.svg");
                    }
                }
            }
        }
    }

    .connect {
        cursor: pointer;

        display: flex;
        flex-direction: row;
        align-items: center;

        border: solid 1px #ffe646;
        padding-left: 12px;
        padding-right: 12px;

        height: 30px;
        border-radius: 15px;

        transition: opacity $anim-duration;

        .icon {
            width: 14px;
            height: 12px;
            background-image: url("../../resources/ico-wallet.svg");
            background-size: contain;
        }

        .text {
            @include font(12px, #ffe646);
            margin-left: 4px;
        }

        &:hover {
            opacity: 0.6;
        }
    }
}

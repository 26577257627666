@import "mixins";
@import "variables";

.container {
    display: flex;
    flex-direction: column;

    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;

    .balance {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-bottom: 40px;
    }
    .row0 {
        display: flex;
        flex-direction: row;
        align-items: center;

        @media (max-width: $mobile-size) {
            flex-direction: column;
        }

        .col1 {
            flex: 1;
            position: relative;
            width: 100%;
            .label {
                position: absolute;
                bottom: 3px;
                right: 0px;
                display: flex;
                flex-direction: row;
                align-items: center;
            }
        }

        .icon {
            width: 28px;
            height: 28px;

            margin-left: 11px;
            margin-right: 11px;

            background-size: cover;
            background-repeat: no-repeat;
            background-image: url("../../../resources/icon-sum.svg");
        }
    }

    .row1 {
        margin-top: 38px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;

        .title_container {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        .bg1 {
            margin-top: 10px;
            width: 100%;
            height: 10px;
            border-radius: 5px;
            background-color: #2e2b29;
            overflow: hidden;

            display: flex;
            flex-direction: row;

            align-items: center;

            .rate {
                display: flex;
                height: 10px;
                background-color: #786f64;
            }
        }
    }
}

@import "mixins";
@import "variables";

.container {
    @include container2();

    .tradetab {
        width: 100%;
        border-radius: 0 2px 2px 0;
        border: solid 1px rgba(255, 255, 255, 0.2);
        background-image: linear-gradient(320deg, #23293b, #39425d);
        display: flex;
        flex-direction: column;

        @media (max-width: $width-pc1) {
            width: 100%;
        }

        .tradetab_bottom {
            margin: 20px 30px 0 30px;
            width: calc(100% - 60px);

            @media (max-width: $width-pc1) {
                margin: 20px 20px 0 20px;
                width: calc(100% - 40px);
            }
        }
    }
    .switch_box {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
        margin: 30px 30px 20px 30px;

        @media (max-width: $mobile-size) {
            height: auto;
            align-items: center;
        }
        .dummy {
            flex: 1;
        }
        .line_container {
            width: calc(100% + 60px);
            position: relative;
            left: 30px;

            @media (max-width: $mobile-size) {
                left: 0px;
            }
            &.staked {
                top: 65px;
                @media (max-width: $mobile-size) {
                    top: 55px;
                }
            }
        }
    }
}

@import "mixins";
@import "variables";

.container {
    width: calc(100% - 2px);
    height: 34px;
    border-radius: 17px;
    border: solid 1px rgba(#fff, 0.3);

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media (max-width: $mobile-size) {
        margin: -16px 0 0 0;
    }

    &.hovercheck {
        border-radius: 28.5px;
        background-color: #ffe646;

        .detail {
            color: #2d2d2d;
        }

        .arrow {
            background-image: url("../../../resources/ico-arrow-ctrue.svg");
        }
    }

    .detail {
        @include font(14px, #fff);
        line-height: 23px;
        margin: 0 6px 0 0;
        height: 20px;
    }

    .arrow {
        width: 20px;
        height: 20px;
        background-size: cover;
        background-image: url("../../../resources/ico-arrow-c.svg");
    }
}

@import "mixins";
@import "variables";

.container {
    display: flex;
    flex-direction: row;

    width: 160px;
    height: 32px;
    align-items: center;

    border-radius: 20px;
    background-color: #282a3c;
    border: transparent;

    .button {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        height: 100%;
        .text {
            @include font(14px, rgba(white, 0.3));
        }

        &.selected {
            border-radius: 16px;
            background-color: #ffe646;

            .text {
                @include font(14px, #2d2d2d);
            }
        }
    }
}

@import "mixins";
@import "variables";

.governance_polls_card {
    @include box_shadow();
    text-decoration: none;
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    width: calc(50% - 20px);

    overflow: hidden;
    margin-bottom: 40px;

    border-radius: 2px;
    box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.5);
    border: solid 1px rgba(255, 255, 255, 0.2);
    background-image: linear-gradient(299deg, #23293b, #39425d);

    &:hover {
        background-image: linear-gradient(315deg, #191b1e, #1b1f2c);
        border: solid 0.5px #ffe646;
    }

    &.expired {
        border: solid 1px #121213;
        background-color: rgba(0, 0, 0, 0.4);

        &:hover {
            // opacity: 0.4;
            // background-color: #000;
            background-color: rgba(0, 0, 0, 0.4);
            border: solid 1px rgba(#ffe646, 0.5);
        }
    }

    @media (max-width: $mobile-size) {
        width: calc(100%);
    }

    .inner {
        flex: 1;
        display: flex;
        flex-direction: column;

        padding-top: 30px;
        padding-left: 30px;
        padding-right: 30px;

        @media (max-width: $mobile-size) {
            padding: 20px;
        }

        .row0 {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        .row1 {
            flex-wrap: wrap;
            margin-top: 8px;
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        .row2 {
            margin-top: 20px;
            height: 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
            border-radius: 4px;
            padding-left: 10px;
            padding-right: 10px;
            // padding: 2px 10px 2px 6px;
            width: fit-content;

            &.inProgress {
                background-color: #ffffff;
                &.expried {
                    z-index: 999;
                }
            }
            &.executed {
                background-color: #ffe646;
                &.expried {
                    z-index: 999;
                }
            }
            &.passed {
                background-color: #4da4ff;
                &.expried {
                    z-index: 999;
                }
            }
            &.rejected {
                background-color: #e14141;
                &.expried {
                    z-index: 999;
                }
            }

            .icon {
                width: 13px;
                height: 13px;
                background-size: contain;

                &.inprogress {
                    background-image: url("../../resources/ico-progress-black.svg");
                }

                &.executed {
                    background-image: url("../../resources/ico-excuted-black.svg");
                }

                &.passed {
                    background-image: url("../../resources/ico-passed-white.svg");
                }

                &.rejected {
                    background-image: url("../../resources/ico-rejected-white.svg");
                }
            }

            .text {
                margin-left: 8px;
                @include fontBold(12px, #786f64);

                &.inprogress {
                    color: #2d2d2d;
                }

                &.executed {
                    color: #2d2d2d;
                }

                &.passed {
                    color: #ffffff;
                }

                &.rejected {
                    color: #ffffff;
                }
            }
        }

        .row3 {
            margin-top: 24px;
            display: flex;
            flex-direction: row;
            align-items: center;

            .text {
                @include font(20px, white);
            }
        }

        .voted_text {
            margin-top: 10px;
            display: flex;
            flex-direction: row;
            position: relative;

            .text {
                width: auto;
                overflow-x: hidden;

                @include fontBold(12px, white);
            }
        }

        .row4 {
            margin-top: 6px;
            display: flex;
            flex-direction: column;
            &.disabled {
                z-index: 999;
            }
        }
    }

    .buttons {
        margin-top: 0px;
        height: 80px;
        display: flex;
        flex-direction: row;
        align-items: center;

        .dummy {
            width: 2px;
            height: 20px;
            display: flex;
        }

        .button {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            @include font(12px, #ffe646);

            &.yes {
                color: #4da4ff;
            }

            &.no {
                color: #e14141;
            }

            &.abstain {
                color: #ffffff;
            }

            .text1 {
                margin-top: 2px;
            }
            .text2 {
                margin-top: 2px;
                // @include font(12px, #ffe646);
            }
        }
    }

    .expired {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        border-radius: 10;
        background-color: rgba(#000000, 0.5);

        &:hover {
            // opacity: 0.4;
            // background-color: #000;
            background-color: transparent;
            border: solid 1px rgba(#ffe646, 0.5);
        }
    }
}

@import "mixins";
@import "variables";

.container {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100vw;
    height: 100vh;

    background-color: rgba(black, 0.2);

    display: flex;
    align-items: center;
    justify-content: center;

    z-index: 123456789;

    .add_popup {
        width: calc(288px - 40px);

        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 30px;

        border-radius: 2px;
        box-shadow: 0 20px 15px 0 rgba(0, 0, 0, 0.3);
        border: solid 1px rgba(255, 255, 255, 0.2);
        background-image: linear-gradient(310deg, #23293b, #39425d);

        display: flex;
        flex-direction: column;

        align-items: center;

        textarea {
            @include font(14px, white);

            border-radius: 8px;
            resize: none;

            padding-top: 14px;
            padding-bottom: 14px;
            padding-left: 19px;
            padding-right: 19px;
            flex: 1;

            width: calc(100% - 40px);
            min-height: calc(148px - 28px);
            outline: none;
            -webkit-appearance: none;

            background-color: rgba(255, 255, 255, 0.1);
            border: solid 1px rgba(255, 255, 255, 0.1);

            &::placeholder {
                color: rgba(white, 0.3);
            }

            &:focus {
                height: 148px;
                background-color: #ffffff;
                border: 1px solid white;
                color: #2d2d2d;
            }
        }

        .paste {
            margin-top: 10px;
            display: flex;
            flex-direction: row;

            align-self: flex-end;

            .icon {
                width: 9px;
                height: 11px;
                background-size: cover;
                background-image: url("../../../resources/ico-paste.svg");
            }

            .text {
                margin-left: 4px;
                text-decoration: underline;
                @include font(12px, #ffe646);
            }
        }
        .buttons {
            width: calc(100% - 30px);

            margin-top: 25px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .button {
                width: 100px;
                height: 50px;
            }
        }
    }

    .error {
        width: calc(288px - 40px);

        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 30px;

        border-radius: 2px;
        box-shadow: 0 20px 15px 0 rgba(0, 0, 0, 0.3);
        border: solid 1px rgba(255, 255, 255, 0.2);
        background-image: linear-gradient(310deg, #23293b, #39425d);

        display: flex;
        flex-direction: column;

        align-items: center;

        .title {
            @include fontBold(16px, #a02d46);
        }

        .body {
            margin-top: 10px;
            @include fontLight(14px, #c8c8c8);
            text-align: center;
        }

        .buttons {
            margin-top: 25px;
            display: flex;
            align-items: center;
            justify-content: center;

            .button {
                width: 100px;
                height: 50px;
            }
        }
    }
}

@import "mixins";
@import "variables";

.container {
    // border-radius: 5px;
    // border: solid 1px #2e2b29;

    display: flex;
    flex-direction: column;
    // padding: 20px;

    .row {
        display: flex;
        flex-direction: row;

        .icon {
            width: 20px;
            height: 20px;
            margin-right: 10px;
            background-size: cover;
            background-image: url("../../resources/vp-ico-not2.svg");

            &.checked {
                background-image: url("../../resources/vp-ico-ok2.svg");
            }
        }

        .content {
            line-height: 20px;
            flex: 1;
            @include fontLight(12px, #fd5214);

            &.checked {
                color: #4056c5;
            }
        }
    }

    .dummy {
        height: 20px;
    }
}

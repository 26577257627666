@import "mixins";
@import "variables";

.governance_detail_poll_container {
    @include container2();

    .governance_detail_poll_header {
        // @include box_shadow();
        position: relative;
        display: flex;

        width: 100%;
        flex-direction: column;
        padding: 30px 0;
        margin-top: 40px;

        @media (max-width: $mobile-size) {
            // width: calc(100% - 60px);
            width: 100%;
            margin-top: 0px;
        }

        .row0 {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        .row1 {
            flex-wrap: wrap;
            margin-top: 2px;
            display: flex;
            flex-direction: row;
            align-items: center;

            .row1_1 {
                height: 24px;

                display: flex;
                flex-direction: row;
                align-items: center;
                flex-wrap: wrap;
            }
            .tag1 {
                margin-left: 12px;
                height: 24px;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding-left: 12px;
                padding-right: 12px;

                border-radius: 12px;
                background-color: rgba(255, 230, 70, 0.2);

                .icon {
                    width: 14px;
                    height: 14px;
                    background-size: cover;
                    background-image: url("../../resources/ico-time.svg");
                }

                .text {
                    flex: 1;
                    margin-left: 4px;
                    @include font(14px, #ffe646);
                }
            }
        }

        .row2 {
            margin-top: 20px;
            height: 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
            border-radius: 4px;
            width: fit-content;
            padding-left: 10px;
            padding-right: 10px;

            &.inProgress {
                background-color: #ffffff;
            }
            &.executed {
                background-color: #ffe646;
            }
            &.passed {
                background-color: #4da4ff;
            }
            &.rejected {
                background-color: #e14141;
            }
            .icon {
                width: 13px;
                height: 13px;
                background-size: cover;

                &.inprogress {
                    background-image: url("../../resources/ico-progress-black.svg");
                }

                &.executed {
                    background-image: url("../../resources/ico-excuted-black.svg");
                }

                &.passed {
                    background-image: url("../../resources/ico-passed-white.svg");
                }

                &.rejected {
                    background-image: url("../../resources/ico-rejected-white.svg");
                }
            }

            .text {
                margin-left: 4px;
                @include fontBold(12px, #786f64);

                &.inprogress {
                    color: #2d2d2d;
                }

                &.executed {
                    color: #2d2d2d;
                }

                &.passed {
                    color: #ffffff;
                }

                &.rejected {
                    color: #ffffff;
                }
            }
        }

        .row3 {
            margin-top: 20px;
            display: flex;
            flex-direction: row;
            align-items: center;

            .text {
                @include font(28px, white);

                @media (max-width: $mobile-size) {
                    @include font(20px, white);
                }
            }
        }

        .row5 {
            display: flex;
            flex-direction: row;

            margin-top: 7px;

            @media (max-width: $mobile-size) {
                margin-top: 10px;
            }

            .text1 {
                cursor: pointer;
                @include fontLight(14px, #0661b9);
                text-decoration: underline;
                margin-right: 40px;

                word-break: break-all;

                @media (max-width: $mobile-size) {
                    display: none;
                }
            }

            .text2 {
                display: none;
                @media (max-width: $mobile-size) {
                    cursor: pointer;
                    display: inline;
                    @include fontLight(14px, #0661b9);
                    text-decoration: underline;

                    word-break: break-all;
                    margin-right: 0px;
                }
            }
        }

        .row7 {
            display: flex;
            flex-direction: column;

            margin-top: 30px;
            .desc {
                @include fontLight(14px, white);
                width: 100%;
                white-space: pre-wrap;
                word-break: break-all;
                line-height: 1.57;
            }
            .link {
                cursor: pointer;
                @include fontLight(14px, #0661b9);
                overflow-x: hidden;
                text-overflow: ellipsis;
                text-decoration: underline;
                line-height: 1.57;
            }
        }

        .row8 {
            margin-top: 20px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .createbutton {
                width: 40%;
                margin: 30px auto 33px auto;
                height: 32px;

                @media (max-width: $mobile-size) {
                    width: 100%;
                }

                .button {
                    width: 100%;
                    @include fontBold(14px, #fff);
                    border-radius: 2px;
                    border: solid 1px rgba(255, 255, 255, 0.3);

                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    line-height: 48px;
                    cursor: pointer;

                    &:hover {
                        border: solid 1px rgba(#fff, 0.1);
                        background-color: rgba(#000, 0.1);
                        width: calc(100% - 2px);
                        height: 46px;
                        line-height: 46px;
                    }

                    &.enable {
                        border-radius: 24px;
                        background-color: #425ad0;
                        @include fontBold(16px, white);
                        cursor: pointer;

                        &:hover {
                            background-color: rgba(#425ad0, 0.6);
                            border: solid 1px rgba(#425ad0, 0.6);
                            color: white;
                            width: calc(100% - 2px);
                            height: 46px;
                            line-height: 46px;
                        }
                    }

                    &.enable2 {
                        border-radius: 24px;
                        background-color: #bd3552;
                        @include fontBold(16px, white);
                        cursor: pointer;

                        &:hover {
                            background-color: rgba(#bd3552, 0.6);
                            border: solid 1px rgba(#bd3552, 0.6);
                            color: white;
                            width: calc(100% - 2px);
                            height: 46px;
                            line-height: 46px;
                        }
                    }
                }
            }
            // .button {
            //     height: 32px;
            //     width: 200px;
            // }
        }
    }

    .governance_detail_vote_title {
        margin-top: 80px;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;

        position: relative;
        @media (max-width: $mobile-size) {
            margin-top: 40px;
        }

        .text {
            margin-right: 4px;
            @include fontLight(20px, #ffffff);
        }
    }

    .governance_detail_vote_header {
        @include box_shadow();
        margin-top: 20px;
        position: relative;
        display: flex;
        width: calc(100% - 60px);
        flex-direction: column;
        padding-top: 30px;
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 30px;

        border-radius: 2px;
        box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.5);
        border: solid 1px rgba(255, 255, 255, 0.2);
        background-image: linear-gradient(293deg, #23293b, #39425d);

        @media (max-width: $mobile-size) {
            width: calc(100% - 40px);
            padding-top: 20px;
            padding-left: 20px;
            padding-right: 20px;
            padding-bottom: 0px;
        }

        .row0 {
            display: flex;
            flex-direction: column;
        }

        .line_container {
            width: calc(100% + 60px);
            position: relative;
            left: -30px;

            @media (max-width: $mobile-size) {
                width: calc(100% + 40px);
                left: -20px;
            }
        }

        .row1 {
            // margin-top: 20px;
            // height: 90px;
            border-radius: 8px;

            display: flex;
            flex-direction: row;

            align-items: center;

            @media (max-width: $mobile-size) {
                height: auto;
                flex-direction: column;
            }

            .dummy1 {
                display: flex;
                width: 2px;
                height: 20px;
                @media (max-width: $mobile-size) {
                    display: none;
                }
            }

            .dummy2 {
                display: none;
                @media (max-width: $mobile-size) {
                    display: flex;
                    width: 100%;
                    height: 1px;
                }
                .line {
                    @media (max-width: $mobile-size) {
                        display: flex;
                        width: 100%;
                        height: 1px;
                        background-color: rgba(#fff, 0.2);
                    }
                }
            }

            .button {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                position: relative;

                @media (max-width: $mobile-size) {
                    flex: none;
                    width: calc(100% - 30px);
                    align-items: center;
                    // padding-left: 30px;
                    height: 90px;
                }

                .row11 {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-end;
                    &.yes {
                        @include fontLight(20px, #4da4ff);
                        .voted_icon {
                            position: relative;
                            width: 20px;
                            height: 20px;
                            margin-left: 4px;
                            background-size: cover;
                            background-image: url("../../resources/img-voted.svg");
                        }
                    }
                    &.no {
                        @include fontLight(20px, #e14141);
                        .voted_icon {
                            position: relative;
                            width: 20px;
                            height: 20px;
                            margin-left: 4px;
                            background-size: cover;
                            background-image: url("../../resources/img-voted.svg");
                        }
                    }
                    &.abstain {
                        @include fontLight(20px, #fff);
                        .voted_icon {
                            position: relative;
                            width: 20px;
                            height: 20px;
                            margin-left: 4px;
                            background-size: cover;
                            background-image: url("../../resources/img-voted.svg");
                        }
                    }
                }

                .row12 {
                    margin-top: 8px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    .icon {
                        width: 16px;
                        height: 16px;
                        background-size: cover;
                        margin-right: 4px;
                        &.yes {
                            background-image: url("../../resources/ico-progress-yes.svg");
                        }

                        &.no {
                            background-image: url("../../resources/ico-progress-no.svg");
                        }

                        &.abstain {
                            background-image: url("../../resources/ico-progress-abstain.svg");
                        }
                    }
                }

                // .voted_icon {
                //     position: absolute;
                //     top: 50%;
                //     transform: translateY(-50%);
                //     right: 20px;
                //     width: 24px;
                //     height: 24px;
                //     background-size: cover;
                //     background-image: url("../../resources/img-voted.svg");
                // }
            }
        }
        .row2 {
            margin-top: 40px;
            margin-bottom: 0px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            @media (max-width: $mobile-size) {
                margin-top: 0px;
                margin-bottom: 30px;
            }

            .button {
                width: 400px;
                height: 48px;

                @media (max-width: $mobile-size) {
                    width: 100%;
                }
            }
        }

        .row3 {
            margin-top: 30px;

            display: flex;
            flex-direction: column;
            justify-content: center;

            .col1 {
                flex: 1;
                display: flex;
            }

            .col2 {
                width: 50px;
                display: flex;
                flex-direction: row;
                justify-content: center;
            }

            .col3 {
                flex: 1;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                @media (max-width: $mobile-size) {
                    flex: none;
                    width: 150px;
                }
            }

            .header {
                flex: 1;
                display: flex;
                flex-direction: row;
                align-items: center;
            }

            .item {
                height: 72px;
                display: flex;
                flex-direction: row;
                align-items: center;

                .item1 {
                    @include fontLight(14px, #0661b9);
                    text-decoration: underline;
                    overflow-x: hidden;
                    text-overflow: ellipsis;
                    cursor: pointer;

                    @media (max-width: $mobile-size) {
                        display: none;
                    }
                }

                .item2 {
                    display: none;

                    @media (max-width: $mobile-size) {
                        display: inline;
                        @include fontLight(14px, #0661b9);
                        text-decoration: underline;
                        overflow-x: hidden;
                        text-overflow: ellipsis;
                        cursor: pointer;
                    }
                }
            }
        }
    }
    .governance_detail_voter {
        @include box_shadow();
        position: relative;
        display: flex;
        width: calc(100% - 60px);
        flex-direction: column;
        padding: 30px 30px 0px 30px;

        border-radius: 2px;
        border: solid 1px rgba(255, 255, 255, 0.2);
        background-color: rgba(255, 255, 255, 0.05);

        @media (max-width: $mobile-size) {
            width: calc(100% - 40px);
            padding: 30px 20px 0px 20px;
        }

        .row3 {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .col1 {
                flex: 1;
                display: flex;
            }
            .col2 {
                width: 50px;
                display: flex;
                flex-direction: row;
                justify-content: center;
            }
            .col3 {
                flex: 1;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                @media (max-width: $mobile-size) {
                    flex: none;
                    width: 150px;
                }
            }

            .line_container {
                width: calc(100% + 60px);
                position: relative;
                left: -30px;

                @media (max-width: $mobile-size) {
                    width: calc(100% + 40px);
                    left: -20px;
                }
            }

            .header {
                flex: 1;
                display: flex;
                flex-direction: row;
                align-items: center;
            }

            .item {
                height: 72px;
                display: flex;
                flex-direction: row;
                align-items: center;

                .item1 {
                    @include fontLight(14px, #0661b9);
                    text-decoration: underline;
                    overflow-x: hidden;
                    text-overflow: ellipsis;
                    cursor: pointer;

                    @media (max-width: $mobile-size) {
                        display: none;
                    }
                }

                .item2 {
                    display: none;

                    @media (max-width: $mobile-size) {
                        display: inline;
                        @include fontLight(14px, #0661b9);
                        text-decoration: underline;
                        overflow-x: hidden;
                        text-overflow: ellipsis;
                        cursor: pointer;
                    }
                }
            }
        }

        .more_button_container {
            display: flex;
            flex-direction: row;

            @media (max-width: $mobile-size) {
                width: calc(100% + 40px);
                margin-left: -20px;
            }
        }
    }
}

@import "mixins";
@import "variables";

.container {
    width: 310px;
    margin: 0 0 0 30px;
    display: flex;
    flex-direction: column;

    @media (max-width: $mobile-size) {
        width: 100%;
        margin: 20px 0 0 0;
    }

    .claimable_amount {
        width: calc(100%);
        display: flex;
        flex-direction: column;

        @media (max-width: $mobile-size) {
            width: 100%;
        }

        .claimable_amountnow {
            @include fontBold(30px, #ffe646);
            margin: 6px 0 0 0;
            height: 36px;
            line-height: 36px;

            &.zero {
                @include fontBold(30px, rgba(white, 0.3));

                small {
                    @include fontBold(12px, rgba(white, 0.3));
                }
            }

            small {
                @include fontBold(12px, #ffe646);
            }
        }
    }

    .claimable_button {
        width: 100%;
        height: 48px;
        margin: 33px 0 0 0;

        @media (max-width: $mobile-size) {
            margin: 10px 0 0 0;
        }
    }
}

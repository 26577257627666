@import "mixins";
@import "variables";

.container {
    display: flex;
    flex-direction: column;
    margin-top: 40px;

    @media (max-width: $mobile-size) {
        overflow: hidden;
    }

    .empty_box {
        display: flex;
        margin-top: 10px;
    }
    .box {
        display: flex;
        flex-direction: column;

        border-radius: 2px;
        border: solid 1px rgba(255, 255, 255, 0.2);
        background-color: rgba(255, 255, 255, 0.05);
        margin-top: 10px;

        @media (max-width: $mobile-size) {
            overflow-x: scroll;
            overflow-y: hidden;
            white-space: nowrap;

            &::-webkit-scrollbar {
                height: 2px;
            }
            &::-webkit-scrollbar-thumb {
                height: 2px;
                background-color: #ffe646;
                border-radius: 30px;
            }
            &::-webkit-scrollbar-track {
                background-color: transparent;
            }
        }

        .title_container {
            display: flex;
            flex-direction: row;
            margin-top: 33px;
            margin-bottom: 10px;

            @media (max-width: $mobile-size) {
                width: $width-pc1;
            }

            .dummy_margin {
                width: 30px;
                @media (max-width: $mobile-size) {
                    width: 20px;
                }
            }
            .button_dummy {
                width: 64px;
            }

            .title_item {
                flex: 1;
                display: flex;
                flex-direction: row;
                align-items: center;

                .icon {
                    margin-bottom: 3px;
                    width: 20px;
                    height: 20px;
                    background-image: url(../../resources/ico-feelock01.svg);
                    object-fit: contain;
                    opacity: 0.5;
                }
            }
        }

        .separator {
            height: 1px;
            background-color: rgba(255, 255, 255, 0.2);
            position: relative;

            @media (max-width: $mobile-size) {
                width: $width-pc1;
            }
        }

        .item_container {
            display: flex;
            flex-direction: column;

            @media (max-width: $mobile-size) {
                width: $width-pc1;
            }

            .inner_container {
                display: flex;
                flex-direction: row;
                margin-top: 10px;
                margin-bottom: 10px;

                .dummy_margin {
                    width: 30px;

                    @media (max-width: $mobile-size) {
                        width: 20px;
                    }
                }

                .title_item {
                    flex: 1;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .title {
                        overflow: hidden;
                        word-break: normal;
                        white-space: normal;
                        padding-right: 14px;
                    }
                    .symbol_icon {
                        margin-right: 10px;
                        width: 40px;
                        height: 40px;
                    }
                }

                .button_item {
                    width: 64px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-end;

                    .claimbutton {
                        position: relative;
                        width: 64px;
                        height: 32px;
                        cursor: pointer;

                        display: flex;
                        justify-content: center;
                        align-items: center;

                        border-radius: 16px;
                        border: solid 1px rgba(255, 255, 255, 0.3);

                        .icon {
                            width: 20px;
                            height: 20px;
                            background-size: contain;
                            background-image: url("../../resources/ico-more2.svg");
                        }

                        &:hover {
                            background-color: #ffe646;
                            border-color: #ffe646;
                            .icon {
                                background-image: url("../../resources/ico-more3.svg");
                            }
                        }
                    }
                }
            }

            .row_line {
                height: 1px;
                background-color: rgba(255, 255, 255, 0.2);
                position: relative;

                @media (max-width: $mobile-size) {
                    width: $width-pc1;
                }
            }
        }
    }
}

@import "mixins";
@import "variables";

.container {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    border: solid 1px rgba(#fff, 0.3);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
        background: #ffe646;

        .title {
            color: #2d2d2d;
        }
    }

    .title {
        @include font(12px, #fff);
    }
}

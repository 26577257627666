@import "mixins";
@import "variables";

.container {
    margin: 0 0 40px 0;

    .box {
        padding-right: 30px;
        padding-left: 30px;
        padding-bottom: 0px;

        border-radius: 2px;
        box-shadow: 0 20px 15px 0 rgba(0, 0, 0, 0.3);
        border: solid 1px rgba(255, 255, 255, 0.15);
        background-image: radial-gradient(
            circle at 0 100%,
            rgba(#000, 0.35),
            rgba(#1bffbc, 0.35)
        );

        display: flex;
        flex-direction: column;

        .gotosite_container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
            padding-top: 30px;

            .gotosite {
                text-decoration: none;
                height: 30px;
                padding-left: 18px;
                padding-right: 12px;
                border-radius: 15px;
                border: solid 1px #fff;

                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                @include font(14px, white);
                cursor: pointer;

                &:hover {
                    border-color: #ffe646;
                    color: #ffe646;
                    .arrowicon {
                        background-image: url("../../../../../resources/ico-arrow-top-true.svg");
                    }
                }

                .arrowicon {
                    margin-left: 4px;
                    width: 20px;
                    height: 20px;
                    background-image: url("../../../../../resources/ico-arrow-top.svg");
                }
            }

            .referralcheck {
                // margin: 10px 0 0 0;
                height: 20px;
                display: flex;
                flex-direction: row;

                @media (max-width: $mobile-size) {
                    display: none;
                }

                .referralcheck_ico {
                    width: 20px;
                    height: 20px;
                    background-size: cover;
                    background-image: url("../../../../../resources/ico-referrallink-x.svg");
                    margin: 0 6px 0 0;

                    &.reftrue {
                        background-image: url("../../../../../resources/ico-referrallink-o.svg");
                    }
                }

                .referralcheck_title {
                    height: 15px;
                    line-height: 15px;
                    margin: 3px 0 0 0;
                }
            }
        }

        .signature_box {
            height: 92px;
            margin-top: -20px;
            margin-bottom: 30px;
            border-radius: 2px;
            border: solid 1px rgba(#fff, 0.3);

            display: flex;
            flex-direction: column;
            .box1 {
                padding-left: 10px;
                padding-right: 10px;
                padding-top: 10px;
                padding-bottom: 10px;
                min-height: 20px;
                display: flex;
                flex-direction: row;
                align-items: center;

                .icon {
                    width: 20px;
                    height: 20px;
                    background-size: cover;
                    background-image: url("../../../../../resources/ico-referrallink-x.svg");

                    &.referrer {
                        background-image: url("../../../../../resources/ico-referrallink-o.svg");
                    }
                }

                .text {
                    @include fontLight(12px, white);
                    margin-left: 6px;
                }
            }

            .box2 {
                height: 1px;
                background-color: rgba(white, 0.3);
            }

            .box3 {
                padding-left: 10px;
                padding-right: 10px;
                padding-top: 10px;
                padding-bottom: 10px;
                min-height: 20px;
                display: flex;
                flex-direction: column;

                .text1 {
                    @include fontLight(12px, white);
                }

                .text2 {
                    margin-top: 4px;
                    word-break: break-all;
                    @include fontLight(10px, rgba(white, 0.3));
                }
            }
        }
    }

    .dummy {
        width: 800px;
        height: 1px;
        opacity: 0.2;
        background-color: #fff;

        &.isIpc {
            margin: 40px 0 0 0;
        }

        @media (max-width: $mobile-size) {
            width: 100%;
        }
    }

    .title {
        margin: 40px 0 20px 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .titlewrap {
            display: flex;
            flex-direction: row;
            .title_sub {
                margin: 2px 0 0 6px;
            }
        }

        .change {
            cursor: pointer;
            @include fontLight(16px, rgba(white, 0.3));
            text-decoration: underline;
            &:hover {
                color: #ffe646;
            }
            line-height: 18px;
        }
    }
}

.particontainer3 {
    width: 100%;
    height: 160px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: $mobile-size) {
        height: 203px;
    }

    .comingsoon {
        border-radius: 5px;
        border: solid 1px $TPT-default-color;
        background-color: rgba(142, 47, 245, 0.1);
        width: calc(100% - 60px);
        margin: 0 auto;
        height: 100px;
        display: flex;
        flex-direction: row;
        justify-content: center;

        .textwrap {
            @include fontLight(16px, $TPT-default-color);
            text-align: center;
            margin: 40px 0 0 0;
        }
    }
}

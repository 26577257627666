@import "mixins";
@import "variables";

.container {
    @include container1();
    align-items: flex-start;

    .ggg {
        width: 20px;
        height: 20px;
        cursor: pointer;
    }

    &.hash {
        @include container2();
    }

    .myvalue_container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        @media (max-width: $mobile-size) {
            width: 100%;
            flex-direction: column;
        }
    }
}

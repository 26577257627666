@import "mixins";
@import "variables";

.container {
    @include box_shadow();
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 2px;
    box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.5);
    border: solid 1px rgba(255, 255, 255, 0.2);
    background-image: linear-gradient(317deg, #23293b 100%, #39425d 0%);

    @media (max-width: $mobile-size) {
        width: 100%;
    }

    .inner_container {
        display: flex;
        flex-direction: column;

        padding: 30px;
        width: calc(100% - 60px);
        height: calc(100% - 60px);
        
        @media (max-width: $mobile-size) {
            width: calc(100% - 40px);
            height: calc(100% - 40px);
            padding: 20px;
        }

        .chart_container {
            width: 100%;
            height: calc(100% - 60px);
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 30px;

            @media (max-width: $mobile-size) {
                margin-top: 30px;
                margin-bottom: 0px;
            }
        }
    }
}

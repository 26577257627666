@import "mixins";
@import "variables";

.container {
    @include container2();

    .inner {
        border-radius: 2px;
        box-shadow: 0 20px 15px 0 rgba(0, 0, 0, 0.3);
        border: solid 1px rgba(255, 255, 255, 0.2);
        background-image: linear-gradient(310deg, #23293b, #39425d);

        display: flex;
        flex-direction: column;

        padding: 30px;

        .row1 {
            display: flex;
            flex-direction: column;
            flex: 1;

            .col1 {
                display: flex;
                flex-direction: row;
                align-items: center;

                .dash {
                    width: 13px;
                    height: 1px;
                    margin-left: 8px;
                    margin-right: 8px;
                    margin-bottom: 20px;
                    background-color: rgba(white, 0.3);
                }
            }
        }
    }
}

@import "mixins";
@import "variables";

.container {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    border: solid 1px #fff;
    background-color: rgba(255, 255, 255, 0.1);
    margin: 0 0 30px 0;

    padding-top: 20px;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 0px;
}

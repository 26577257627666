@import "mixins";
@import "variables";

.container {
    width: 490px;
    margin: 0 0 0 30px;
    display: flex;
    flex-direction: column;

    @media (max-width: $mobile-size) {
        width: 100%;
        margin: 20px 0 0 0;
    }

    .distributed {
        width: 100%;
        height: 58px;
        display: flex;
        flex-direction: row;

        .distributed_amount {
            width: calc(100% - 220px);
            display: flex;
            flex-direction: column;

            @media (max-width: $mobile-size) {
                width: 100%;
            }

            .distributed_amountnow {
                @include fontBold(30px, #fff);
                margin: 6px 0 0 0;
                height: 36px;
                line-height: 36px;

                span {
                    @include fontBold(12px, #fff);
                }
            }
        }
    }

    .progressbar {
        margin: 25px 0 0 0;
        width: 100%;
        height: 60px;
        display: flex;
        flex-direction: column;

        @media (max-width: $mobile-size) {
            margin: 10px 0 0 0;
        }

        .progressdate {
            width: 100%;
            height: 15px;
            position: relative;

            .start,
            .end {
                position: absolute;
                top: 0;
                @include fontLight(12px, #fff);
                height: 15px;
                line-height: 15px;
            }

            .start {
                left: 14%;
            }

            .end {
                right: 0;
            }
        }

        .bar {
            margin: 10px 0 0 0;
            width: 100%;
            height: 16px;
            background-color: #495068;
            position: relative;

            .progressbar_fixed {
                position: absolute;
                width: 20%;
                height: 14px;
                top: 0;
                left: 0;
                background: rgb(143, 64, 88);
            }

            .progressbar_wrap {
                position: absolute;
                top: 0;
                left: 0;
                height: 14px;
                width: 100%;
                display: flex;
                flex-direction: row;

                .progressbar_claimed {
                    height: 14px;
                    opacity: 0.8;
                    background-color: #d8d9df;
                }

                .progressbar_claimable {
                    height: 14px;
                    background-color: #ffe646;
                }
            }

            .progressbar_pointer,
            .progressbar_pointer2,
            .progressbar_pointer3 {
                position: absolute;
                top: -6px;

                background-color: #495068;
                width: 1px;
                height: 26px;
            }

            .progressbar_pointer {
                left: 0;
            }

            .progressbar_pointer2 {
                background-color: #a14263;
                left: 20%;
            }

            .progressbar_pointer3 {
                left: 100%;
            }
        }

        .progressamount {
            margin: 10px 0 0 0;
            width: 100%;
            height: 15px;
            position: relative;

            .locked,
            .end {
                position: absolute;
                top: 0;
                @include fontLight(12px, rgba(#fff, 0.5));
                height: 15px;
                line-height: 15px;
            }

            .locked {
                color: #a14263;
                left: 15%;
            }

            .end {
                right: 0;
            }
        }
    }
}

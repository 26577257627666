@import "mixins";
@import "variables";

.container {
    width: 100%;
    display: flex;
    flex-direction: column;

    .header {
        margin: 0 0 20px 0;
        height: 24px;
        line-height: 24px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .rewards_partamount_sub {
            .sub {
                line-height: 18px;
                .date {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .participants {
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        .icon1 {
                            width: 20px;
                            height: 20px;
                            background-size: cover;
                            background-image: url("../../../../../resources/ico-people01.svg");
                        }
                        .icon2 {
                            width: 20px;
                            height: 20px;
                            background-size: cover;
                            background-image: url("../../../../../resources/ico-day-b01.svg");
                        }

                        .text {
                            margin-top: 4px;
                            margin-left: 4px;
                            @include fontLight(12px, rgba(#fff, 0.5));
                        }
                    }
                    .dummy {
                        width: 1px;
                        height: 9px;
                        background-color: rgba(#fff, 0.5);
                        margin-left: 10px;
                        margin-right: 10px;
                    }
                }
            }
        }
    }

    .contents {
        display: flex;
        flex-direction: column;

        .rewards {
            margin: 0px 0 20px 0;
            // margin: 40px 0 20px 0;
            display: flex;
            flex-direction: column;

            .rewardsdetail {
                height: 52px;
                margin: 10px 0 0 0;
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                @media (max-width: $mobile-size) {
                    flex-direction: column;
                    justify-content: left;
                    height: 152px;
                }
            }
        }

        .condition {
            display: flex;
            flex-direction: column;
            margin: 0 0 40px 0;

            .conditiondetail {
                margin: 10px 0 0 0;
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                @media (max-width: $mobile-size) {
                    flex-direction: column;
                    justify-content: left;
                }
            }
        }
    }
}

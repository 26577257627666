@import "mixins";
@import "variables";

@mixin button($borderwidth) {
    cursor: pointer;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    padding-left: 16px - $borderwidth;
    padding-right: 16px - $borderwidth;

    border-radius: 1000px;
}

.button {
    @include button(2px);

    position: relative;
    overflow: hidden;

    width: calc(100% - 28px);
    height: calc(100% - 2px);

    border: solid 1px #ffe646;

    .bg {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .icon {
        z-index: 1;
        margin-right: 4px;
    }

    .title {
        z-index: 1;
        @include fontBold(14px, #2d2d2d);

        &.small {
            @include fontBold(12px, #2d2d2d);
        }
        &.medium {
            @include fontBold(14px, #2d2d2d);
        }
        &.large {
            @include fontBold(16px, #2d2d2d);
        }
    }

    cursor: pointer;
    &.disabled {
        cursor: not-allowed;
    }
}

.link_button_container {
    width: 64px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    .link_button {
        position: relative;
        width: 64px;
        height: 32px;
        cursor: pointer;

        display: flex;
        justify-content: center;
        align-items: center;

        border-radius: 16px;
        border: solid 1px rgba(255, 255, 255, 0.3);

        .icon {
            width: 20px;
            height: 20px;
            background-size: contain;
            background-image: url("../../../resources/ico-more2.svg");
        }

        &:hover {
            background-color: #ffe646;
            border-color: #ffe646;
            .icon {
                background-image: url("../../../resources/ico-more3.svg");
            }
        }
    }
}

@-webkit-keyframes trigger {
    0%,
    100% {
        -webkit-box-shadow: -30px 30px 0 0 rgba(255, 255, 255, 0.5),
            -15px 30px 0 0 rgba(255, 255, 255, 0.5),
            0 30px 0 0 rgba(255, 255, 255, 0.5),
            15px 30px 0 0 rgba(255, 255, 255, 0.5),
            30px 30px 0 0 rgba(255, 255, 255, 0.5);
        box-shadow: -30px 30px 0 0 rgba(255, 255, 255, 0.5),
            -15px 30px 0 0 rgba(255, 255, 255, 0.5),
            0 30px 0 0 rgba(255, 255, 255, 0.5),
            15px 30px 0 0 rgba(255, 255, 255, 0.5),
            30px 30px 0 0 rgba(255, 255, 255, 0.5);
    }
    30% {
        -webkit-box-shadow: -30px 30px 0 5px #fff,
            -15px 30px 0 0 rgba(255, 255, 255, 0.5),
            0 30px 0 0 rgba(255, 255, 255, 0.5),
            15px 30px 0 0 rgba(255, 255, 255, 0.5),
            30px 30px 0 0 rgba(255, 255, 255, 0.5);
        box-shadow: -30px 30px 0 5px #fff,
            -15px 30px 0 0 rgba(255, 255, 255, 0.5),
            0 30px 0 0 rgba(255, 255, 255, 0.5),
            15px 30px 0 0 rgba(255, 255, 255, 0.5),
            30px 30px 0 0 rgba(255, 255, 255, 0.5);
    }
    40% {
        -webkit-box-shadow: -30px 30px 0 0 rgba(255, 255, 255, 0.5),
            -15px 30px 0 5px #fff, 0 30px 0 0 rgba(255, 255, 255, 0.5),
            15px 30px 0 0 rgba(255, 255, 255, 0.5),
            30px 30px 0 0 rgba(255, 255, 255, 0.5);
        box-shadow: -30px 30px 0 0 rgba(255, 255, 255, 0.5),
            -15px 30px 0 5px #fff, 0 30px 0 0 rgba(255, 255, 255, 0.5),
            15px 30px 0 0 rgba(255, 255, 255, 0.5),
            30px 30px 0 0 rgba(255, 255, 255, 0.5);
    }
    50% {
        -webkit-box-shadow: -30px 30px 0 0 rgba(255, 255, 255, 0.5),
            -15px 30px 0 0 rgba(255, 255, 255, 0.5), 0 30px 0 5px #fff,
            15px 30px 0 0 rgba(255, 255, 255, 0.5),
            30px 30px 0 0 rgba(255, 255, 255, 0.5);
        box-shadow: -30px 30px 0 0 rgba(255, 255, 255, 0.5),
            -15px 30px 0 0 rgba(255, 255, 255, 0.5), 0 30px 0 5px #fff,
            15px 30px 0 0 rgba(255, 255, 255, 0.5),
            30px 30px 0 0 rgba(255, 255, 255, 0.5);
    }
    60% {
        -webkit-box-shadow: -30px 30px 0 0 rgba(255, 255, 255, 0.5),
            -15px 30px 0 0 rgba(255, 255, 255, 0.5),
            0 30px 0 0 rgba(255, 255, 255, 0.5), 15px 30px 0 5px #fff,
            30px 30px 0 0 rgba(255, 255, 255, 0.5);
        box-shadow: -30px 30px 0 0 rgba(255, 255, 255, 0.5),
            -15px 30px 0 0 rgba(255, 255, 255, 0.5),
            0 30px 0 0 rgba(255, 255, 255, 0.5), 15px 30px 0 5px #fff,
            30px 30px 0 0 rgba(255, 255, 255, 0.5);
    }
    70% {
        -webkit-box-shadow: -30px 30px 0 0 rgba(255, 255, 255, 0.5),
            -15px 30px 0 0 rgba(255, 255, 255, 0.5),
            0 30px 0 0 rgba(255, 255, 255, 0.5),
            15px 30px 0 0 rgba(255, 255, 255, 0.5), 30px 30px 0 5px #fff;
        box-shadow: -30px 30px 0 0 rgba(255, 255, 255, 0.5),
            -15px 30px 0 0 rgba(255, 255, 255, 0.5),
            0 30px 0 0 rgba(255, 255, 255, 0.5),
            15px 30px 0 0 rgba(255, 255, 255, 0.5), 30px 30px 0 5px #fff;
    }
    80% {
        -webkit-box-shadow: -30px 30px 0 0 rgba(255, 255, 255, 0.5),
            -15px 30px 0 0 rgba(255, 255, 255, 0.5),
            0 30px 0 0 rgba(255, 255, 255, 0.5),
            15px 30px 0 0 rgba(255, 255, 255, 0.5),
            30px 30px 0 0 rgba(255, 255, 255, 0.5);
        box-shadow: -30px 30px 0 0 rgba(255, 255, 255, 0.5),
            -15px 30px 0 0 rgba(255, 255, 255, 0.5),
            0 30px 0 0 rgba(255, 255, 255, 0.5),
            15px 30px 0 0 rgba(255, 255, 255, 0.5),
            30px 30px 0 0 rgba(255, 255, 255, 0.5);
    }
}
@keyframes trigger {
    0%,
    100% {
        -webkit-box-shadow: -30px 30px 0 0 rgba(255, 255, 255, 0.5),
            -15px 30px 0 0 rgba(255, 255, 255, 0.5),
            0 30px 0 0 rgba(255, 255, 255, 0.5),
            15px 30px 0 0 rgba(255, 255, 255, 0.5),
            30px 30px 0 0 rgba(255, 255, 255, 0.5);
        box-shadow: -30px 30px 0 0 rgba(255, 255, 255, 0.5),
            -15px 30px 0 0 rgba(255, 255, 255, 0.5),
            0 30px 0 0 rgba(255, 255, 255, 0.5),
            15px 30px 0 0 rgba(255, 255, 255, 0.5),
            30px 30px 0 0 rgba(255, 255, 255, 0.5);
    }
    30% {
        -webkit-box-shadow: -30px 30px 0 5px #fff,
            -15px 30px 0 0 rgba(255, 255, 255, 0.5),
            0 30px 0 0 rgba(255, 255, 255, 0.5),
            15px 30px 0 0 rgba(255, 255, 255, 0.5),
            30px 30px 0 0 rgba(255, 255, 255, 0.5);
        box-shadow: -30px 30px 0 5px #fff,
            -15px 30px 0 0 rgba(255, 255, 255, 0.5),
            0 30px 0 0 rgba(255, 255, 255, 0.5),
            15px 30px 0 0 rgba(255, 255, 255, 0.5),
            30px 30px 0 0 rgba(255, 255, 255, 0.5);
    }
    40% {
        -webkit-box-shadow: -30px 30px 0 0 rgba(255, 255, 255, 0.5),
            -15px 30px 0 5px #fff, 0 30px 0 0 rgba(255, 255, 255, 0.5),
            15px 30px 0 0 rgba(255, 255, 255, 0.5),
            30px 30px 0 0 rgba(255, 255, 255, 0.5);
        box-shadow: -30px 30px 0 0 rgba(255, 255, 255, 0.5),
            -15px 30px 0 5px #fff, 0 30px 0 0 rgba(255, 255, 255, 0.5),
            15px 30px 0 0 rgba(255, 255, 255, 0.5),
            30px 30px 0 0 rgba(255, 255, 255, 0.5);
    }
    50% {
        -webkit-box-shadow: -30px 30px 0 0 rgba(255, 255, 255, 0.5),
            -15px 30px 0 0 rgba(255, 255, 255, 0.5), 0 30px 0 5px #fff,
            15px 30px 0 0 rgba(255, 255, 255, 0.5),
            30px 30px 0 0 rgba(255, 255, 255, 0.5);
        box-shadow: -30px 30px 0 0 rgba(255, 255, 255, 0.5),
            -15px 30px 0 0 rgba(255, 255, 255, 0.5), 0 30px 0 5px #fff,
            15px 30px 0 0 rgba(255, 255, 255, 0.5),
            30px 30px 0 0 rgba(255, 255, 255, 0.5);
    }
    60% {
        -webkit-box-shadow: -30px 30px 0 0 rgba(255, 255, 255, 0.5),
            -15px 30px 0 0 rgba(255, 255, 255, 0.5),
            0 30px 0 0 rgba(255, 255, 255, 0.5), 15px 30px 0 5px #fff,
            30px 30px 0 0 rgba(255, 255, 255, 0.5);
        box-shadow: -30px 30px 0 0 rgba(255, 255, 255, 0.5),
            -15px 30px 0 0 rgba(255, 255, 255, 0.5),
            0 30px 0 0 rgba(255, 255, 255, 0.5), 15px 30px 0 5px #fff,
            30px 30px 0 0 rgba(255, 255, 255, 0.5);
    }
    70% {
        -webkit-box-shadow: -30px 30px 0 0 rgba(255, 255, 255, 0.5),
            -15px 30px 0 0 rgba(255, 255, 255, 0.5),
            0 30px 0 0 rgba(255, 255, 255, 0.5),
            15px 30px 0 0 rgba(255, 255, 255, 0.5), 30px 30px 0 5px #fff;
        box-shadow: -30px 30px 0 0 rgba(255, 255, 255, 0.5),
            -15px 30px 0 0 rgba(255, 255, 255, 0.5),
            0 30px 0 0 rgba(255, 255, 255, 0.5),
            15px 30px 0 0 rgba(255, 255, 255, 0.5), 30px 30px 0 5px #fff;
    }
    80% {
        -webkit-box-shadow: -30px 30px 0 0 rgba(255, 255, 255, 0.5),
            -15px 30px 0 0 rgba(255, 255, 255, 0.5),
            0 30px 0 0 rgba(255, 255, 255, 0.5),
            15px 30px 0 0 rgba(255, 255, 255, 0.5),
            30px 30px 0 0 rgba(255, 255, 255, 0.5);
        box-shadow: -30px 30px 0 0 rgba(255, 255, 255, 0.5),
            -15px 30px 0 0 rgba(255, 255, 255, 0.5),
            0 30px 0 0 rgba(255, 255, 255, 0.5),
            15px 30px 0 0 rgba(255, 255, 255, 0.5),
            30px 30px 0 0 rgba(255, 255, 255, 0.5);
    }
}

.trigger:before {
    -webkit-animation: trigger 1s infinite ease;
    animation: trigger 1s infinite ease;
    content: "";
    height: 6px;
    left: 50%;
    margin-left: -5px;
    margin-top: -32px;
    position: absolute;
    top: 50%;
    width: 10px;
}

@import "mixins";
@import "variables";

.container {
    display: flex;
    flex-direction: column;

    .signature_box {
        height: 92px;
        margin-top: -20px;
        margin-bottom: 30px;
        border-radius: 2px;
        border: solid 1px rgba(#fff, 0.3);

        display: flex;
        flex-direction: column;
        .box1 {
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 10px;
            padding-bottom: 10px;
            min-height: 20px;
            display: flex;
            flex-direction: row;
            align-items: center;

            .icon {
                width: 20px;
                height: 20px;
                background-size: cover;
                background-image: url("../../../../../resources/ico-referrallink-x.svg");

                &.referrer {
                    background-image: url("../../../../../resources/ico-referrallink-o.svg");
                }
            }

            .text {
                @include fontLight(12px, white);
                margin-left: 6px;
            }
        }

        .box2 {
            height: 1px;
            background-color: rgba(white, 0.3);
        }

        .box3 {
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 10px;
            padding-bottom: 10px;
            min-height: 20px;
            display: flex;
            flex-direction: column;

            .text1 {
                @include fontLight(12px, white);
            }

            .text2 {
                margin-top: 4px;
                word-break: break-all;
                @include fontLight(10px, rgba(white, 0.3));
            }
        }
    }
}

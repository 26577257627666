@import "mixins";
@import "variables";

.container {
    position: relative;
}

.inner_container {
    display: flex;
    flex-direction: column;

    .line_container {
        width: calc(100% + 40px);
        position: relative;
        left: -20px;

        @media (max-width: $mobile-size) {
            left: -20px;
        }
    }
    .exchangeicon {
        width: 20px;
        height: 20px;
        align-self: center;
        margin-top: 20px;

        background-size: cover;
        background-repeat: no-repeat;
        background-image: url("../../../../resources/ipc-next2.svg");
        @media (max-width: $mobile-size) {
            margin-top: 20px;
        }
    }

    .row {
        display: flex;
        flex-direction: row;
        align-items: center;

        @media (max-width: $mobile-size) {
            flex-direction: column;
        }

        .icon {
            width: 28px;
            height: 28px;

            margin-left: 11px;
            margin-right: 11px;

            background-size: cover;
            background-repeat: no-repeat;
            background-image: url("../../../../resources/icon-sum2.svg");
            
            @media (max-width: $mobile-size) {
                width: 20px;
                height: 20px;
            }
        }
    }
    .spreaddummy {
        width: 100%;
        height: 1px;
        background: rgba(#fff, 0.3);
    }
}

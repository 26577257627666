@import "mixins";
@import "variables";

.container {
    width: 100%;
    height: 70px;
    border-radius: 5px;
    border: solid 1px #ffe646;
    background-color: rgba(255, 230, 70, 0.1);

    display: flex;
    flex-direction: row;
    justify-content: center;

    .wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .sub {
            height: 18px;
            line-height: 18px;
            display: flex;
            flex-direction: row;
            justify-content: center;
        }

        .content {
            height: 18px;
            margin: 6px 0 0 0;
            display: flex;
            flex-direction: row;
            justify-content: center;
        }
    }
}

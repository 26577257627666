@import "mixins";
@import "variables";

.container {
    @include container2();

    .title {
        margin-top: 40px;
        align-self: flex-start;
    }

    .subtitle {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-bottom: 20px;
    }

    .inner_container {
        display: flex;
        flex-direction: column;

        border-radius: 2px;
        box-shadow: 0 20px 15px 0 rgba(0, 0, 0, 0.3);
        border: solid 1px rgba(255, 255, 255, 0.2);
        background-image: linear-gradient(313deg, #23293b, #39425d);
    }
}

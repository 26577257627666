@import "mixins";
@import "variables";

.pendingbox {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;

    .icon {
        margin-top: 10px;
        width: 120px;
        height: 120px;
        background-size: cover;
        background-image: url("../../../resources/loading_01.gif");
    }

    .text {
        margin-top: 10px;

        @include fontLight(14px, #ececec);
        text-align: center;
    }

    padding-bottom: 40px;
}

@import "mixins";
@import "variables";

.container {
    @include container2();

    .spreaddummy {
        width: 100%;
        height: 1px;
        background: rgba(#fff, 0.3);
    }
    .tradetab {
        width: 100%;
        border-radius: 0 2px 2px 0;
        border: solid 1px rgba(255, 255, 255, 0.2);
        background-image: linear-gradient(320deg, #23293b, #39425d);
        display: flex;
        flex-direction: column;

        @media (max-width: $width-pc1) {
            width: 100%;
        }

        .tradetab_top {
            // background: green;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        .tradetab_bottom {
            margin: 20px auto 0 auto;
            width: calc(100% - 40px);
        }
    }
}

.inner_container {
    display: flex;
    flex-direction: column;
}

.header_container {
    display: flex;
    flex-direction: row;

    @media (max-width: $mobile-size) {
        flex-direction: column;
    }

    .item_container {
        display: flex;
        flex-direction: column;
        flex: 1;

        .row {
            display: flex;
            flex-direction: column;
            height: 100%;
            padding: 20px 30px;

            border-radius: 2px;
            border: solid 1px rgba(255, 255, 255, 0.2);
            background-color: rgba(255, 255, 255, 0.05);

            .number_item {
                display: flex;
                flex-direction: column;
                .item {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                }
            }

            .vp_item {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                justify-content: space-between;
                .number_item {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                }
                .required_createvp {
                    width: 130px;
                    margin: 2px 0 0 0;
                    @media (max-width: $mobile-size) {
                        width: 100px;
                    }
                }
            }
            .overview_dummy {
                flex: 1;

                @media (max-width: $mobile-size) {
                    flex: none;
                    height: 30px;
                }
            }

            .dummy {
                height: 22px;
            }
        }
    }

    .spacer {
        width: 30px;
        height: 30px;
    }
}

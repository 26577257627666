@import "mixins";
@import "variables";

.container {
    width: calc(50% - 20px);
    display: flex;
    flex-direction: column;

    @media (max-width: $mobile-size) {
        width: 100%;
        margin: 20px 0 0 0;
    }

    .sharing_title {
        height: 16px;
        line-height: 16px;
        margin: 0 0 6px 0;
    }

    .sharing_contents {
        height: 30px;
        border-radius: 15px;
        border: solid 1px rgba(255, 255, 255, 0.2);
        background-color: rgba(255, 255, 255, 0.1);
        display: flex;
        flex-direction: row;

        .sharing_contents_amount {
            width: 285px;
            display: flex;
            flex-direction: row;
            margin: 3px 0 0 10px;

            @media (max-width: $mobile-size) {
                width: calc(100% - 95px);
            }

            .sharing_contents_amount_ico {
                width: 20px;
                height: 20px;
                background-size: cover;
                background-image: url("../../../../../resources/ico-rewards-022.svg");
            }

            .sharing_contents_amount_text {
                margin: 0 0 0 6px;
                line-height: 20px;
                display: flex;
                flex-direction: row;

                .amount_text_right {
                    margin: 3px 0 0 4px;
                }
            }
        }

        .sharing_contents_dummy {
            width: 1px;
            height: 14px;
            opacity: 0.2;
            margin: 7px 0 0 0;
            background-color: #fff;
        }

        .sharing_contents_day {
            width: 94px;
            display: flex;
            flex-direction: row;
            position: relative;

            .sharing_contents_day_text {
                margin: 0 0 0 20px;
                line-height: 28px;
                position: absolute;
                top: 0;
                right: 28px;
                width: 64px;
                @include font(12px, #e94f4f);
                text-align: right;

                @media (max-width: $mobile-size) {
                    line-height: 30px;
                }
            }

            .sharing_contents_day_ico {
                width: 20px;
                height: 20px;
                position: absolute;
                top: 2px;
                right: 8px;
                background-size: cover;
                background-image: url("../../../../../resources/ico-feelock011.svg");

                @media (max-width: $mobile-size) {
                    top: 3px;
                }
            }
        }
    }
}

@import "mixins";
@import "variables";

.container {
    display: flex;
    flex-direction: column;

    .box {
        margin-top: 30px;
        display: flex;
        flex-direction: column;

        .titlebox {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            height: 16px;
            align-items: center;

            .button {
                // margin-top: -50px;
                width: 150px;
                height: 30px;
            }
        }

        .signature_box {
            height: 92px;

            margin-bottom: 30px;
            border-radius: 2px;
            border: solid 1px rgba(#fff, 0.3);

            display: flex;
            flex-direction: column;
            .box1 {
                padding-left: 10px;
                padding-right: 10px;
                padding-top: 10px;
                padding-bottom: 10px;
                min-height: 20px;
                display: flex;
                flex-direction: row;
                align-items: center;

                .icon {
                    width: 20px;
                    height: 20px;
                    background-size: cover;
                    background-image: url("../../../../../resources/ico-referrallink-x.svg");

                    &.referrer {
                        background-image: url("../../../../../resources/ico-referrallink-o.svg");
                    }
                }

                .text {
                    @include fontLight(12px, white);
                    margin-left: 6px;
                }
            }

            .box2 {
                height: 1px;
                background-color: rgba(white, 0.3);
            }

            .box3 {
                padding-left: 10px;
                padding-right: 10px;
                padding-top: 10px;
                padding-bottom: 10px;
                min-height: 20px;
                display: flex;
                flex-direction: column;

                .text1 {
                    @include fontLight(12px, white);
                }

                .text2 {
                    margin-top: 4px;
                    word-break: break-all;
                    @include fontLight(10px, rgba(white, 0.3));
                }
            }
        }
    }

    .findtx {
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
            margin-top: 10px;
            @include fontBold(16px, white);
        }

        .subtitle {
            margin-top: 9px;
            text-align: center;
            @include fontLight(14px, white);
        }

        .button {
            margin-top: 30px;
            margin-bottom: 40px;
            height: 48px;
            width: 400px;
        }
    }
}

@-webkit-keyframes tx_loading {
    from {
        opacity: 1;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    to {
        opacity: 0;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
@keyframes tx_loading {
    from {
        opacity: 1;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    to {
        opacity: 0;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
.tx_loading {
    margin-top: 20px;
    margin-bottom: 48px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.tx_loading:after,
.tx_loading:before {
    border-radius: 100%;
    content: "";
    position: absolute;
}

.tx_loading:after {
    -webkit-animation: tx_loading 1.5s infinite;
    animation: tx_loading 1.5s infinite;
    border: 10px solid white;
    height: 50px;
    width: 50px;
}

.tx_loading:before {
    background: white;
    height: 20px;
    width: 20px;
}

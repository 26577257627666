@import "mixins";
@import "variables";

.container {
    width: 100%;
    display: flex;
    flex-direction: column;

    .header {
        margin: 0 0 20px 0;
        height: 24px;
        line-height: 24px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .participation_count {
            display: flex;
            flex-direction: row;
            align-items: center;

            .icon {
                width: 20px;
                height: 20px;
                background-size: cover;
                background-image: url("../../../../../resources/ico-people01.svg");
            }

            .text {
                margin-top: 4px;
                margin-left: 4px;
                @include fontLight(12px, rgba(#fff, 0.5));
            }
        }
    }

    .contents {
        width: 100%;
        border-radius: 2px;
        overflow: hidden;
        border: solid 1px rgba(255, 255, 255, 0.3);
        background-color: rgba(255, 255, 255, 0.05);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        position: relative;

        .wavewrapper {
            height: 21px; /* change the height of the waves here */
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            overflow-x: hidden;

            .wave {
                background-image: url("../../../../../resources/img-pool.svg");
                // filter: opacity(0.9) drop-shadow(0 0 0 #ffe646)
                //     saturate(1900%);
                width: 300%;
                height: 100%;
                animation: wave 40s -3s linear infinite;
                transform: translate3d(0, 0, 0);
                opacity: 0.5;
            }

            .wave:nth-of-type(2) {
                position: absolute;
                width: 300%;
                bottom: 0;
                animation: wave 15s linear infinite;
                opacity: 0.1;
            }

            @keyframes wave {
                0% {
                    transform: translateX(0);
                }
                50% {
                    transform: translateX(-25%);
                }
                100% {
                    transform: translateX(-50%);
                }
            }
        }

        @media (max-width: $mobile-size) {
            // height: 85px;
            flex-direction: column;
            justify-content: start;
        }

        .pooltitle {
            margin: 20px 0 20px 20px;
            width: 134px;
            line-height: 20px;
            display: flex;
            flex-direction: row;
            flex: 1;

            @media (max-width: $mobile-size) {
                margin: 20px 0 0 20px;
            }

            .pooltitle_ico {
                width: 20px;
                height: 20px;
                background-size: cover;
                background-image: url("../../../../../resources/ico-pool01.svg");
            }

            .pooltitle_text {
                margin: 0 0 0 6px;
            }
        }

        .poolamount {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;

            margin-right: 20px;

            @media (max-width: $mobile-size) {
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;

                margin-top: 8px;
                margin-left: 20px;
                margin-bottom: 20px;

                line-height: 20px;
            }

            .dummy {
                @include font(12px, white);
                @media (max-width: $mobile-size) {
                    display: none;
                }
            }
        }
    }
    .description {
        margin-top: 20px;
        @include fontLight(14px, white);
    }

    .gotosite_container {
        display: flex;
        flex-direction: row;

        margin-top: 12px;

        .gotosite {
            text-decoration: none;
            height: 30px;
            padding-left: 18px;
            padding-right: 12px;
            border-radius: 15px;
            border: solid 1px #fff;

            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            @include font(14px, white);
            cursor: pointer;

            &:hover {
                border-color: #ffe646;
                color: #ffe646;
                .arrowicon {
                    background-image: url("../../../../../resources/ico-arrow-top-true.svg");
                }
            }

            .arrowicon {
                margin-left: 4px;
                width: 20px;
                height: 20px;
                background-image: url("../../../../../resources/ico-arrow-top.svg");
            }
        }
    }
}

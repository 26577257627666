@import "mixins";
@import "variables";

.container {
    position: relative;
    cursor: pointer;
    width: 20px;
    height: 20px;
    display: inline-block;

    .icon {
        display: flex;
        width: 20px;
        height: 20px;
        margin: 0px 0 0 0;
        background-image: url("../../../resources/ico-t-011.svg");

        &.campaignfalse {
            width: 20px;
            height: 20px;
            margin: -3px 0 0 0;
            background-image: url("../../../resources/ico-t-01.svg");
            opacity: 0.3;
        }

        &.hovercheck {
            width: 20px;
            height: 20px;
            margin: -3px 0 0 0;
            background-image: url("../../../resources/ico-t-01.svg");
            opacity: 1;
        }
    }

    &:hover {
        .icon {
            width: 20px;
            height: 20px;
            margin: 0px 0 0 0;
            background-image: url("../../../resources/ico-t-01.svg");

            &.campaign {
                width: 20px;
                height: 20px;
                margin: -3px 0 0 0;
                background-image: url("../../../resources/ico-t-01.svg");
                filter: opacity(0.8) drop-shadow(0 0 0 #ffe646) saturate(5000%);
            }

            &.campaignfalse {
                width: 20px;
                height: 20px;
                margin: -3px 0 0 0;
                background-image: url("../../../resources/ico-t-01.svg");
                filter: opacity(0.8) drop-shadow(0 0 0 #ffe646) saturate(5000%);
                // opacity: 0.3;
            }
        }
    }

    .tooltip {
        border-radius: 5px;

        padding-top: 6px;
        padding-bottom: 6px;
        padding-left: 12px;
        padding-right: 12px;

        line-height: 20px;

        @include font(13px, black);
        max-width: 350px;
        // max-height: 50px;
        word-break: break-all;

        background-color: rgba(255, 255, 255, 0.9);
        border-color: solid 1px white;
    }
}

@import "mixins";
@import "variables";

.wrap {
    width: 100%;
    height: calc(100% + 300px);

    position: relative;
    z-index: 0;

    @media (max-width: $mobile-size) {
        height: calc(100%);
    }

    .container {
        @include container1();
        display: flex;
        flex-direction: column;
        // width: 100%;

        position: relative;
        margin: 0 auto;

        .item_container {
            position: relative;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            // justify-content: space-between;
            justify-content: center;
            align-items: center;
            height: 240px;
            border-radius: 2px;
            border: solid 1px rgba(255, 255, 255, 0.2);
            background-color: rgba(255, 255, 255, 0.05);

            .empty {
                height: 170px;
                width: 210px;
                display: flex;
                flex-direction: column;

                .img {
                    width: 208px;
                    height: 124px;
                    background-size: cover;
                    background-image: url("../../resources/cam-empty.svg");
                }

                .texts {
                    @include fontLight(14px, #fff);
                    letter-spacing: -0.4px;
                    margin: 20px 0 0 0;
                }
            }

            @media (max-width: $mobile-size) {
                margin-top: 0px;
                padding-top: 0px;
            }
        }

        .campaign_container {
            position: relative;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: space-between;
            // justify-content: center;
            // align-items: center;

            @media (max-width: $mobile-size) {
                margin-top: 0px;
                padding-top: 0px;
            }
        }

        .comingsoon {
            width: 100%;
            margin-bottom: 40px;
            border-radius: 2px;

            background-color: #222630;
            overflow: hidden;
            position: relative;
            box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.5);
        }
    }
}

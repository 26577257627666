@import "mixins";
@import "variables";

.container {
    @include box_shadow();
    display: flex;
    flex-direction: column;
    width: calc(100% - 20px);
    border-radius: 2px;
    box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.5);
    border: solid 1px rgba(255, 255, 255, 0.2);
    background-image: linear-gradient(317deg, #23293b 100%, #39425d 0%);

    @media (max-width: $mobile-size) {
        width: 100%;
        margin-bottom: 40px;
    }

    .chart_container {
        margin-bottom: 0px;
    }

    .header_container {
        padding: 30px;
    }

    .bar_chart_container {
        overflow: hidden;
        height: 24px;
        margin-left: 30px;
        margin-right: 30px;
        margin-bottom: 40px;

        border-radius: 5px;

        display: flex;
        flex-direction: row;

        @media (max-width: $mobile-size) {
            margin-left: 20px;
            margin-right: 20px;
            margin-bottom: 30px;            
        }

        .bar_chart_item {

            &:hover {
                opacity: 0.8;
                transition: all 0.6s;
            }
        }
    }
}

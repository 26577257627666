@import "mixins";
@import "variables";

.container {
    position: absolute;

    top: 30px;
    right: 0px;
    width: 100%;

    z-index: 100000;

    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 2px;

    box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.5);
    border: solid 1px rgba(255, 255, 255, 0.2);
    background-image: linear-gradient(332deg, #23293b, #39425d);

    display: flex;
    flex-direction: column;

    .item {
        @include fontLight(16px, white);
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 34px;
        padding-left: 20px;
        // padding-right: 20px;

        &:hover {
            background-color: rgba(white, 0.1);
        }

        &.selected {
            background-color: rgba(white, 0.1);
            color: #ffe646;
        }
    }
}

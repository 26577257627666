@import "mixins";
@import "variables";

body {
    background-color: #181b23;
    // background-image: url(./resources/);
}

.app_container {
    display: flex;

    flex-direction: column;
    flex: 1;
    width: 100vw;
    min-height: 100vh;
    align-items: center;

    .bg_container {
        display: flex;
        flex-direction: column;
        flex: 1;
        width: 100vw;
        align-items: center;
        background-color: #181b23;

        // min-height: 100vh - $header-pc - $footer-pc - $footer-margin-pc;
        min-height: calc(100vh - 70px - 100px - 100px);

        @media (max-width: $mobile-size) {
            min-height: calc(100vh - 70px - 182px - 100px);
        }
    }

    .v2_popup_container {
        position: fixed;
        left: 0px;
        top: 0px;
        width: 100vw;
        height: 100vh;
        z-index: 9999999;

        background-color: rgba(black, 0.5);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .v2_popup {
            width: calc(280px - 40px);
            padding: 30px;

            border-radius: 5px;
            box-shadow: 0 20px 15px 0 rgba(0, 0, 0, 0.4);
            border: solid 1px rgba(255, 255, 255, 0.3);
            background-color: #181b23;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .v2_text1 {
                @include fontBold(18px, white);
            }
            .v2_text2 {
                margin-top: 30px;
                text-align: center;
                line-height: 120%;
                @include font(14px, rgba(white, 0.8));
            }

            .v2_button {
                width: 200px;
                height: 30px;
                margin-top: 30px;
            }
        }
    }
}

@import "mixins";
@import "variables";

.container {
    width: calc(50% - 20px);
    display: flex;
    flex-direction: column;

    @media (max-width: $mobile-size) {
        width: 100%;
        margin: 20px 0 0 0;
    }

    .required_title {
        height: 16px;
        line-height: 16px;
        margin: 0 0 6px 0;
    }

    .required_contents {
        height: 30px;
        width: 229px;
        display: flex;
        flex-direction: row;

        @media (max-width: $mobile-size) {
            width: calc(100%);
        }

        .required_wrap {
            display: flex;
            flex-direction: row;

            @media (max-width: $mobile-size) {
                width: calc(100%);
            }

            .required_contents_amount {
                width: 229px;
                height: 28px;
                display: flex;
                flex-direction: row;
                border-radius: 15px;
                border: solid 1px rgba(255, 255, 255, 0.2);
                background-color: rgba(255, 255, 255, 0.1);

                @media (max-width: $mobile-size) {
                    width: calc(100% - 155px);
                }

                .required_contents_amount_ico {
                    width: 20px;
                    height: 20px;
                    margin: 2px 0 0 10px;
                    background-size: cover;
                    background-image: url("../../../../../resources/ico-vp.svg");
                }

                .required_contents_amount_text {
                    margin: 0 0 0 6px;
                    line-height: 20px;
                    display: flex;
                    flex-direction: row;
                }
            }

            .required_dummy {
                width: 1px;
                height: 14px;
                opacity: 0.2;
                margin: 8px 10px 0 10px;
                background-color: #fff;
            }

            .required_createvp {
                width: 130px;
                margin: -2px 0 0 0;
            }
        }
    }
}

@import "mixins";
@import "variables";

.container {
    display: flex;
    flex-direction: column;

    &.transparent {
        width: 100%;
    }

    &:not(.transparent) {
        width: calc(100% - 62px);
        padding-top: 30px;
        padding-left: 30px;
        padding-right: 30px;

        border-radius: 2px;
        border: solid 1px rgba(255, 255, 255, 0.2);
        background-color: rgba(255, 255, 255, 0.05);
    }
}

@import "mixins";
@import "variables";

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    .dummy {
        width: 800px;
        height: 1px;
        opacity: 0.2;
        background-color: #fff;

        @media (max-width: $mobile-size) {
            width: 100%;
        }
    }

    .go_button {
        margin-top: 30px;
        height: 48px;
        width: 400px;

        @media (max-width: $mobile-size) {
            width: calc(100%);
        }
    }
}

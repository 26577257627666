@import "mixins";
@import "variables";

.container {
    display: flex;
    flex-direction: column;

    padding-top: 60px;
    padding-left: 30px;
    padding-right: 30px;

    position: relative;
}

@import "mixins";
@import "variables";

.container {
    display: flex;
    flex: 1;

    &.horizontal {
        flex-direction: column;
        .dummy1 {
            height: 1px;
            background-color: #000000;
            opacity: 0.1;
        }
        .dummy2 {
            height: 1px;
            background-color: #fff;
            opacity: 0.1;
        }
    }

    &:not(.horizontal) {
        flex-direction: row;
        .dummy1 {
            width: 1px;
            height: 100%;
            background-color: #000000;
            opacity: 0.1;
        }
        .dummy2 {
            width: 1px;
            height: 100%;
            background-color: #fff;
            opacity: 0.1;
        }
    }
}

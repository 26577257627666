@import "mixins";
@import "variables";

.container {
    width: 100%;
    display: flex;
    flex-direction: column;

    .header {
        margin: 0 0 20px 0;
        height: 24px;
        line-height: 24px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .rewards_partamount_sub {
            .sub {
                line-height: 18px;
                .date {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .participants {
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        .icon1 {
                            width: 20px;
                            height: 20px;
                            background-size: cover;
                            background-image: url("../../../../../resources/ico-people01.svg");
                        }
                        .icon2 {
                            width: 20px;
                            height: 20px;
                            background-size: cover;
                            background-image: url("../../../../../resources/ico-day-b01.svg");
                        }

                        .text {
                            margin-top: 4px;
                            margin-left: 4px;
                            @include fontLight(12px, rgba(#fff, 0.5));
                        }
                    }
                    .dummy {
                        width: 1px;
                        height: 9px;
                        background-color: rgba(#fff, 0.5);
                        margin-left: 10px;
                        margin-right: 10px;
                    }
                }
            }
        }
    }

    .contents {
        display: flex;
        flex-direction: column;

        .pool {
            // height: 60px;
            width: 100%;
            border-radius: 2px;
            border: solid 1px rgba(255, 255, 255, 0.3);
            background-color: rgba(255, 255, 255, 0.05);
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            position: relative;

            .wavewrapper {
                height: 21px; /* change the height of the waves here */
                width: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                overflow-x: hidden;

                .wave {
                    background-image: url("../../../../../resources/img-pool.svg");
                    // filter: opacity(0.9) drop-shadow(0 0 0 #ffe646)
                    //     saturate(1900%);
                    width: 300%;
                    height: 100%;
                    animation: wave 40s -3s linear infinite;
                    transform: translate3d(0, 0, 0);
                    opacity: 0.5;
                }

                .wave:nth-of-type(2) {
                    position: absolute;
                    width: 300%;
                    bottom: 0;
                    animation: wave 15s linear infinite;
                    opacity: 0.1;
                }

                @keyframes wave {
                    0% {
                        transform: translateX(0);
                    }
                    50% {
                        transform: translateX(-25%);
                    }
                    100% {
                        transform: translateX(-50%);
                    }
                }
            }

            @media (max-width: $mobile-size) {
                // height: 85px;
                flex-direction: column;
                justify-content: start;
            }

            .pooltitle {
                margin: 20px 0 20px 20px;
                width: 134px;
                line-height: 20px;
                display: flex;
                flex-direction: row;

                @media (max-width: $mobile-size) {
                    margin: 20px 0 0 20px;
                }

                .pooltitle_ico {
                    width: 20px;
                    height: 20px;
                    background-size: cover;
                    background-image: url("../../../../../resources/ico-pool01.svg");
                }

                .pooltitle_text {
                    margin: 0 0 0 6px;
                }
            }

            .poolamount {
                // background: yellow;
                margin: 20px 20px 20px 0;
                width: 628px;
                // height: 20px;
                line-height: 20px;
                // display: flex;
                // flex-direction: row;
                position: relative;
                // justify-content: right;

                @media (max-width: $mobile-size) {
                    justify-content: left;
                    margin: 8px 0 0 20px;
                    width: calc(100% - 20px);
                    height: 40px;

                    &.amountlong {
                        height: 60px;
                    }
                }

                .poolamount_text {
                    position: absolute;
                    top: 0;
                    right: 0;

                    @media (max-width: $mobile-size) {
                        left: 0;
                        right: auto;
                    }

                    .sub {
                        display: flex;
                        flex-direction: row;
                        // align-items: center;

                        @media (max-width: $mobile-size) {
                            flex-direction: column;
                            // align-items: left;
                            // justify-content: left;
                        }

                        .text2 {
                            @include font(16px, white);

                            // @media (max-width: $mobile-size) {
                            //     @include font(14px, white);
                            // }

                            small {
                                font-size: 12px;

                                // @media (max-width: $mobile-size) {
                                //     @include font(10px, white);
                                // }
                            }

                            &.isZero {
                                color: #e94f4f;
                            }
                        }

                        .dummy {
                            @include font(16px, white);

                            @media (max-width: $mobile-size) {
                                // @include font(14px, white);
                                display: none;
                            }

                            small {
                                font-size: 12px;

                                // @media (max-width: $mobile-size) {
                                //     @include font(10px, white);
                                // }
                            }
                        }

                        .text3 {
                            @include font(16px, white);

                            // @media (max-width: $mobile-size) {
                            //     margin-top: 6px;
                            //     @include font(14px, white);
                            // }

                            small {
                                font-size: 12px;

                                // @media (max-width: $mobile-size) {
                                //     @include font(10px, white);
                                // }
                            }

                            &.isZero {
                                color: #e94f4f;
                            }
                        }
                    }
                }
            }
        }

        .rewards {
            margin: 40px 0 20px 0;
            display: flex;
            flex-direction: column;

            .rewardsdetail {
                height: 52px;
                margin: 10px 0 0 0;
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                @media (max-width: $mobile-size) {
                    flex-direction: column;
                    justify-content: left;
                    height: 152px;
                }

                .participants {
                    width: calc(50% - 20px);
                    display: flex;
                    flex-direction: column;

                    @media (max-width: $mobile-size) {
                        width: 100%;
                    }

                    .participants_title {
                        height: 16px;
                        line-height: 16px;
                        margin: 0 0 6px 0;
                    }

                    .participants_contents {
                        height: 30px;
                        border-radius: 15px;
                        border: solid 1px rgba(255, 255, 255, 0.2);
                        background-color: rgba(255, 255, 255, 0.1);
                        display: flex;
                        flex-direction: row;

                        .participants_contents_amount {
                            width: 285px;
                            display: flex;
                            flex-direction: row;
                            margin: 3px 0 0 10px;

                            @media (max-width: $mobile-size) {
                                width: calc(100% - 95px);
                            }

                            .participants_contents_amount_ico {
                                width: 20px;
                                height: 20px;
                                background-size: cover;
                                background-image: url("../../../../../resources/ico-rewards-011.svg");

                                &.isIpc {
                                    background-image: url("../../../../../resources/ico-swap-guide.svg");
                                }
                            }

                            .participants_contents_amount_text {
                                margin: 0 0 0 6px;
                                line-height: 23px;

                                .ipc_swap {
                                    display: flex;
                                    flex-direction: row;

                                    .ipc_swap_dummy {
                                        margin: 0 6px 0 6px;
                                    }
                                }
                            }
                        }

                        .participants_contents_dummy {
                            width: 1px;
                            height: 14px;
                            opacity: 0.2;
                            margin: 7px 0 0 0;
                            background-color: #fff;
                        }

                        .participants_contents_day {
                            width: 94px;
                            display: flex;
                            flex-direction: row;
                            position: relative;

                            .participants_contents_day_text {
                                line-height: 28px;
                                position: absolute;
                                top: 0;
                                right: 28px;
                                width: 64px;
                                @include font(12px, #e94f4f);
                                text-align: right;

                                @media (max-width: $mobile-size) {
                                    line-height: 30px;
                                }
                            }

                            .participants_contents_day_ico {
                                width: 20px;
                                height: 20px;
                                position: absolute;
                                top: 2px;
                                right: 8px;
                                background-size: cover;
                                background-image: url("../../../../../resources/ico-feelock011.svg");

                                @media (max-width: $mobile-size) {
                                    top: 3px;
                                }
                            }
                        }
                    }
                }

                .sharing {
                    width: calc(50% - 20px);
                    display: flex;
                    flex-direction: column;

                    @media (max-width: $mobile-size) {
                        width: 100%;
                        margin: 20px 0 0 0;
                    }

                    .sharing_title {
                        height: 16px;
                        line-height: 16px;
                        margin: 0 0 6px 0;
                    }

                    .sharing_contents {
                        height: 30px;
                        border-radius: 15px;
                        border: solid 1px rgba(255, 255, 255, 0.2);
                        background-color: rgba(255, 255, 255, 0.1);
                        display: flex;
                        flex-direction: row;

                        .sharing_contents_amount {
                            width: 285px;
                            display: flex;
                            flex-direction: row;
                            margin: 3px 0 0 10px;

                            @media (max-width: $mobile-size) {
                                width: calc(100% - 95px);
                            }

                            .sharing_contents_amount_ico {
                                width: 20px;
                                height: 20px;
                                background-size: cover;
                                background-image: url("../../../../../resources/ico-rewards-022.svg");
                            }

                            .sharing_contents_amount_text {
                                margin: 0 0 0 6px;
                                line-height: 20px;
                                display: flex;
                                flex-direction: row;

                                .amount_text_right {
                                    margin: 3px 0 0 4px;
                                }
                            }
                        }

                        .sharing_contents_dummy {
                            width: 1px;
                            height: 14px;
                            opacity: 0.2;
                            margin: 7px 0 0 0;
                            background-color: #fff;
                        }

                        .sharing_contents_day {
                            width: 94px;
                            display: flex;
                            flex-direction: row;
                            position: relative;

                            .sharing_contents_day_text {
                                margin: 0 0 0 20px;
                                line-height: 28px;
                                position: absolute;
                                top: 0;
                                right: 28px;
                                width: 64px;
                                @include font(12px, #e94f4f);
                                text-align: right;

                                @media (max-width: $mobile-size) {
                                    line-height: 30px;
                                }
                            }

                            .sharing_contents_day_ico {
                                width: 20px;
                                height: 20px;
                                position: absolute;
                                top: 2px;
                                right: 8px;
                                background-size: cover;
                                background-image: url("../../../../../resources/ico-feelock011.svg");

                                @media (max-width: $mobile-size) {
                                    top: 3px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .condition {
            display: flex;
            flex-direction: column;
            margin-bottom: 40px;

            .conditiondetail {
                height: 52px;
                margin: 10px 0 0 0;
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                @media (max-width: $mobile-size) {
                    height: auto;
                    flex-direction: column;
                    justify-content: left;
                }

                .deposit {
                    width: calc(50% - 20px);
                    display: flex;
                    flex-direction: column;

                    @media (max-width: $mobile-size) {
                        width: 100%;
                    }

                    .deposit_title {
                        height: 16px;
                        line-height: 16px;
                        margin: 0 0 6px 0;
                    }

                    .deposit_contents {
                        height: 30px;
                        border-radius: 15px;
                        border: solid 1px rgba(255, 255, 255, 0.2);
                        background-color: rgba(255, 255, 255, 0.1);
                        display: flex;
                        flex-direction: row;

                        .deposit_contents_none {
                            margin: 0 0 0 10px;
                            line-height: 30px;
                            display: flex;
                            flex-direction: row;

                            .deposit_contents_amount_ico {
                                width: 20px;
                                height: 20px;
                                margin: 4px 6px 0 0;
                                background-size: cover;
                                background-image: url("../../../../../resources/ico-deposit011.svg");
                            }
                        }

                        .deposit_contents_amount {
                            width: 285px;
                            display: flex;
                            flex-direction: row;
                            margin: 3px 0 0 10px;

                            @media (max-width: $mobile-size) {
                                width: calc(100% - 95px);
                            }

                            .deposit_contents_amount_ico {
                                width: 20px;
                                height: 20px;
                                background-size: cover;
                                background-image: url("../../../../../resources/ico-deposit011.svg");
                            }

                            .deposit_contents_amount_text {
                                margin: 0 0 0 6px;
                                line-height: 23px;
                            }
                        }

                        .deposit_contents_dummy {
                            width: 1px;
                            height: 14px;
                            opacity: 0.2;
                            margin: 7px 0 0 0;
                            background-color: #fff;
                        }

                        .deposit_contents_day {
                            width: 94px;
                            display: flex;
                            flex-direction: row;
                            position: relative;

                            .deposit_contents_day_text {
                                margin: 0 0 0 20px;
                                line-height: 28px;
                                position: absolute;
                                top: 0;
                                right: 28px;
                                width: 64px;
                                @include font(12px, #e94f4f);
                                text-align: right;

                                @media (max-width: $mobile-size) {
                                    line-height: 30px;
                                }
                            }

                            .deposit_contents_day_ico {
                                width: 20px;
                                height: 20px;
                                position: absolute;
                                top: 2px;
                                right: 8px;
                                background-size: cover;
                                background-image: url("../../../../../resources/ico-feelock011.svg");

                                @media (max-width: $mobile-size) {
                                    top: 3px;
                                }
                            }
                        }
                    }
                }

                .required {
                    width: calc(50% - 20px);
                    display: flex;
                    flex-direction: column;

                    @media (max-width: $mobile-size) {
                        width: 100%;
                        margin: 20px 0 0 0;
                    }

                    .required_title {
                        height: 16px;
                        line-height: 16px;
                        margin: 0 0 6px 0;
                    }

                    .required_contents {
                        height: 30px;
                        display: flex;
                        flex-direction: row;

                        @media (max-width: $mobile-size) {
                            width: calc(100%);
                        }

                        .required_contents_amount {
                            width: 229px;
                            height: 28px;
                            display: flex;
                            flex-direction: row;
                            border-radius: 15px;
                            border: solid 1px rgba(255, 255, 255, 0.2);
                            background-color: rgba(255, 255, 255, 0.1);

                            @media (max-width: $mobile-size) {
                                width: calc(100% - 155px);
                            }

                            .required_contents_amount_ico {
                                width: 20px;
                                height: 20px;
                                margin: 2px 0 0 10px;
                                background-size: cover;
                                background-image: url("../../../../../resources/ico-vp.svg");
                            }

                            .required_contents_amount_text {
                                margin: 0 0 0 6px;
                                line-height: 20px;
                                display: flex;
                                flex-direction: row;
                            }
                        }

                        .required_dummy {
                            width: 1px;
                            height: 14px;
                            opacity: 0.2;
                            margin: 8px 10px 0 10px;
                            background-color: #fff;
                        }

                        .required_createvp {
                            width: 130px;
                            margin: -2px 0 0 0;
                        }
                    }
                }
            }
        }
    }
}

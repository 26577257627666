@import "mixins";
@import "variables";

.container {
    display: flex;
    // flex-direction: row;
    flex-direction: column;

    @media (max-width: $mobile-size) {
        flex-direction: column;
        width: 100%;
    }

    .from {
        // background: red;
        height: 74px;
    }

    .arrow {
        width: 20px;
        height: 20px;
        background-size: cover;
        background-image: url("../../resources/ipc-next2.svg");
        margin: 22px auto 0 auto;

        &.error {
            background: transparent;
        }
    }

    .to {
        // background: red;
        height: 74px;
    }

    // .from {
    //     width: 340px;
    //     height: 100%;
    //     border-radius: 5px;
    //     background-color: #222226;
    //     display: flex;
    //     flex-direction: column;

    //     @media (max-width: $mobile-size) {
    //         width: 100%;
    //     }

    //     .title {
    //         width: calc(100% - 40px);
    //         margin: 20px auto 0 auto;
    //         height: 24px;
    //         @include fontLight(20px, #fff);
    //         line-height: 24px;
    //     }

    //     .inputs {
    //         width: calc(100% - 40px);
    //         margin: 20px auto 0 auto;
    //         display: block;
    //     }
    // }

    // .arrow {
    //     width: 60px;
    //     height: 100%;

    //     @media (max-width: $mobile-size) {
    //         width: 100%;
    //         height: 48px;
    //     }

    //     .arrowico {
    //         width: 28px;
    //         height: 28px;
    //         margin: 70px auto 0 auto;
    //         background-size: cover;
    //         background-image: url("../../resources/ico-arrow-01.svg");

    //         @media (max-width: $mobile-size) {
    //             margin: 10px auto 0 auto;
    //             background-image: url("../../resources/vp-ico-arrow2.svg");
    //         }
    //     }
    // }

    // .to {
    //     width: 340px;
    //     height: 100%;
    //     border-radius: 5px;
    //     background-color: #222226;
    //     display: flex;
    //     flex-direction: column;

    //     @media (max-width: $mobile-size) {
    //         width: 100%;
    //     }

    //     .title {
    //         width: calc(100% - 40px);
    //         margin: 20px auto 0 auto;
    //         height: 24px;
    //         @include fontLight(20px, #fff);
    //         line-height: 24px;
    //     }

    //     .vps {
    //         width: calc(100% - 40px);
    //         margin: 38px auto 0 auto;

    //         .vpamount {
    //             line-height: 60px;
    //             @include font(18px, #fff);
    //             padding: 0 0 0 20px;
    //         }

    //         .vpsymbol {
    //             position: absolute;
    //             top: 22px;
    //             right: 20px;
    //             @include font(18px, #fff);
    //         }
    //     }
    // }
}
